import React, { useMemo } from "react";
import { LodgingCardTranslations } from "@bwp/translations/LodgingCardTranslations";
import useGlobalSearchContext from "@bwp/shared/useGlobalSearchContext";
import { defaultValue } from "@bwp/shared/helpers";
import { formatISO } from "date-fns";
import useQueryString from "@bwp/shared/useQueryString";
import { SearchResultListTranslations } from "@bwp/translations/SearchResultListTranslations";
import { useSearchResultsPageQuery } from "@bwp/operations.generated";
import SearchResultListSkeleton from "@bwp/SearchResultListSkeleton";
import SearchResultList from "@bwp/SearchResultList";
import { lodgingUrl, lodgingUrlWithBookingOption } from "@bwp/shared/lodgingurl";

export interface SearchResultListWrapperProps {
    translations: SearchResultListTranslations;
    lodgingCardTranslations: LodgingCardTranslations;
    lodgingUrlPattern: string;
    markerIconUrl: string;
    mapHeight: number;
    googleMapsApiKey: string;
    maxMapZoomLevel: number;
    emptyResultHtmlContent: string;
    durationFlexibility?: number;
}

export default function SearchResultListWrapper({
    translations,
    lodgingCardTranslations,
    lodgingUrlPattern,
    markerIconUrl,
    mapHeight,
    googleMapsApiKey,
    maxMapZoomLevel,
    emptyResultHtmlContent,
    durationFlexibility,
}: SearchResultListWrapperProps) {
    const [headerElement] = React.useState(document.getElementById("bwp-search-result-header"));
    const [sorting, setSorting] = useQueryString("ord", null);

    // TODO: These needs to be set somewhere
    const numberOfBedroomsFacilityId = 27;
    const numberOfBathroomsFacilityId = 35;
    const extraFacilities = [numberOfBedroomsFacilityId, numberOfBathroomsFacilityId];

    const [searchContext] = useGlobalSearchContext({ defaultValue });

    const query = useSearchResultsPageQuery({
        variables: {
            query: searchContext.changeArrivalDensity(3).toQueryString(),
            onlyMasterFacilities: [],
            onlyFacilities: extraFacilities,
            displayDate: formatISO(searchContext.arrival, { representation: "date" }),
            sorting: sorting as any,
            durationFlexibility: durationFlexibility || 0,
        },
    });

    const queryString = useMemo(() => {
        return searchContext.toQueryString([
            "context:lodging-location",
            "context:lodging-address1",
        ]);
    }, [searchContext]);

    if (query.error) {
        return <div>{query.error}</div>;
    }

    if (query.loading) {
        return <SearchResultListSkeleton headerElement={headerElement} height={405} />;
    }

    return (
        <>
            <SearchResultList
                headerElement={headerElement}
                translations={translations}
                lodgingCardTranslations={lodgingCardTranslations}
                numberOfBathroomsFacilityId={numberOfBathroomsFacilityId}
                numberOfBedroomsFacilityId={numberOfBedroomsFacilityId}
                {...query.data}
                lodgingUrlBuilder={(l) =>
                    `${lodgingUrlWithBookingOption(
                        lodgingUrlPattern,
                        l.lodging,
                        l.selectedBookingOption
                    )}`
                }
                extraFacilities={extraFacilities}
                sorting={sorting}
                setSorting={setSorting}
                markerIconUrl={markerIconUrl}
                mapHeight={mapHeight}
                googleMapsApiKey={googleMapsApiKey}
                maxMapZoomLevel={maxMapZoomLevel}
                emptyResultHtmlContent={emptyResultHtmlContent}
                searchContext={searchContext}
            />
        </>
    );
}
