import * as React from "react";
import { DatePickerCalendarDay, DatePickerCalendar } from "./DatePickerCalendar";
import {
    format,
    eachDayOfInterval,
    compareAsc,
    addDays,
    isAfter,
    startOfToday,
    startOfDay,
} from "date-fns";
import { CalendarValue } from "./shared/types";
import {
    formattedShortDate,
    formattedValue,
    getDateValueFromDate,
    isValidDate,
} from "./shared/helpers";
import { DatePickerTranslations } from "./translations/DatePickerTranslations";
import { useCallback, useEffect, useState } from "react";

type DayManipulator = (
    year: number,
    month: number,
    day: DatePickerCalendarDay,
    lastReturnValue: any
) => any;

interface DatePickerProps {
    value: CalendarValue;
    onChange: (value: CalendarValue) => void;
    selectionMode: SelectionMode;
    dateSelectionDate?: Date;
    translations: DatePickerTranslations;
    culture: string;
    dayManipulator?: DayManipulator;
    showTwoMonths?: boolean;
}

export enum SelectionMode {
    TodayAndFuture,
    AllDays,
    Future,
    Past,
    TodayAndPast,
    SetDateAndFuture,
    SetDateAndPast,
    FutureFromSetDate,
    PastFromSetDate,
}

export default function DatePicker({
    value,
    dateSelectionDate,
    selectionMode,
    onChange,
    translations,
    culture,
    dayManipulator,
    showTwoMonths,
}: DatePickerProps) {
    const defaultSelectedDay = getDateValueFromDate(
        isValidDate(dateSelectionDate) ? dateSelectionDate : new Date()
    );
    const selectedDay = value || defaultSelectedDay;

    let [displayYear, setDisplayYear] = useState<number>(selectedDay.year);
    let [displayMonth, setDisplayMonth] = useState<number>(selectedDay.month - 1);
    let [days, setDays] = useState<DatePickerCalendarDay[]>(null);

    useEffect(() => {
        setDays(
            generateDays(
                displayYear,
                displayMonth,
                selectionMode,
                translations,
                culture,
                dateSelectionDate,
                dayManipulator,
                showTwoMonths ? 2 : 1
            )
        );
    }, [
        culture,
        dateSelectionDate,
        dayManipulator,
        displayMonth,
        displayYear,
        selectionMode,
        showTwoMonths,
        translations,
    ]);

    let handleActiveMonthChanged = useCallback((year: number, month: number) => {
        setDisplayYear(year);
        setDisplayMonth(month);
    }, []);

    const today = new Date();
    if (displayYear === null || displayYear === undefined) {
        displayYear = today.getFullYear();
    }
    if (displayMonth === null || displayMonth === undefined) {
        displayMonth = today.getMonth();
    }
    return (
        <React.Fragment>
            {days && (
                <DatePickerCalendar
                    days={days}
                    onActiveMonthChanged={handleActiveMonthChanged}
                    onChange={onChange}
                    displayYear={displayYear}
                    displayMonth={displayMonth}
                    value={value}
                    showTwoMonths={showTwoMonths}
                />
            )}
        </React.Fragment>
    );
}

function generateDays(
    year: number,
    month: number,
    selectionMode: SelectionMode,
    translations: DatePickerTranslations,
    culture: string,
    dateSelectionDate: Date,
    dayManipulator: DayManipulator,
    numberOfMonths: number
): DatePickerCalendarDay[] {
    const days = eachDayOfInterval({
        start: new Date(year, month, 1),
        end: new Date(year, month + numberOfMonths, 0),
    });

    let dayManipulatorLastReturnValue = null;
    return days.map((d) => {
        let selectable = false;
        let unSelectableReason = "";
        const today = startOfDay(new Date());
        const setDate = dateSelectionDate || new Date();
        switch (selectionMode) {
            case SelectionMode.AllDays:
                selectable = true;
                break;
            case SelectionMode.Future:
                selectable = compareAsc(d, today) === 1;
                unSelectableReason = translations.onlyDaysInFuture;
                break;
            case SelectionMode.Past:
                selectable = compareAsc(d, today) === -1;
                unSelectableReason = translations.onlyDaysInPast;
                break;
            case SelectionMode.TodayAndFuture:
                selectable = compareAsc(d, today) >= 0;
                unSelectableReason = translations.onlyTodayAndFuture;
                break;
            case SelectionMode.TodayAndPast:
                selectable = compareAsc(d, today) <= 0;
                unSelectableReason = translations.onlyTodayAndPast;
                break;
            case SelectionMode.SetDateAndFuture:
                selectable = compareAsc(d, setDate) >= 0;
                unSelectableReason = formattedValue(
                    formattedShortDate(addDays(setDate, -1), culture),
                    translations.onlyDaysAfter
                );
                break;
            case SelectionMode.SetDateAndPast:
                selectable = compareAsc(d, setDate) <= 0;
                unSelectableReason = formattedValue(
                    formattedShortDate(addDays(setDate, +1), culture),
                    translations.onlyDaysBefore
                );
                break;
            case SelectionMode.FutureFromSetDate:
                selectable = compareAsc(d, setDate) === 1;
                unSelectableReason = formattedValue(
                    formattedShortDate(setDate, culture),
                    translations.onlyDaysAfter
                );
                break;
            case SelectionMode.PastFromSetDate:
                selectable = compareAsc(d, setDate) === -1;
                unSelectableReason = formattedValue(
                    formattedShortDate(setDate, culture),
                    translations.onlyDaysBefore
                );
                break;
        }

        let day: DatePickerCalendarDay = {
            date: format(d, "yyyy-MM-dd"),
            parsedDate: d,
            selectable: selectable,
            unSelectableReason: unSelectableReason,
            classNames: [],
        };

        if (dayManipulator != null) {
            dayManipulatorLastReturnValue = dayManipulator(
                year,
                month,
                day,
                dayManipulatorLastReturnValue
            );
        }

        return day;
    });
}

export function dayManipulatorHighlightDays(days: number[]) {
    return (d) => {
        if (isAfter(d.parsedDate, startOfToday()) && days.some((x) => x == d.parsedDate.getDay())) {
            d.classNames.push("bwp-calendar__cell-highlighted");
        }
    };
}
