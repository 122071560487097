import ReactDOM from "react-dom";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import FramedSpot from "@bwp/FramedSpot";
import Footer from "@bwp/Footer";
import HeroSearchWrapper from "./HeroSearchWrapper";
import MenuBar from "@bwp/MenuBar";
import Spot from "@bwp/Spot";
import GallerySpot from "@bwp/GallerySpot";
import SearchBox from "@bwp/SearchBox";
import Grid from "@bwp/Grid";
import SearchResultListWrapper from "./SearchResultListWrapper";
import { defaultDateValue, isBrowser } from "@bwp/shared/helpers";
import { ApolloClient, ApolloProvider, NormalizedCacheObject } from "@apollo/client";
import LodgingTitle from "@bwp/LodgingTitle";
import useGlobalSearchContext from "@bwp/shared/useGlobalSearchContext";
import { LodgingPresentationQuery, useLodgingPresentationQuery } from "@bwp/operations.generated";
import LodgingImages from "@bwp/LodgingImages";
import LodgingFacilityRow from "@bwp/LodgingFacilityRow";
import LodgingDescription from "@bwp/LodgingDescription";
import IconHeadline from "@bwp/IconHeadline";
import Article from "@bwp/Article";
import { LodgingsQuery, useLodgingsLazyQuery } from "@bwp/MenuBar.generated";
import { SearchContext } from "@bwp/shared/searchcontext";
import LodgingFacilityGroup from "@bwp/LodgingFacilityGroup";
import LodgingReviews from "@bwp/LodgingReviews";
import LodgingBookingBox from "@bwp/LodgingBookingBox";
import GraphQLErrorPanel from "@bwp/GraphQLErrorPanel";
import LodgingMap from "@bwp/LodgingMap";
import LocationDescription from "@bwp/LocationDescription";
import LocationReviews from "@bwp/LocationReviews";
import NavButton from "@bwp/NavButton";
import BreakoutImage from "@bwp/BreakoutImage";
import LodgingGeneralInformation from "@bwp/LodgingGeneralInfo";
import LodgingListWrapper from "./LodgingListWrapper";
import LodgingListFilter from "@bwp/LodgingListFilter";
import DiscountLodgingListWrapper from "./DiscountLodgingListWrapper";
import useFavorites from "@bwp/shared/useFavorites";
import CheckoutWrapper from "./CheckoutWrapper";
import CompactDiscountLodgingListWrapper from "./CompactDiscountLodgingListWrapper";
import NewsletterSpotWrapper from "./NewsletterSpotWrapper";
import ReviewSpotWrapper from "./ReviewSpotWrapper";
import BureauReviewsWrapper from "./BureauReviewsWrapper";
import PurchaseTrackerWrapper from "./PurchaseTrackerWrapper";

// Assets
import SandDunesSmall from "@bwp/assets/Klitferie/sanddunes_small.svg";
import SandDunesLarge from "@bwp/assets/Klitferie/sanddunes_large.svg";
import Seamarker from "@bwp/assets/Klitferie/seamarker.svg";
import { formatISO } from "date-fns";

export function setExports(globalObject: object, client: ApolloClient<NormalizedCacheObject>) {
    globalObject["FramedSpot"] = FramedSpot;
    globalObject["Footer"] = Footer;
    globalObject["HeroSearch"] = HeroSearchWrapper;
    globalObject["MenuBar"] = withSearchBoxWrapper(
        conditionalApolloWrapper(
            lazyLoadLodgingsWrapper(MenuBar, (x) => x.lodgings),
            MenuBar,
            client,
            {
                requestLodgings: () => {
                    return;
                },
                lodgings: [],
            }
        )
    );
    globalObject["NewsletterSpot"] = onlyClientWrapper(NewsletterSpotWrapper, client);
    globalObject["ReviewSpot"] = onlyClientWrapper(ReviewSpotWrapper, client);
    globalObject["Spot"] = Spot;
    globalObject["GallerySpot"] = GallerySpot;
    globalObject["SearchBox"] = SearchBox;
    globalObject["LodgingListFilter"] = LodgingListFilter;
    globalObject["React"] = React;
    globalObject["ReactDOM"] = ReactDOM;
    globalObject["SandDunesSmall"] = SandDunesSmall;
    globalObject["SandDunesLarge"] = SandDunesLarge;
    globalObject["Seamarker"] = Seamarker;
    globalObject["NavButton"] = NavButton;
    globalObject["SearchResultList"] = onlyClientWrapper(SearchResultListWrapper, client);
    globalObject["LodgingList"] = onlyClientWrapper(LodgingListWrapper, client);
    globalObject["DiscountLodgingList"] = onlyClientWrapper(DiscountLodgingListWrapper, client);
    globalObject["CompactDiscountLodgingList"] = onlyClientWrapper(
        CompactDiscountLodgingListWrapper,
        client
    );
    globalObject["LodgingTitle"] = onlyClientWrapper(
        lodgingPresentationWrapper(LodgingTitle, (x) => x.lodgingPresentation.lodging),
        client
    );
    globalObject["LodgingImages"] = onlyClientWrapper(
        lodgingPresentationWrapper(LodgingImages, (x) => x.lodgingPresentation.lodging),
        client
    );
    globalObject["LodgingFacilityRow"] = onlyClientWrapper(
        lodgingPresentationWrapper(LodgingFacilityRow, (x) => x.lodgingPresentation.lodging),
        client
    );
    globalObject["LodgingDescription"] = onlyClientWrapper(
        lodgingPresentationWrapper(LodgingDescription, (x) => x.lodgingPresentation.lodging),
        client
    );
    globalObject["LodgingFacilityGroup"] = onlyClientWrapper(
        lodgingPresentationWrapper(LodgingFacilityGroup, (x) => x.lodgingPresentation.lodging),
        client
    );
    globalObject["LodgingReviews"] = onlyClientWrapper(
        lodgingPresentationWrapper(LodgingReviews, (x) => ({
            lodging: x.lodgingPresentation.lodging,
        })),
        client
    );
    globalObject["LodgingBookingBox"] = onlyClientWrapper(
        lodgingPresentationWrapper(LodgingBookingBox, (x) => ({
            lodgingPresentation: x.lodgingPresentation,
        })),
        client
    );
    globalObject["LodgingMap"] = onlyClientWrapper(
        lodgingPresentationWrapper(LodgingMap, (x) => ({
            lodging: x.lodgingPresentation.lodging,
        })),
        client
    );
    globalObject["LodgingLocationDescription"] = onlyClientWrapper(
        lodgingPresentationWrapper(LocationDescription, (x) => ({
            location: x.lodgingPresentation.lodging.location,
        })),
        client
    );
    globalObject["LodgingLocationReviews"] = onlyClientWrapper(
        lodgingPresentationWrapper(LocationReviews, (x) => ({
            reviews: x.lodgingPresentation.lodging.location.reviews,
        })),
        client
    );
    globalObject["IconHeadline"] = IconHeadline;
    globalObject["Grid"] = Grid;
    globalObject["Article"] = Article;
    globalObject["Checkout"] = onlyClientWrapper(CheckoutWrapper, client);
    globalObject["BreakoutImage"] = onlyClientWrapper(BreakoutImage, client);
    globalObject["LodgingGeneralInfo"] = onlyClientWrapper(LodgingGeneralInformation, client);
    globalObject["BureauReviews"] = onlyClientWrapper(BureauReviewsWrapper, client);
    globalObject["PurchaseTracker"] = onlyClientWrapper(PurchaseTrackerWrapper, client);
}

// This function takes a component...
function onlyClientWrapper(WrappedComponent, client) {
    return function OnlyClientWrapper(props) {
        let [mounted, setMounted] = useState(false);

        useEffect(() => {
            if (isBrowser()) {
                setMounted(true);
            }
        }, []);

        if (!mounted) {
            return null;
        }

        return (
            <ApolloProvider client={client}>
                <WrappedComponent {...props} />
            </ApolloProvider>
        );
    };
}

const defaultValue = {
    adults: 2,
    duration: 7,
    pets: null,
    arrival: defaultDateValue,
};

type LodgingPresentationMapper = (input: LodgingPresentationQuery) => any;
type LodgingsMapper = (input: LodgingsQuery) => any;

function lodgingPresentationWrapper(WrappedComponent, mapper: LodgingPresentationMapper) {
    return function HousePresentationWrapper(props) {
        let [searchContext] = useGlobalSearchContext({ defaultValue });
        let [cachedData, setCachedData] = useState<LodgingPresentationQuery>(null);
        let { data, loading, error } = useLodgingPresentationQuery({
            variables: {
                seasonPricesYear: searchContext.arrival.getFullYear(),
                query: searchContext.toQueryString(),
                displayDate: formatISO(searchContext.arrival, { representation: "date" }),
                numberOfReviews: 3,
            },
        });

        let [favorites, setFavorite] = useFavorites();

        let favorite = useMemo(() => {
            if (data == null) {
                return false;
            }
            return favorites.includes(data.lodgingPresentation.lodging.id);
        }, [data, favorites]);

        useEffect(() => {
            if (data != null) {
                setCachedData(data);
            }
        }, [data]);

        const handleFavoriteChanged = useCallback(() => {
            if (data) {
                setFavorite(data.lodgingPresentation.lodging.id);
            }
        }, [data]);

        const handleOnBook = useCallback(() => {
            if (props.nextUrl == null) {
                alert("Unable to goto booking. No Next Url");
            } else {
                window.location.href =
                    props.nextUrl +
                    "?lod=" +
                    data.lodgingPresentation.lodging.id +
                    "&" +
                    searchContext.toQueryString([
                        "context:lodging-address1",
                        "context:lodging-location",
                    ]);
            }
        }, [data, searchContext, props.nextUrl]);

        if (error) {
            return <GraphQLErrorPanel error={error} />;
        }

        if (loading && !cachedData) {
            return <div />;
        }

        return (
            <WrappedComponent
                {...mapper(data || cachedData)}
                {...props}
                breadcrumb={props.breadcrumb || []}
                favorite={favorite}
                onFavoriteChanged={handleFavoriteChanged}
                onBook={handleOnBook}
            />
        );
    };
}

function conditionalApolloWrapper(ApolloComponent, NoApolloComponent, client, noApolloProps) {
    if (!client) {
        return function NoDataComponentWrapper(props) {
            return <NoApolloComponent {...props} {...noApolloProps} />;
        };
    }

    return function ApolloComponentWrapper(props) {
        return (
            <ApolloProvider client={client}>
                <ApolloComponent {...props} />
            </ApolloProvider>
        );
    };
}

function lazyLoadLodgingsWrapper(WrappedComponent, mapper: LodgingsMapper) {
    return function LazyLoadLodgingsComponentWrapper(props) {
        const [requestLodgings, { data }] = useLodgingsLazyQuery({ ssr: false });

        return (
            <WrappedComponent
                {...props}
                requestLodgings={requestLodgings}
                lodgings={data ? mapper(data) : []}
            />
        );
    };
}

function withSearchBoxWrapper(WrappedComponent) {
    return function SearchBoxComponentWrapper(props) {
        const urlReader = useCallback((location: Location) => {
            // So when we are server side rendering we don't have a location. So we will look for the special fallback url prop and use that
            if (location === null) {
                if (props.searchBox.fallbackUrl) {
                    const tokens = props.searchBox.fallbackUrl.split("?");
                    if (tokens.length > 1) {
                        const search = props.searchBox.fallbackUrl.split("?")[1];
                        return SearchContext.createFromQueryString(search);
                    } else {
                        return new SearchContext();
                    }
                } else {
                    return new SearchContext();
                }
            } else {
                return SearchContext.createFromQueryString(location.search);
            }
        }, []);

        props.searchBox.urlReader = urlReader;
        props.searchBox.onSubmit = (value: SearchContext) => {
            window.location.href = props.searchBox.searchUrl + "?" + value.toQueryString();
        };

        return <WrappedComponent {...props} />;
    };
}

function withErrorWrapper(WrappedComponent) {
    class ErrorWrapper extends React.Component<any, { error: any }> {
        render() {
            if (this.state && this.state.error) {
                return (
                    <pre
                        style={{ maxWidth: "100%", overflow: "scroll" }}
                        onClick={() => alert(this.state.error)}
                    >
                        {this.state.error}
                    </pre>
                );
            }
            return <WrappedComponent {...this.props} />;
        }

        componentDidCatch(error, errorInfo) {
            this.setState({ error: JSON.stringify({ error, errorInfo }) });
        }
    }
    return ErrorWrapper;
}
