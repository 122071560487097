import React, { useEffect, useState } from "react";

import "./FilterPanel.scss";
import SelectWithLabel from "./SelectWithLabel";
import CheckBoxWithLabel from "./CheckBoxWithLabel";
import { HeroSearchSearchBoxProps } from "./HeroSearchSearchBox";
import { DurationSelectWithLabel } from "./DurationSelectWithLabel";
import { NumberSelectWithLabel } from "./NumberSelectWithLabel";
import DatePickerWithLabel from "./DatePickerWithLabel";
import { dayManipulatorHighlightDays, SelectionMode } from "./DatePicker";
import { SearchBoxSettings } from "./shared/types";
import SearchBoxRangeFilter from "./SearchBoxRangeFilter";
import usePassiveGlobalSearchContext from "./shared/usePassiveGlobalSearchContext";
import Button from "./Button";
import AnimatedCounter from "./AnimatedCounter";
import { CloseIcon } from "./CloseIcon";
import useQueryString from "./shared/useQueryString";
import PeopleSelectWithLabel from "./PeopleSelectWithLabel";

interface FilterPanelProps {
    searchBox: HeroSearchSearchBoxProps;
    searchBoxSettings: SearchBoxSettings;
}

export default function SearchBox({ searchBox, searchBoxSettings }: FilterPanelProps) {
    const [searchContext, setSearchContext] = usePassiveGlobalSearchContext();
    const [open, setOpen] = useState(false);
    const [numberOfSearchResults, setNumberOfSearchResults] = useState<number>(null);
    const [sorting, setSorting] = useQueryString("ord", null);

    useEffect(() => {
        const body = document.querySelector("body");
        const bounds = body.getBoundingClientRect();
        if (bounds.width < 768) {
            if (open) {
                body.classList.add("bwp-noscroll");
            } else {
                body.classList.remove("bwp-noscroll");
            }
        }
    }, [open]);

    useEffect(() => {
        let listener = (e: { detail: number }) => {
            setNumberOfSearchResults(e.detail);
        };

        window.addEventListener("numberOfSearchResultsUpdated", listener as any);

        return () => {
            window.removeEventListener("numberOfSearchResultsUpdated", listener as any);
        };
    });

    if (searchContext == null) {
        return <div />;
    }

    if (searchBoxSettings == null) {
        return (
            <div style={{ color: "red" }}>
                SearchBoxSettings was null. It must be supplied by choosing a SearchBoxSetting in
                the Administration.
            </div>
        );
    }

    const translations = searchBox.translations;

    return (
        <aside className="bwp-filter-panel">
            <div bwp-show={!open ? "" : undefined} className="bwp-filter-panel__open-filter-button">
                <Button onClick={() => setOpen(true)}>{translations.setFilter}</Button>
            </div>
            <div className="bwp-filter-panel__container" bwp-show={open ? "" : undefined}>
                <div className="bwp-filter-panel__close-icon" onClick={() => setOpen(false)}>
                    <CloseIcon />
                </div>
                <section className="bwp-filter-panel__sorting">
                    <header>{translations.chooseSorting}</header>
                    <div>{translations.sortingHelp}</div>

                    <SelectWithLabel
                        id="sorting"
                        label={translations.sortingLabel}
                        value={sorting}
                        onChange={(v) => setSorting(v)}
                        items={[
                            {
                                text: translations.sort.standard,
                                value: null,
                            },
                            {
                                text: translations.sort.price,
                                value: "PRICE_LOW_TO_HIGH",
                            },
                        ]}
                    />
                </section>
                <section>
                    <header>{translations.chooseAdditionalFilters}</header>

                    <SelectWithLabel
                        id="location"
                        label={translations.locationLabel}
                        value={
                            searchContext.locationIds == null ||
                            searchContext.locationIds.length == 0
                                ? null
                                : searchContext.locationIds[0].toString()
                        }
                        onChange={(v) =>
                            setSearchContext(
                                searchContext.changeLocationId(v == null ? null : parseInt(v))
                            )
                        }
                        items={searchBox.locations}
                    />
                    <DatePickerWithLabel
                        label={translations.arrivalLabel}
                        value={searchContext.parsedArrival}
                        onChange={(v) => setSearchContext(searchContext.changeArrival(v))}
                        selectionMode={SelectionMode.Future}
                        culture={searchBox.culture}
                        translations={searchBox.datePickerTranslations}
                        dayManipulator={dayManipulatorHighlightDays(searchBox.highlightDays)}
                    />
                    <DurationSelectWithLabel
                        id="duration"
                        label={translations.durationLabel}
                        value={searchContext.duration}
                        onChange={(v) => setSearchContext(searchContext.changeDuration(v))}
                        translations={translations}
                        durations={searchBoxSettings.availableDurations}
                    />
                    <PeopleSelectWithLabel
                        label={translations.personsLabel}
                        maxPersons={searchBoxSettings.maxPeople}
                        value={{
                            adults: searchContext.adults ?? 0,
                            children: searchContext.children ?? 0,
                        }}
                        onChange={(v) =>
                            setSearchContext(
                                searchContext.changePersons(
                                    v.adults,
                                    v.children,
                                    0,
                                    searchContext.pets
                                )
                            )
                        }
                        translations={translations}
                    />
                    <NumberSelectWithLabel
                        id="pets"
                        label={translations.petsLabel}
                        itemTranslation={translations.pet}
                        value={searchContext.pets}
                        min={1}
                        max={searchBoxSettings.maxPets}
                        nullValueText={translations.petsNotImportant}
                        onChange={(v) => setSearchContext(searchContext.changePets(v))}
                    />
                    <div style={{ paddingTop: "8px", fontWeight: "bold" }}>
                        {translations.chooseFilters}
                    </div>
                    {searchBoxSettings.rangeFacilities.map((filter) => (
                        <SearchBoxRangeFilter
                            key={filter.facilityId}
                            filter={filter}
                            value={searchContext.getFacilityRange(filter.facilityId)}
                            onChange={(v) =>
                                setSearchContext(
                                    searchContext.changeFacilityRange(filter.facilityId, v)
                                )
                            }
                        />
                    ))}
                    {searchBoxSettings.booleanFacilities.map((facility) => (
                        <CheckBoxWithLabel
                            key={facility.facilityId}
                            label={facility.displayName}
                            value={
                                searchContext.getFacilityEqualValue(facility.facilityId) == "true"
                            }
                            onChange={(v) =>
                                setSearchContext(
                                    searchContext.changeFacilityValue(
                                        facility.facilityId,
                                        v ? "true" : null
                                    )
                                )
                            }
                        />
                    ))}
                </section>

                <div className="bwp-filter-panel__modal-footer">
                    <Button type={"primary"} onClick={() => setOpen(false)}>
                        {translations.searchButtonText}{" "}
                        {numberOfSearchResults !== null && (
                            <span>
                                (<AnimatedCounter number={numberOfSearchResults} />)
                            </span>
                        )}
                    </Button>
                </div>
            </div>
        </aside>
    );
}
