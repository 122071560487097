import React from "react";
import { NavGroup, Brand } from "./shared/types";

import "./Footer.scss";
import { telLinkHref, emailLinkHref } from "./shared/helpers";

export interface FooterProps {
    brand: Brand;
    groupedMenus: NavGroup[];
    backgroundStyle?: string;
}

export default function Footer({ brand, groupedMenus, backgroundStyle }: FooterProps) {
    return (
        <footer className="bwp-footer" bwp-color-preset={backgroundStyle}>
            <div className="bwp-footer__header">
                <div className="bwp-footer__header-center"></div>
            </div>
            <div className="bwp-footer__body">
                <div className="bwp-footer__body-center">
                    <div className="bwp-footer__group">
                        <h3 className="bwp-desktop-only">{brand.legalName || brand.name}</h3>
                        <address>
                            {(brand.address || "").split("\n").map((line, index) => (
                                <React.Fragment key={`addressLine${index}`}>
                                    {line}
                                    <br />
                                </React.Fragment>
                            ))}
                            <ul>
                                {brand.telephone && (
                                    <li>
                                        Tel:{" "}
                                        <a href={telLinkHref(brand.telephone)}>{brand.telephone}</a>
                                    </li>
                                )}
                                {brand.email && (
                                    <li>
                                        E-mail:{" "}
                                        <a href={emailLinkHref(brand.email)}>{brand.email}</a>
                                    </li>
                                )}
                            </ul>
                        </address>
                    </div>
                    {groupedMenus &&
                        groupedMenus.map((menu, index) => (
                            <FooterMenu key={`footerMenu${index}`} menu={menu} />
                        ))}
                </div>
            </div>
            <div className="bwp-footer__footer">
                <div className="bwp-footer__footer-center">
                    &copy;{new Date().getFullYear()} - {brand.legalName}
                </div>
            </div>
        </footer>
    );
}

interface FooterMenuProps {
    menu: NavGroup;
}

function FooterMenu({ menu }: FooterMenuProps) {
    const classNames = [];
    if (menu.icon) {
        classNames.push("bwp-icon");
        classNames.push(`bwp-icon--${menu.icon}`);
    }

    return (
        <div className="bwp-footer__group">
            <h3 className={classNames.join(" ")}>{menu.headline}</h3>
            {menu.nodes && (
                <nav>
                    <ul>
                        {menu.nodes.map((node) => (
                            <li
                                key={`${node.name}_${node.url}`}
                                className={node.icon && `bwp-icon--${node.icon}`}
                            >
                                <a href={node.url}>{node.name}</a>
                            </li>
                        ))}
                    </ul>
                </nav>
            )}
        </div>
    );
}
