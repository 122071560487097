import {
    ApolloClient,
    InMemoryCache,
    NormalizedCache,
    NormalizedCacheObject,
} from "@apollo/client";
import { isBrowser } from "@bwp/shared/helpers";

let client: ApolloClient<NormalizedCacheObject> = null;

export function getClient() {
    if (client == null && isBrowser()) {
        client = new ApolloClient({
            uri: "/umbraco/bookingstudio/api/graphql?culture=" + window["culture"],
            cache: new InMemoryCache(),
        });
    }
    return client;
}
