import React from "react";

import "./BookingSummary.scss";
import "./global/shared.scss";
import { pluralize, stringFormat } from "./shared/helpers";
import { CheckoutTranslations } from "./translations/CheckoutTranslations";
import { getImageUrl } from "./shared/images";
import {
    BookingSummaryLodgingFragment,
    BookingSummaryBookingOptionFragment,
} from "./operations.generated";

export interface BookingSummaryProps {
    lodging: BookingSummaryLodgingFragment;
    bookingOption: BookingSummaryBookingOptionFragment;
    translations: CheckoutTranslations;
}

export default function BookingSummary({
    lodging,
    bookingOption,
    translations,
}: BookingSummaryProps) {
    const lodgingName = stringFormat(translations.lodgingPrefix, lodging.name);
    const lodgingStreetAndArea = stringFormat(
        translations.streetAndArea,
        lodging.address1,
        lodging.location.name
    );
    const dateRange = stringFormat(
        translations.dateRange,
        bookingOption.arrivalDisplayValue,
        bookingOption.departureDisplayValue
    );
    const adults = pluralize(bookingOption.adults, translations.adult);
    const children = pluralize(bookingOption.children, translations.child);
    const pets = pluralize(bookingOption.pets, translations.pet);
    const adultsAndChildren = stringFormat(translations.adultsAndChildren, adults, children);

    const width = 262;
    const height = 190;
    const imageUrl = lodging.primaryImage
        ? getImageUrl(
              lodging.primaryImage,
              width * window.devicePixelRatio,
              height * window.devicePixelRatio
          )
        : null;

    return (
        <div className="bwp-bookingsummary">
            <div
                className="bwp-bookingsummary__image"
                style={{
                    backgroundImage: lodging.primaryImage != null ? `url(${imageUrl})` : null,
                }}
                title={lodgingName}
            />
            <div className="bwp-bookingsummary__text">
                <h2>{lodgingName}</h2>
                <p className="bwp-only-large-screen">{lodgingStreetAndArea}</p>
                <h2 className="bwp-only-large-screen">{translations.yourStay}</h2>
                <p>{dateRange}</p>
                <p>
                    {adultsAndChildren}
                    <br />
                    {pets}
                </p>
            </div>
        </div>
    );
}
