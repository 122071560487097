import React, { useEffect, useState } from "react";
import "./LodgingTitle.scss";
import { LodgingPageTranslations } from "./translations/LodgingPageTranslations";
import { LodgingPresentationQuery } from "./operations.generated";
import { viewLodging } from "./shared/trackingUtils";

interface Props {
    translations: LodgingPageTranslations;
}

export default function LodgingTitle(
    props: Props & LodgingPresentationQuery["lodgingPresentation"]["lodging"]
) {
    const [tracked, setTracked] = useState(false);

    useEffect(() => {
        if (!tracked) {
            viewLodging(props.id, props.name, props);
            setTracked(true);
        }
    }, [props, tracked]);

    const name = props.name;

    return (
        <div className="bwp-lodging-title">
            <div className="bwp-lodging-title__name">
                {props.translations.lodgingNamePrefix} {name}
            </div>
            <h1 className="bwp-lodging-title__title">{props.activeDescription?.title || name}</h1>
            <div className="bwp-lodging-title__location-name">{props.location.name}</div>
        </div>
    );
}
