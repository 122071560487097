import React, { useState, useRef, useEffect } from "react";
import useOnClickOutside from "use-onclickoutside";
import useIsomorphicLayoutEffect from "./shared/useIsomorphicLayoutEffect";
import IconButton from "./IconButton";
import LanguageSelect from "./LanguageSelect";
import "./MenuBar.scss";
import "./HeroSearch.scss";

import { MenuBarTranslations } from "./translations/MenuBarTranslations";
import { Language, NavNode, Brand } from "./shared/types";
import { IconSearch, IconMenu, IconClose } from "./shared/icons";
import HeroSearchSearchBox, { HeroSearchSearchBoxProps } from "./HeroSearchSearchBox";
import AutoCompleteFieldWithLabel from "./AutoCompleteFieldWithLabel";
import { LodgingsQuery } from "./MenuBar.generated";
import getLodgingAutoComplete from "./shared/autocompletehelpers/LodgingAutoCompleteHelper";
import { setBoundsFromRef } from "./shared/dimensions";
import { lodgingUrl } from "./shared/lodgingurl";

export interface MenuBarProps {
    translations: MenuBarTranslations;
    brand: Brand;
    languages: Language[];
    menus: NavNode[];
    serverPath: string;
    testPath?: string;
    lodgingUrlPattern?: string;
    searchBox?: HeroSearchSearchBoxProps;
    requestLodgings: () => LodgingsQuery;
    lodgings?: LodgingsQuery["lodgings"];
    defaultActiveSearchLayerTab?: string;
}

type SearchLodging = LodgingsQuery["lodgings"][0];

export default function MenuBar({
    translations,
    brand,
    languages,
    menus,
    serverPath,
    testPath,
    lodgingUrlPattern,
    searchBox,
    requestLodgings,
    lodgings,
    defaultActiveSearchLayerTab
}: MenuBarProps) {
    const [activeSubMenu, setActiveSubMenu] = useState(null);
    const [path, setPath] = useState(null);
    const menuListRef = useRef(null);
    const menuListMobileRef = useRef(null);
    const [navigationPanelOpen, setNavigationPanelOpen] = useState(false);
    const [searchLayerOpen, setSearchLayerOpen] = useState(false);
    const [activeSearchLayerTab, setActiveSearchLayerTab] = useState(defaultActiveSearchLayerTab || "searchbox");
    const [currentLodging, setCurrentLodging] = useState(null);
    const [hasRequestedLodgings, setHasRequestedLodgings] = useState(false);
    const searchLayer = useRef<HTMLDivElement>();

    useEffect(() => {
        if (typeof testPath !== "undefined") {
            setPath(testPath);
        } else {
            if (typeof window !== "undefined") {
                setPath(window.location.pathname);
            } else {
                setPath(serverPath);
            }
        }
    }, [testPath, serverPath]);

    useEffect(() => {
        const body = document.querySelector("body");
        const bounds = body.getBoundingClientRect();
        if (bounds.width < 1140) {
            if (searchLayerOpen) {
                body.classList.add("bwp-noscroll");
            } else {
                body.classList.remove("bwp-noscroll");
            }
        }
    }, [searchLayerOpen]);

    useEffect(() => {
        if (window) {
            const handleSearchForLodging = () => {
                setSearchLayerOpen(true);
                setActiveSearchLayerTab("lodgingsearch");
                handleLoadLodgings();
            };

            window.addEventListener("bwp:SearchForLodging", handleSearchForLodging);

            return () => {
                window.removeEventListener("bwp:SearchForLodging", handleSearchForLodging);
            }
        }
    }, []);

    useOnClickOutside(menuListRef, () => {
        if (!navigationPanelOpen) {
            setActiveSubMenu(null);
        }
    });

    useOnClickOutside(menuListMobileRef, () => {
        if (navigationPanelOpen) {
            setActiveSubMenu(null);
        }
    });

    useOnClickOutside(searchLayer, (e) => {
        if (
            !thisOrParentElement(e.target as HTMLElement, (e) =>
                e.classList?.contains("bwp-menu-search")
            )
        ) {
            setSearchLayerOpen(false);
        }
    });

    const handleMenuClick = (nodes: NavNode[]) => {
        if (activeSubMenu === nodes) {
            setActiveSubMenu(null);
        } else {
            setActiveSubMenu(nodes);
        }
    };

    const handleMenuIconClick = (e: React.SyntheticEvent) => {
        e.preventDefault();
        setNavigationPanelOpen(true);
    };

    const handleCloseIconClick = (e: React.SyntheticEvent) => {
        e.preventDefault();
        setNavigationPanelOpen(false);
    };

    const handleSearchIconClick = (e: React.SyntheticEvent) => {
        e.preventDefault();
        setSearchLayerOpen(!searchLayerOpen);
    };

    const handleLodgingSearchChange = (lodging: SearchLodging) => {
        setCurrentLodging(lodging);
        gotoLodging(lodging);
    };

    const handleTabClicked = (tabId: string) => {
        return (e: React.SyntheticEvent) => {
            e.preventDefault();
            setActiveSearchLayerTab(tabId);
            if (tabId === "lodgingsearch") {
                handleLoadLodgings();
            }
        };
    };

    const gotoLodging = (lodging: SearchLodging) => {
        if (lodgingUrlPattern) {
            if (lodging) {
                const url = lodgingUrl(lodgingUrlPattern, lodging);
                window.location.href = url;
            }
        } else {
            console.error("MenuBar component is missing lodgingUrlPattern prop");
        }
    };

    const handleLoadLodgings = () => {
        if (!hasRequestedLodgings) {
            requestLodgings();
            setHasRequestedLodgings(true);
        }
    };

    const isMenuActive = (menu: NavNode) => {
        if (!path) {
            return false;
        }

        return (
            path === menu.url ||
            (menu.nodes && menu.nodes.length && menu.nodes.some((node) => node.url === path))
        );
    };

    const getMenuItem = (menu: NavNode) => {
        if (menu.nodes && menu.nodes.length) {
            return (
                <MenuWithSubMenu
                    menu={menu}
                    isOpen={activeSubMenu === menu.nodes}
                    onClick={handleMenuClick}
                    isActive={isMenuActive(menu)}
                    key={`menuwithsubmenu${menu.name}`}
                />
            );
        }
        return <Menu menu={menu} isActive={isMenuActive(menu)} key={`menu${menu.url}`} />;
    };

    const panelClassNames = ["bwp-menu-bar__panel"];
    if (navigationPanelOpen) {
        panelClassNames.push("bwp-open");
    }

    const toolsClassNames = ["bwp-menu-bar__tools"];
    if (searchLayerOpen) {
        toolsClassNames.push("bwp-layer-open");
    }

    const currentLanguage = languages.find((lan) => lan.isCurrent) || languages[0];

    return (
        <React.Fragment>
            <nav className="bwp-menu-bar">
                <div className="bwp-menu-bar__body">
                    <div className="bwp-menu-bar__brand">
                        <a href={brand.url || "/"}>
                            <img src={brand.logoUrl} width={brand.logoWidth} />
                        </a>
                    </div>
                    <ul className="bwp-menu-bar__list" ref={menuListRef}>
                        {menus.map((menu) => getMenuItem(menu))}
                    </ul>
                    <div className={toolsClassNames.join(" ")}>
                        <IconButton
                            type="button"
                            onClick={handleSearchIconClick}
                            className="bwp-menu-search"
                            padding="9px 10px"
                        >
                            {IconSearch}
                        </IconButton>
                        <IconButton
                            type="button"
                            onClick={handleMenuIconClick}
                            className="bwp-menu-hamburger"
                            padding="13px 9px 10px 9px"
                        >
                            {IconMenu}
                        </IconButton>
                        <LanguageSelect value={currentLanguage.isoCode} languages={languages} />
                    </div>
                </div>
                <div className="bwp-menu-bar__container">
                    <div className={panelClassNames.join(" ")}>
                        <div className="bwp-menu-bar__panel-body">
                            <button className="bwp-menu-bar__close" onClick={handleCloseIconClick}>
                                {IconClose}
                            </button>
                            <div className="bwp-menu-bar__brand">
                                <a href={brand.url || "/"}>{brand.name}</a>
                            </div>
                            <ul className="bwp-menu-bar__list" ref={menuListMobileRef}>
                                {menus.map((menu) => getMenuItem(menu))}
                            </ul>
                        </div>
                        <div className="bwp-menu-bar__panel-footer">
                            <div className="bwp-menu-bar__language-list">
                                <ul>
                                    {languages.map((language) => (
                                        <li
                                            key={language.isoCode}
                                            className={`bwp-flag--${language.isoCode}`}
                                        >
                                            <a href={language.url}>{language.name}</a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
            {searchLayerOpen && (
                <div className="bwp-menu-bar-layer">
                    <div className="bwp-menu-bar-layer__body" ref={searchLayer}>
                        <div className="bwp-menu-bar-layer__tabs">
                            <ul>
                                <li
                                    className={
                                        activeSearchLayerTab === "searchbox" ? "bwp-active" : ""
                                    }
                                >
                                    <a href="#" onClick={handleTabClicked("searchbox")}>
                                        {translations.findHolidayHomes}
                                    </a>
                                </li>
                                <li
                                    className={
                                        activeSearchLayerTab === "lodgingsearch" ? "bwp-active" : ""
                                    }
                                >
                                    <a href="#" onClick={handleTabClicked("lodgingsearch")}>
                                        {translations.findHolidayHome}
                                    </a>
                                </li>
                            </ul>
                        </div>
                        {activeSearchLayerTab === "searchbox" && (
                            <div className="bwp-menu-bar-layer__tab bwp-tabs-searchbox">
                                <HeroSearchSearchBox {...searchBox} />
                            </div>
                        )}
                        {activeSearchLayerTab === "lodgingsearch" && (
                            <div className="bwp-menu-bar-layer__tab bwp-tabs-lodgingsearch">
                                <div>
                                    <AutoCompleteFieldWithLabel
                                        label={translations.lodgingSearchLabel}
                                        focus={true}
                                        onChange={handleLodgingSearchChange}
                                        items={lodgings}
                                        onLoadItems={handleLoadLodgings}
                                        {...getLodgingAutoComplete()}
                                    />
                                </div>
                                <p>{translations.lodgingSearchDescription}</p>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </React.Fragment>
    );
}

export interface MenuWithSubMenuProps {
    menu: NavNode;
    isOpen: boolean;
    onClick: (subMenu: NavNode[]) => void;
    isActive: boolean;
}

export function MenuWithSubMenu({ menu, isOpen, onClick, isActive }: MenuWithSubMenuProps) {
    const [menuBounds, setMenuBounds] = useState(null);
    const menuRef: React.RefObject<HTMLLIElement> = useRef(null);

    const [subMenuBounds, setSubMenuBounds] = useState(null);
    const subMenuRef: React.RefObject<HTMLDivElement> = useRef(null);

    const handleMenuClick = (e: React.SyntheticEvent, subMenu: NavNode[]) => {
        e.preventDefault();
        onClick(subMenu);
    };

    useIsomorphicLayoutEffect(() => {
        setBoundsFromRef(menuRef, menuBounds, setMenuBounds);
        setBoundsFromRef(subMenuRef, subMenuBounds, setSubMenuBounds);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const subMenuStyle =
        menuBounds && subMenuBounds
            ? { left: Math.round((menuBounds.width - subMenuBounds.width) / 2) }
            : {};

    const classNames: string[] = [];
    if (isActive) {
        classNames.push("bwp-active");
    }
    if (isOpen) {
        classNames.push("bwp-open");
    }

    return (
        <li ref={menuRef} className={classNames.join(" ")}>
            <button
                type="button"
                onClick={(e) => handleMenuClick(e, menu.nodes)}
                aria-expanded={isOpen}
            >
                {menu.name}
            </button>
            {isOpen && (
                <div className="bwp-menu-bar__submenu" ref={subMenuRef} style={subMenuStyle}>
                    <ul>
                        {menu.nodes.map((submenu) => (
                            <li key={`menu${submenu.url}_${submenu.name}`}>
                                <a href={submenu.url}>{submenu.name}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </li>
    );
}

export interface MenuProps {
    menu: NavNode;
    isActive: boolean;
}

export function Menu({ menu, isActive }: MenuProps) {
    return (
        <li className={isActive ? "bwp-active" : ""}>
            <a href={menu.url || "#"}>{menu.name}</a>
        </li>
    );
}

function thisOrParentElement(element: HTMLElement, matcher: (e: HTMLElement) => boolean) {
    if (matcher(element)) {
        return true;
    } else {
        if (element.parentElement != null) {
            return thisOrParentElement(element.parentElement, matcher);
        }
    }

    return false;
}
