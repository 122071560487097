import { SearchContext, SearchContextProps } from "./searchcontext";
import { useMemo, useState } from "react";
import { LodgingListSetting, UrlReader } from "./types";
import { lodgingListSettingToSearchContext } from "./lodgingListUtils";

/**
 * A hook that supplies access to a SearchContext instance.
 * If you supply a urlWriter it will automaticly update the url with history.replaceState
 * If you supply a urlReader it will read the value from the url on popState and merge the defaultValue with the current url values on first render
 * @param defaultValue the default value to use for a new instance of the searchContext
 */
export default function useLocalSearchContext({
    defaultValue = {},
    lodgingListSetting
}: {
        defaultValue?: SearchContextProps;
        lodgingListSetting?: LodgingListSetting;
    }) {
    let defaultSearchContextValue = useInitialSearchContext({ defaultValue, lodgingListSetting });

    let [searchContext, setSearchContext] = useState(defaultSearchContextValue);

    function handleSetSearchContext(searchContext: SearchContext) {
        setSearchContext(searchContext);
    }

    return [searchContext, handleSetSearchContext] as [
        SearchContext,
        (searchContext: SearchContext) => void
    ];
}

/**
 * Gets a merge of the default search context values supplied and the ones using the urlReader.
 * These will not change on window popState
 * @param defaultValue
 * @param urlReader
 */
function useInitialSearchContext({ defaultValue, lodgingListSetting }: { defaultValue?: SearchContextProps, lodgingListSetting?: LodgingListSetting }) {
    return useMemo(() => SearchContext.createFromSearchContextProps(defaultValue), [defaultValue]).usePreset(lodgingListSettingToSearchContext(lodgingListSetting));
}
