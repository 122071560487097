import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "./LodgingBookingBox.scss";
import { LodgingPresentationQuery, useLodgingCalendarDataQuery } from "./operations.generated";
import { LodgingPageTranslations } from "./translations/LodgingPageTranslations";
import { defaultValue, stringFormat } from "./shared/helpers";
import Button from "./Button";
import { getImageUrl } from "./shared/images";
import Image from "./Image";
import DatePickerWithLabel, { DatePickerWithLabelRef } from "./DatePickerWithLabel";
import { SelectionMode } from "./DatePicker";
import { DurationSelectWithLabel } from "./DurationSelectWithLabel";
import { NumberSelectWithLabel } from "./NumberSelectWithLabel";
import useGlobalSearchContext from "./shared/useGlobalSearchContext";
import { SearchBoxTranslations } from "./translations/SearchBoxTranslations";
import { SearchBoxSettings } from "./shared/types";
import { DatePickerTranslations } from "./translations/DatePickerTranslations";
import { DatePickerCalendarDay } from "./DatePickerCalendar";
import { SearchContext } from "./shared/searchcontext";
import { CloseIcon } from "./CloseIcon";
import PeopleSelectWithLabel from "./PeopleSelectWithLabel";
import SelectWithLabel from "./SelectWithLabel";
import { startOfDay } from "date-fns";

interface Props {
    translations: LodgingPageTranslations;
    searchBoxTranslations: SearchBoxTranslations;
    datePickerTranslations: DatePickerTranslations;
    lodgingPresentation: LodgingPresentationQuery["lodgingPresentation"];
    searchBoxSettings: SearchBoxSettings;
    culture: string;
    onBook?: () => void;
}

export default function LodgingBookingBox({
    translations,
    searchBoxTranslations,
    datePickerTranslations,
    searchBoxSettings,
    lodgingPresentation: { lodging, selectedBookingOption },
    culture,
    onBook,
}: Props) {
    const [open, setOpen] = useState(false);
    const containerRef = useRef<HTMLDivElement>();

    const [searchContext, setSearchContext] = useGlobalSearchContext({ defaultValue });
    const datePickerRef = useRef<DatePickerWithLabelRef>();

    useEffect(() => {
        if (open) {
            containerRef.current.scrollIntoView({ block: "start" });
            document.body.style.overflowY = "hidden";
        } else {
            document.body.style.overflowY = "auto";
        }
    }, [open]);

    let dayManipulator = useCalendarDayManipulator({ searchContext });

    const showMaxNumberOfPersonsWarning =
        searchContext.adults + searchContext.children > lodging.maxPeople;

    const availableDurations = useMemo(() => {
        let durations = searchBoxSettings.availableDurations;
        if (selectedBookingOption && durations.indexOf(selectedBookingOption.duration) === -1) {
            durations.push(selectedBookingOption.duration);
            durations.sort((a, b) => a - b);
        }
        return durations;
    }, [searchBoxSettings, selectedBookingOption]);

    return (
        <div className={"bwp-lodging-booking-box"}>
            <div
                onClick={() => setOpen(true)}
                className="bwp-lodging-booking-box__open-booking-button"
                bwp-show={!open ? "" : undefined}
            >
                <Button type="primary">{translations.book}</Button>
            </div>
            <div
                ref={containerRef}
                className="bwp-lodging-booking-box__container"
                bwp-show={open ? "" : undefined}
            >
                <div className="bwp-lodging-booking-box__close-icon" onClick={() => setOpen(false)}>
                    <CloseIcon />
                </div>
                <div className="bwp-lodging-booking-box__lodging-summary">
                    <div className="bwp-lodging-booking-box__lodging-summary__image">
                        {lodging.images.length > 0 && (
                            <Image
                                imageUrlFunc={(width, height) =>
                                    getImageUrl(lodging.images[0], width, height)
                                }
                                alt={lodging.name}
                                width="56px"
                                height="50px"
                            />
                        )}
                    </div>
                    <div className="bwp-lodging-booking-box__lodging-summary__description">
                        <div className="bwp-lodging-booking-box__lodging-summary__name">
                            {translations.lodgingNamePrefix} {lodging.name}
                        </div>
                        <div className="bwp-lodging-booking-box__lodging-summary__address">
                            {lodging.location.name}
                        </div>
                    </div>
                </div>
                <div className="bwp-lodging-booking-box__parameters">
                    <div className="bwp-hide-on-bigger-than-phone">
                        <DatePickerWithLabel
                            ref={datePickerRef}
                            label={searchBoxTranslations.arrivalLabel}
                            value={searchContext.parsedArrival}
                            onChange={(v) => setSearchContext(searchContext.changeArrival(v))}
                            selectionMode={SelectionMode.Future}
                            culture={culture}
                            translations={datePickerTranslations}
                            dayManipulator={dayManipulator}
                        />
                    </div>
                    <div className="bwp-hide-on-phone">
                        <DatePickerWithLabel
                            ref={datePickerRef}
                            label={searchBoxTranslations.arrivalLabel}
                            value={searchContext.parsedArrival}
                            onChange={(v) => setSearchContext(searchContext.changeArrival(v))}
                            selectionMode={SelectionMode.Future}
                            culture={culture}
                            translations={datePickerTranslations}
                            dayManipulator={dayManipulator}
                            showTwoMonths
                        />
                    </div>
                    <DurationSelectWithLabel
                        id="duration"
                        label={searchBoxTranslations.durationLabel}
                        value={searchContext.duration}
                        onChange={(v) => setSearchContext(searchContext.changeDuration(v))}
                        translations={searchBoxTranslations}
                        durations={availableDurations}
                    />
                    <PeopleSelectWithLabel
                        label={searchBoxTranslations.personsLabel}
                        maxPersons={searchBoxSettings.maxPeople}
                        value={{
                            adults: searchContext.adults ?? 0,
                            children: searchContext.children ?? 0,
                        }}
                        onChange={(v) =>
                            setSearchContext(
                                searchContext.changePersons(
                                    v.adults,
                                    v.children,
                                    null,
                                    searchContext.pets
                                )
                            )
                        }
                        translations={searchBoxTranslations}
                        warningMessage={
                            showMaxNumberOfPersonsWarning
                                ? translations.theHouseMayNotBeAvailableForTheSelectedNumberOfPeople
                                : undefined
                        }
                    />
                    {lodging.petsAllowed && (
                        <NumberSelectWithLabel
                            id="pets"
                            label={searchBoxTranslations.petsLabel}
                            itemTranslation={searchBoxTranslations.pet}
                            value={searchContext.pets ?? 0}
                            min={0}
                            max={lodging.maxPets ?? 1}
                            onChange={(v) => setSearchContext(searchContext.changePets(v))}
                        />
                    )}
                    {!lodging.petsAllowed && (
                        <SelectWithLabel
                            id="pets"
                            label={searchBoxTranslations.petsLabel}
                            value={null}
                            onChange={() => {}}
                            items={[{ value: null, text: translations.petsNotAllowed }]}
                            disabled
                        />
                    )}
                </div>
                {selectedBookingOption && (
                    <PriceTable
                        translations={translations}
                        selectedBookingOption={selectedBookingOption}
                        onBook={onBook}
                    />
                )}
                {selectedBookingOption == null && (
                    <div className="bwp-lodging-booking-box__no-bookingoption-available-container">
                        {showMaxNumberOfPersonsWarning && (
                            <p>
                                <strong>
                                    {
                                        translations.theHouseMayNotBeAvailableForTheSelectedNumberOfPeople
                                    }
                                </strong>
                            </p>
                        )}
                        <p>{translations.noAvailableBookingOption}</p>
                        <Button
                            type={"primary"}
                            onClick={() => datePickerRef.current.focusAndShowCalendar()}
                        >
                            {translations.chooseAnotherArrivalDate}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
}

interface PriceTableProps {
    translations: LodgingPageTranslations;
    selectedBookingOption: LodgingPresentationQuery["lodgingPresentation"]["selectedBookingOption"];
    onBook: () => void;
}

function PriceTable({ selectedBookingOption, translations, onBook }: PriceTableProps) {
    const isProbabilityWithoutPrice = selectedBookingOption?.status == "Probability";
    return (
        <>
            {selectedBookingOption.itemPrices
                ?.filter((ip) => ip.isMandatory)
                .map((ip, index) => (
                    <div className="bwp-lodging-booking-box__item-row" key={index}>
                        <div>{ip.item.name}</div>
                        <div>{ip.priceWithQuantityDisplayValue}</div>
                    </div>
                ))}
            {!isProbabilityWithoutPrice && (
                <div className="bwp-lodging-booking-box__item-row">
                    <div>
                        {stringFormat(
                            translations.periodPrice,
                            selectedBookingOption.arrivalDisplayValue,
                            selectedBookingOption.departureDisplayValue
                        )}
                    </div>
                    <div>{selectedBookingOption.normalPriceDisplayValue}</div>
                </div>
            )}
            {selectedBookingOption.hasDiscount && (
                <div className="bwp-lodging-booking-box__item-row">
                    <div>{selectedBookingOption.discountName}</div>
                    <div>{selectedBookingOption.discountReductionDisplayValue}</div>
                </div>
            )}
            {!isProbabilityWithoutPrice && (
                <div className="bwp-lodging-booking-box__total-row">
                    <div>{translations.price}</div>
                    <div>{selectedBookingOption.priceWithMandatoryItemsDisplayValue}</div>
                </div>
            )}
            <div className="bwp-lodging-booking-box__book-row">
                <Button type="primary" onClick={() => onBook()}>
                    {isProbabilityWithoutPrice ? translations.reserve : translations.book}
                </Button>
            </div>
        </>
    );
}

function useCalendarDayManipulator({ searchContext }: { searchContext: SearchContext }) {
    let [monthAndYear, setMonthAndYear] = useState<{ month: number; year: number }>({
        month: searchContext.arrival.getMonth() + 1,
        year: searchContext.arrival.getFullYear(),
    });

    let numberOfMonths = 4;
    let { data, loading, error } = useLodgingCalendarDataQuery({
        variables: {
            month: monthAndYear.month,
            year: monthAndYear.year,
            numberOfMonths: numberOfMonths,
            query: searchContext.toQueryString(),
        },
    });

    let days = data?.calendar?.days;

    let callback = useCallback(
        (
            currentYear: number,
            currentMonth: number,
            day: DatePickerCalendarDay,
            dayManipulatorLastReturnValue: boolean
        ) => {
            if (loading || error) {
                return dayManipulatorLastReturnValue;
            }

            if (day.parsedDate < startOfDay(new Date())) {
                day.classNames.push("bwp-calendar__cell-past-date");
                return dayManipulatorLastReturnValue;
            }

            let dayData = days?.find((d) => d.date == day.date);

            if (dayData == null) {
                if (dayManipulatorLastReturnValue !== false) {
                    // We need to make sure we will only call the event once - so we use the return value as a marker boolean
                    setMonthAndYear({ month: currentMonth, year: currentYear });
                }
                return false;
            }

            if (dayData.isChangeDay && dayData.isAvailable) {
                day.classNames.push("bwp-calendar__cell-arrival-date");
            }

            if (!dayData.isAvailable) {
                day.selectable = false;
                day.unSelectableReason = "Unavailable";
                day.classNames.push("bwp-calendar__cell-unavailable");
            } else {
                day.classNames.push("bwp-calendar__cell-available");
                if (dayData.selectedBookingOption) {
                    day.suggestionRange = {
                        from: dayData.selectedBookingOption.arrival,
                        to: dayData.selectedBookingOption.departure,
                    };
                    day.overrideValue = dayData.selectedBookingOption.arrival;
                    day.title = dayData.selectedBookingOption.priceDisplayValue;
                }
            }

            return dayManipulatorLastReturnValue;
        },
        [days, error, loading]
    );

    return callback;
}
