import React, { useCallback, useMemo } from "react";
import "./LodgingCard2.scss";
import CardImageSlider from "./CardImageSlider";
import { LodgingCardTranslations } from "./translations/LodgingCardTranslations";
import { formattedValue, pluralize } from "./shared/helpers";
import { processTemplate } from "./shared/templates";
import { getImageUrl } from "./shared/images";
import { LastMinuteQuery, LodgingListQuery, SearchResultsPageQuery } from "./operations.generated";
import { SearchContext } from "./shared/searchcontext";

type LodgingForLodgingCard =
    | SearchResultsPageQuery["search"]["results"][0]["lodging"]
    | LodgingListQuery["lodgingList"]["results"][0]["lodging"]
    | LastMinuteQuery["lastMinute"]["results"][0]["lodging"]
    | {
          id: number;
          activeDescription?: {
              title: string | null;
          };
          name: string;
          address1: string;
          petsAllowed?: boolean;
          maxPeople?: number;
          images: {
              url: string;
          }[];
          location: {
              name: string;
          };
          facilityValues: {
              facilityId: number;
              masterFacilityId?: number;
              combinedHtmlDisplayValue: string;
              value: string;
          }[];
          stars?: number;
          starsPostText: string;
      };

interface LodgingCard2Props {
    lodging: LodgingForLodgingCard;
    selectedBookingOption?: {
        status: string;
        priceWithMandatoryItemsDisplayValue: string;
        arrivalWeekDayName: string;
        arrivalDisplayValue: string;
        departureDisplayValue: string;
        duration?: number;
        hasDiscount: boolean;
        normalPriceWithMandatoryItems?: number;
        discountName?: string;
        cleaningIncludedInPriceWithMandatoryItems: boolean;
    };
    seasonPrices?: {
        price: number;
        weeklyPriceDisplayValue: string;
        seasonCode: string;
    }[];
    extraFacilities?: number[];
    nextUrl: string;
    translations: LodgingCardTranslations;
    isFavorite?: boolean;
    onSetFavorite?: (id: number) => void;
    disableSmartImage?: boolean;
    disableImageSlider?: boolean;
    searchContext?: SearchContext;
}

export default function LodgingCard2({
    lodging,
    extraFacilities = [],
    nextUrl,
    translations,
    selectedBookingOption,
    seasonPrices,
    isFavorite,
    onSetFavorite,
    disableSmartImage,
    disableImageSlider,
    searchContext,
}: LodgingCard2Props) {
    const handleClick = useCallback(() => {
        window.history.replaceState(
            { ...window.history.state, jumpBackToX: window.scrollX, jumpBackToY: window.scrollY },
            null,
            null
        );
        window.location.href = nextUrl;
    }, [nextUrl]);

    let petsAllowedLabel = null;
    let personLabel = null;
    if (lodging.maxPeople !== null) {
        personLabel = pluralize(lodging.maxPeople, translations.person);
    }
    if (lodging.petsAllowed !== null) {
        petsAllowedLabel = lodging.petsAllowed
            ? translations.petsAllowed
            : translations.petsNotAllowed;
    }

    let extraFacilityRows = useMemo(
        () =>
            extraFacilities.reduce((result, cur, index) => {
                if (index % 2 == 0) {
                    result.push([]);
                }

                let arr = result[result.length - 1];
                let val = Array.from(lodging.facilityValues).find((f) => f.facilityId == cur);
                if (val) {
                    arr.push(val.combinedHtmlDisplayValue);
                }

                return result;
            }, [] as string[][]),
        [extraFacilities, lodging.facilityValues]
    );

    const hasTitle =
        lodging.activeDescription?.title != null && lodging.activeDescription?.title.length > 0;

    let {
        arrivalDisplayValue = null,
        arrivalWeekDayName = null,
        departureDisplayValue = null,
        priceWithMandatoryItemsDisplayValue = null,
        hasDiscount = false,
        discountName = null,
        normalPriceWithMandatoryItems = null,
        duration = null,
    }: Partial<LodgingCard2Props["selectedBookingOption"]> = selectedBookingOption || {};

    let hasBookingOption = selectedBookingOption != null;
    let lowestSeasonPrice = (seasonPrices ?? []).reduce(
        (prev, cur) => (prev == null || prev.price > cur.price ? cur : prev),
        null
    );

    const isProbabilityWithoutPrice = selectedBookingOption?.status == "Probability";

    const warningText: React.ReactNode =
        searchContext && duration && searchContext.duration != duration ? (
            <>
                {" - "}
                <strong>{pluralize(duration, translations.noticeDuration)}</strong>
            </>
        ) : null;

    return (
        <div className="bwp-lodging-card-2">
            <CardImageSlider
                images={lodging.images}
                favourite={isFavorite}
                enableFavorite={onSetFavorite != null}
                enableImageSlider={!disableImageSlider}
                nextUrl={nextUrl}
                title={lodging.activeDescription?.title ?? lodging.name}
                translations={translations}
                onFavouriteClick={() => onSetFavorite(lodging.id)}
                disableSmartImage={disableSmartImage}
                getImageUrl={(url, width, height) => getImageUrl({ url }, width, height)}
            >
                <div className="bwp-lodging-card-2__banner-container">
                    {discountName && (
                        <div className="bwp-lodging-card-2__discount-banner" onClick={handleClick}>
                            {discountName}
                        </div>
                    )}
                    {selectedBookingOption?.cleaningIncludedInPriceWithMandatoryItems && (
                        <div className="bwp-lodging-card-2__cleaning-banner" onClick={handleClick}>
                            {translations.cleaningIncluded}
                        </div>
                    )}
                </div>
            </CardImageSlider>
            <div className="bwp-lodging-card-2__content" onClick={handleClick}>
                <div className="bwp-lodging-card-2__location">{lodging.location.name}</div>
                <div className="bwp-lodging-card-2__title">
                    {hasTitle ? lodging.activeDescription?.title : lodging.name}
                </div>
                <div className="bwp-lodging-card-2__subtitle">
                    {lodging.activeDescription != null && (
                        <>
                            <span>
                                {translations.houseNumber} {lodging.name} -{" "}
                            </span>
                        </>
                    )}
                    <span>{lodging.address1}</span>
                </div>
                <div className="bwp-lodging-card-2__facility-row">
                    {[personLabel, petsAllowedLabel].filter((l) => l != null).join(" - ")}
                </div>
                {extraFacilityRows.map((row, index) => (
                    <div key={index} className="bwp-lodging-card-2__facility-row">
                        {row.filter((l) => l != null).join(" - ")}
                    </div>
                ))}
            </div>
            {(hasBookingOption || lowestSeasonPrice != null) && (
                <div
                    className="bwp-lodging-card-2__price-row"
                    data-has-discount={hasDiscount}
                    onClick={handleClick}
                >
                    {hasBookingOption && (
                        <>
                            <div className="bwp-lodging-card-2__price-row__first-row">
                                <div>
                                    {translations.arrival}: {arrivalWeekDayName}
                                    {warningText}
                                </div>
                                {hasDiscount && !warningText && (
                                    <div>
                                        <del>
                                            {new Intl.NumberFormat(window["culture"], {
                                                maximumFractionDigits: 2,
                                                minimumFractionDigits: 2,
                                            }).format(normalPriceWithMandatoryItems)}
                                        </del>
                                    </div>
                                )}
                            </div>
                            <div className="bwp-lodging-card-2__price-row__second-row">
                                <div>
                                    {processTemplate(translations.period, {
                                        from: arrivalDisplayValue,
                                        to: departureDisplayValue,
                                    })}
                                </div>
                                <div>
                                    {isProbabilityWithoutPrice
                                        ? translations.canBeReserved
                                        : priceWithMandatoryItemsDisplayValue}
                                </div>
                            </div>
                        </>
                    )}
                    {hasBookingOption == false && lowestSeasonPrice != null && (
                        <div className="bwp-lodging-card-2__price-row__second-row">
                            {processTemplate(translations.fromPricePerPeriod, {
                                price: lowestSeasonPrice.weeklyPriceDisplayValue,
                                period: translations.perWeek,
                            })}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}
