import React from "react";

export const IconSearch: React.ReactNode = (
    <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.1429 9.35714C14.1429 12.3895 11.6752 14.8571 8.64286 14.8571C5.61049 14.8571 3.14286 12.3895 3.14286 9.35714C3.14286 6.32478 5.61049 3.85714 8.64286 3.85714C11.6752 3.85714 14.1429 6.32478 14.1429 9.35714ZM20.4286 19.5714C20.4286 19.154 20.2567 18.7489 19.9743 18.4665L15.7634 14.2556C16.7578 12.8192 17.2857 11.1004 17.2857 9.35714C17.2857 4.58147 13.4185 0.714285 8.64286 0.714285C3.86719 0.714285 0 4.58147 0 9.35714C0 14.1328 3.86719 18 8.64286 18C10.3862 18 12.1049 17.4721 13.5413 16.4777L17.7522 20.6763C18.0346 20.971 18.4397 21.1429 18.8571 21.1429C19.7165 21.1429 20.4286 20.4308 20.4286 19.5714Z" fill="white" />
    </svg>
);

export const IconMenu: React.ReactNode = (
    <svg width="23" height="16" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line y1="1" x2="23" y2="1" stroke="white" strokeWidth="2" />
        <line y1="15" x2="23" y2="15" stroke="white" strokeWidth="2" />
        <line y1="8" x2="23" y2="8" stroke="white" strokeWidth="2" />
    </svg>
);

export const IconClose: React.ReactNode = (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="1.70711" y1="1.29289" x2="23.7071" y2="23.2929" stroke="white" strokeWidth="2" />
        <line y1="-1" x2="31.1127" y2="-1" transform="matrix(-0.707107 0.707107 0.707107 0.707107 24 2)" stroke="white" strokeWidth="2" />
    </svg>
);

export interface CommentIconProps {
    width?: number;
    height?: number; 
    fill?: string;
}

export function CommentIcon({ width, height, fill }: CommentIconProps) {

    const safeWidth = width || 21;
    const safeHeight = height || 20;
    const safeFill = fill || "#777777";

    const viewBox = `0 0 ${safeWidth} ${safeHeight}`;

    return (
        <svg
            width={safeWidth}
            height={safeHeight}
            viewBox={viewBox}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.5 0C4.70039 0 0 4.15625 0 9.28571C0 11.5 0.877734 13.5268 2.33789 15.1205C1.8252 17.3705 0.110742 19.375 0.0902344 19.3973C0 19.5 -0.0246094 19.6518 0.0287109 19.7857C0.0820312 19.9196 0.196875 20 0.328125 20C3.04746 20 5.08594 18.5804 6.09492 17.7054C7.43613 18.2545 8.925 18.5714 10.5 18.5714C16.2996 18.5714 21 14.4152 21 9.28571C21 4.15625 16.2996 0 10.5 0Z"
                fill={safeFill}
            />
        </svg>
    );
}