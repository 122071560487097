import * as React from "react";
import LodgingImagesViewer from "./LodgingImagesViewer";

import "./LodgingImages.scss";
import { LodgingPresentationQuery } from "./operations.generated";
import { LodgingPageTranslations } from "./translations/LodgingPageTranslations";
import Breadcrumb, { BreadcrumbItem } from "./Breadcrumb";
import FavoriteButton from "./FavoriteButton";

interface Props {
    clusterImageTagId: number;
    floorPlainImageTagId: number;
    useGrouping: boolean;
    translations: LodgingPageTranslations;
    breadcrumb: BreadcrumbItem[];
    favorite: boolean;
    onFavoriteChanged: (value: boolean) => void;
}

export default function LodgingImages({
    images,
    useGrouping,
    clusterImageTagId,
    floorPlainImageTagId,
    translations,
    activeDescription,
    breadcrumb,
    favorite,
    onFavoriteChanged,
}: Props & LodgingPresentationQuery["lodgingPresentation"]["lodging"]) {
    return (
        <>
            <div className="bwp-lodging-images__head">
                <Breadcrumb items={[...breadcrumb]} />
                <FavoriteButton
                    value={favorite}
                    onChange={onFavoriteChanged}
                    text={favorite ? translations.removeFromFavorites : translations.addToFavorites}
                />
            </div>
            <LodgingImagesViewer
                isGrouped={useGrouping}
                images={images}
                clusterImageTagId={clusterImageTagId}
                floorPlainImageTagId={floorPlainImageTagId}
                translations={translations}
                embeddedVideoHtml={activeDescription?.embeddedVideoHtml}
            />
        </>
    );
}
