import * as React from 'react'

interface AnimatedCounterProps {
    number: number;
}

interface AnimatedCounterState {
    currentNumber: number;
}

export default class AnimatedCounter extends React.Component<AnimatedCounterProps, AnimatedCounterState> {

    private timer: any;

    constructor(props: AnimatedCounterProps) {
        super(props);
        this.state = {
            currentNumber: this.props.number
        }
    }
    
    render() {
        return <span>{this.state.currentNumber}</span>
    }

    componentDidUpdate() {
        if (this.state.currentNumber != this.props.number) {
            if (this.timer != null) {
                clearTimeout(this.timer);
            }
            this.timer = setTimeout(this.moveCloser, 5);
        }
    }

    private moveCloser = () => {
        if (this.state.currentNumber > this.props.number) {
            this.setState({ currentNumber: this.state.currentNumber - 1 });
        }
        if (this.state.currentNumber < this.props.number) {
            this.setState({ currentNumber: this.state.currentNumber + 1 });
        }
        this.timer = null;
    }

}