import * as Types from "./shared/graphql-types.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type LodgingsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type LodgingsQuery = {
    __typename?: "Query";
    lodgings?: Array<{
        __typename?: "Lodging";
        id: number;
        name: string;
        nameSlug: string;
        internalName: string;
        address1: string;
        address1Slug: string;
        location: { __typename?: "Location"; name: string; slug: string };
    } | null> | null;
};

export const LodgingsDocument = gql`
    query lodgings {
        lodgings {
            id
            name
            nameSlug
            internalName
            address1
            address1Slug
            location {
                name
                slug
            }
        }
    }
`;

/**
 * __useLodgingsQuery__
 *
 * To run a query within a React component, call `useLodgingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLodgingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLodgingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLodgingsQuery(
    baseOptions?: Apollo.QueryHookOptions<LodgingsQuery, LodgingsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<LodgingsQuery, LodgingsQueryVariables>(LodgingsDocument, options);
}
export function useLodgingsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<LodgingsQuery, LodgingsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<LodgingsQuery, LodgingsQueryVariables>(LodgingsDocument, options);
}
export type LodgingsQueryHookResult = ReturnType<typeof useLodgingsQuery>;
export type LodgingsLazyQueryHookResult = ReturnType<typeof useLodgingsLazyQuery>;
export type LodgingsQueryResult = Apollo.QueryResult<LodgingsQuery, LodgingsQueryVariables>;
