export function getImageUrl(image: { url: string }, width: number, height?: number) {
    let url = image.url.replace("/default", "");
    url = url.replace("/cl/", "/v2/");
    url = url + "?width=" + width;
    if (typeof height !== "undefined") {
        url = url + "&height=" + height;
    }
    url = url + "&mode=crop";
    return url;
}
