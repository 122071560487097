import * as React from "react";
import { useCallback, useRef, useState } from "react";
import useLazyLoad from "./shared/useLazyLoad";

export interface SmartBackgroundImageProps {
    imageUrlFunc: (width: number, height: number) => string;
    className?: string;
    link?: string;
    onClick?: (e) => void;
    title?: string;
}

export interface SmartBackgroundImageState {
    imageUrl: string;
    visible: boolean;
    imageLoaded: boolean;
}

export default function SmartBackgroundImage({
    imageUrlFunc,
    link,
    className,
    title,
    onClick,
    children,
}: React.PropsWithChildren<SmartBackgroundImageProps>) {
    const containerRef = useRef<HTMLDivElement>();
    const [imageUrl, setImageUrl] = useState<string>();
    const [imageLoaded, setImageLoaded] = useState(false);
    const [visible, setVisible] = useState(false);

    const handleVisible = useCallback(() => {
        setVisible(true);
        setImageUrl(
            imageUrlFunc(containerRef.current.offsetWidth, containerRef.current.offsetHeight)
        );
    }, [imageUrlFunc]);

    useLazyLoad(containerRef, handleVisible);

    const handleClick = useCallback(
        (e) => {
            if (onClick) {
                onClick(e);
            }
            if (link) {
                window.location.href = link;
            }
        },
        [link, onClick]
    );

    const handleImageLoaded = useCallback(() => {
        setImageLoaded(true);
    }, []);

    //if the complete imageurl is not loaded yet, get a very small image to show until thenn
    const url = imageLoaded ? "url(" + imageUrl + ")" : "url(" + imageUrlFunc(12, 12) + ")";

    return (
        <div
            tabIndex={link ? 0 : -1}
            ref={containerRef}
            role={link ? "link" : null}
            className={className}
            onClick={handleClick}
            title={title || ""}
            aria-label={title || null}
        >
            {imageUrl && visible && (
                <>
                    <div
                        style={{
                            backgroundImage: url,
                            width: "100%",
                            height: "100%",
                            backgroundSize: "cover",
                        }}
                    >
                        {children}
                    </div>
                    <img
                        src={imageUrl}
                        onLoad={handleImageLoaded}
                        style={{ display: "none" }}
                    ></img>
                </>
            )}
        </div>
    );
}
