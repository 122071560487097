import React, { useEffect, useMemo, useState } from "react";
import { LodgingCardTranslations } from "@bwp/translations/LodgingCardTranslations";
import useLocalSearchContext from "@bwp/shared/useLocalSearchContext";
import { defaultValue } from "@bwp/shared/helpers";
import { formatISO, parse } from "date-fns";
import useQueryString from "@bwp/shared/useQueryString";
import { useLastMinuteQuery } from "@bwp/operations.generated";
import { lodgingUrl } from "@bwp/shared/lodgingurl";
import { LodgingListSetting } from "@bwp/shared/types";
import SearchResultListSkeleton from "@bwp/SearchResultListSkeleton";
import CompactDiscountLodgingList from "@bwp/CompactDiscountLodgingList";
import format from "date-fns/format";

export interface CompactDiscountLodgingListWrapperProps {
    lodgingCardTranslations: LodgingCardTranslations;
    lodgingUrlPattern: string;
    daysAhead: number;
    take?: number;
    lodgingListSetting: LodgingListSetting;
    tagFilter: string;
    emptyResultHtmlContent: string;
}

export default function CompactDiscountLodgingListWrapper({
    lodgingCardTranslations,
    lodgingUrlPattern,
    lodgingListSetting,
    daysAhead,
    take,
    tagFilter,
    emptyResultHtmlContent,
}: CompactDiscountLodgingListWrapperProps) {
    const [sorting] = useQueryString("ord", null);

    // TODO: These needs to be set somewhere
    const numberOfBedroomsFacilityId = 27;
    const numberOfBathroomsFacilityId = 35;
    const extraFacilities = [numberOfBedroomsFacilityId, numberOfBathroomsFacilityId];

    const [searchContext] = useLocalSearchContext({ defaultValue, lodgingListSetting });
    const query = useLastMinuteQuery({
        variables: {
            query: searchContext.toQueryString(),
            onlyMasterFacilities: [],
            onlyFacilities: extraFacilities,
            displayDate: formatISO(new Date(), { representation: "date" }),
            sorting: sorting as any,
            take,
            daysAhead,
            tagFilter,
            onlyShowLodgingOnce: true,
        },
    });

    const [numberOfSkeletons, setNumberOfSeletons] = useState(4);

    useEffect(() => {
        if (query.data) {
            setNumberOfSeletons(query.data.lastMinute.results.length);
        }
    }, [query.data]);

    const queryString = useMemo(() => {
        return searchContext.toQueryString([
            "context:lodging-location",
            "context:lodging-address1",
        ]);
    }, [searchContext]);

    if (query.error) {
        return <div>{query.error}</div>;
    }

    if (query.loading) {
        return (
            <div className="bwp-compact-lodging-list">
                <SearchResultListSkeleton numberOfSkeletons={numberOfSkeletons} height={391} />
            </div>
        );
    }

    return (
        <>
            <CompactDiscountLodgingList
                lodgingCardTranslations={lodgingCardTranslations}
                {...query.data}
                lodgingUrlBuilder={(l) =>
                    `${lodgingUrl(lodgingUrlPattern, l.lodging)}?ari=${format(
                        parse(l.selectedBookingOption.arrival, "yyyy-MM-dd", new Date()),
                        "MM-dd-yyyy"
                    )}&dur=${l.selectedBookingOption.duration}&adu=${
                        l.selectedBookingOption.adults
                    }&chi=${l.selectedBookingOption.children}&pet=${l.selectedBookingOption.pets}`
                }
                extraFacilities={extraFacilities}
                emptyResultHtmlContent={emptyResultHtmlContent}
            />
        </>
    );
}
