import { SearchContext, SearchContextProps } from "./searchcontext";
import { useCallback, useEffect, useState } from "react";
import { isBrowser } from "./helpers";
import * as queryString from "querystring";
import { LodgingListSetting } from "./types";
import { lodgingListSettingToSearchContext } from "./lodgingListUtils";

function getSearchFromGlobal() {
    let tokens = globalUrl.split("?");
    if (tokens.length > 1) {
        return globalUrl.split("?")[1];
    } else {
        return "";
    }
}

function getSearchFromLocal() {
    let url = window["originalUrl"] || window.location.search;
    let tokens = url.split("?");
    if (tokens.length > 1) {
        return url.split("?")[1];
    } else {
        return "";
    }
}

function generateDefaultSearchContext(
    defaultValue: SearchContextProps,
    lodgingListSetting: LodgingListSetting
) {
    if (lodgingListSetting != null) {
        return isBrowser()
            ? SearchContext.createFromQueryString(getSearchFromLocal()).usePreset(
                  lodgingListSettingToSearchContext(lodgingListSetting)
              )
            : SearchContext.createFromQueryString(getSearchFromGlobal()).usePreset(
                  lodgingListSettingToSearchContext(lodgingListSetting)
              );
    }

    return isBrowser()
        ? SearchContext.createFromQueryStringWithDefaults(getSearchFromLocal(), defaultValue)
        : SearchContext.createFromQueryStringWithDefaults(getSearchFromGlobal(), defaultValue);
}

/**
 * A hook that supplies access to a SearchContext instance.
 */
export default function useGlobalSearchContext({
    defaultValue = {},
    lodgingListSetting,
}: {
    defaultValue?: SearchContextProps;
    lodgingListSetting?: LodgingListSetting;
}) {
    let [searchContext, setSearchContext] = useState(
        generateDefaultSearchContext(defaultValue, lodgingListSetting)
    );

    useEffect(() => {
        if (isBrowser()) {
            const listener = () => {
                setSearchContext(
                    SearchContext.createFromQueryStringWithDefaults(
                        window.location.search,
                        defaultValue
                    )
                );
            };
            const passiveListener = (e: { detail: SearchContext }) => {
                if (e.detail != searchContext) {
                    setSearchContext(e.detail);
                    replaceState(e.detail);
                }
            };
            const updatedListener = (e: { detail: SearchContext }) => {
                if (e.detail != searchContext) {
                    setSearchContext(e.detail);
                }
            };

            window.addEventListener("popstate", listener);
            window.addEventListener("passiveSearchContextUpdated", passiveListener as any);
            window.addEventListener("searchContextUpdated", updatedListener as any);

            return () => {
                window.removeEventListener("popstate", listener);
                window.removeEventListener("passiveSearchContextUpdated", passiveListener as any);
                window.removeEventListener("searchContextUpdated", updatedListener as any);
            };
        }
    }, [defaultValue, searchContext]);

    useEffect(() => {
        window.dispatchEvent(new CustomEvent("searchContextUpdated", { detail: searchContext }));
    }, [searchContext]);

    const handleSetSearchContext = useCallback((searchContext: SearchContext) => {
        if (isBrowser()) {
            setSearchContext(searchContext);
            replaceState(searchContext);
        } else {
            throw "Global Search Context does not supporting setting the value outside of a browser context";
        }
    }, []);

    return [searchContext, handleSetSearchContext] as [
        SearchContext,
        (searchContext: SearchContext) => void
    ];
}

export function replaceState(searchContext: SearchContext) {
    let queryStringValuesToKeep = {};
    if (window.location.search.startsWith("?")) {
        let qs = queryString.parse(window.location.search.substring(1));

        Object.keys(qs).forEach((key) => {
            if (!SearchContext.isSearchContextQueryStringKeyName(key)) {
                queryStringValuesToKeep[key] = qs[key];
            }
        });
    }

    let searchContextQueryString = searchContext.toQueryString(
        ["context:lodging-address1", "context:lodging-location"],
        true
    );
    let parsedSearchContextQueryString = queryString.parse(searchContextQueryString);
    Object.keys(parsedSearchContextQueryString).forEach((key) => {
        queryStringValuesToKeep[key] = parsedSearchContextQueryString[key];
    });

    let url = window.location.href.split("?")[0];

    let qs = queryString.stringify(queryStringValuesToKeep);

    if (qs != "") {
        url = "?" + qs;
    }

    window.history.replaceState(null, null, url);
}
