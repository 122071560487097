import React, { useCallback } from "react";
import "./Spot.scss";
interface Props {
    image: {
        src: string;
        srcSet?: string;
        sizes?: string;
        alt: string;
    };
    category?: string;
    headline: string;
    imageHeight: string;
    link?: string;
    overlay?: "video";
    openInNewTab?: boolean;
}

export default function Spot({ image, category, headline, link, openInNewTab, overlay }: Props) {
    const onClickHandler = useCallback(
        () => {
            if (link) {
                window.location.href = link;
            }
        },
        [link]
    );

    let linkTarget = undefined;
    let rel = undefined;
    if (openInNewTab) {
        linkTarget = "_blank";
        rel = "noopener noreferrer";
    }

    const classNames = ['bwp-spot'];
    if (overlay) {
        classNames.push(`bwp-spot__overlay--${overlay}`);
    }

    return (
        <div
            className={classNames.join(' ')}
            style={{ cursor: link ? "pointer" : undefined }}
            onClick={onClickHandler}
        >
            {image && <img {...image} loading="lazy" />}
            {category && <h4>{category}</h4>}
            {headline && <h3>{link ? <a target={linkTarget} rel={rel} href={link}>{headline}</a> : headline}</h3>}
        </div>
    );
}
