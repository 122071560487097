import React from "react";
import { ButtonType } from "./shared/types";
import "./Button.scss";
import { removeEmptyProps } from "./shared/helpers";

export interface NavButtonStyles {
    marginTop?: string;
    marginBottom?: string;
    marginLeft?: string;
    marginRight?: string;
}

export interface NavButtonProps {
    type: ButtonType;
    url: string;
    children: React.ReactNode;
    isFullWidth?: boolean;
    isCentered?: boolean;
    openInNewTab?: boolean;
    style?: NavButtonStyles;
}

export default function NavButton({
    type,
    children,
    url,
    isFullWidth,
    isCentered,
    openInNewTab,
    style,
}: NavButtonProps) {
    const classNames = ["bwp-button"];
    if (type && type !== "default") {
        classNames.push(`bwp-button--${type}`);
    }
    if (isFullWidth) {
        classNames.push("bwp-button--fullwidth");
    }

    const linkProps = {
        href: url,
        className: classNames.join(" "),
        style: removeEmptyProps(style),
    };

    if (openInNewTab) {
        linkProps["target"] = "_blank";
    }

    const link = <a {...linkProps}>{children}</a>;

    if (isCentered) {
        return <div className="bwp-button--centered">{link}</div>;
    }

    return link;
}
