import React, {useEffect, useRef, useState} from 'react';
import "./HeroSearch.scss";
import HeroSearchSearchBox, {HeroSearchSearchBoxProps} from "./HeroSearchSearchBox";

export interface Props {
    title: string;
    paragraph: string;
    backgroundImageUrl: string;
    searchBox: HeroSearchSearchBoxProps;
}

export default function HeroSearch({title, paragraph, backgroundImageUrl, searchBox}: Props) {
    
    const containerRef = useRef<HTMLDivElement>();
    
    const [mounted, setIsMounted] = useState<boolean>(false);
    useEffect(() => {
        setIsMounted(true)
    }, [mounted]);
    
    function handleScrollDown() {
        window.scroll({
           behavior: "smooth",
           top: containerRef.current.offsetTop + containerRef.current.offsetHeight 
        });
    }
    
    return <div ref={containerRef} className="bwp-hero-search" style={{ "--hero-search-background": `url(${backgroundImageUrl}) center center`} as any}>
        <h1>{title}</h1>
        <p>{paragraph}</p>
        <HeroSearchSearchBox {...searchBox}  />

        <svg onClick={handleScrollDown} width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.75">
                <circle cx="17.5" cy="17.5" r="16" stroke="white" strokeWidth="3"/>
                <line x1="1.5" y1="-1.5" x2="11.5863" y2="-1.5" transform="matrix(0.725953 0.687745 -0.725953 0.687745 8 20)" stroke="white" strokeWidth="3" strokeLinecap="round"/>
                <line x1="1.5" y1="-1.5" x2="11.5863" y2="-1.5" transform="matrix(-0.725953 0.687745 0.725953 0.687745 27 20)" stroke="white" strokeWidth="3" strokeLinecap="round"/>
                <line x1="17.5" y1="6.5" x2="17.5" y2="26.5" stroke="white" strokeWidth="3" strokeLinecap="round"/>
            </g>
        </svg>
    </div>
    
}