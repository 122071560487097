import * as React from "react";
import SmartBackgroundImage from "./SmartBackgroundImage";

import "./CardImageSlider.scss";

export interface CardImageSliderTranslations {
    imageMissing: string;
    previousImage: string;
    nextImage: string;
    addToFavourites: string;
}

export interface CardImageSliderProps {
    key?: React.Key;
    images: { url: string, width?: number, height?: number }[];
    favourite: boolean;
    onFavouriteClick?: () => void;
    enableFavorite: boolean;
    enableImageSlider: boolean;
    disableSmartImage?: boolean;
    imageMaxWidth?: number;
    imageMaxHeight?: number;
    getImageUrl?: (url: string, width: number, height?: number) => string;
    translations: CardImageSliderTranslations;
    nextUrl: string;
    title: string;
    children?: React.ReactNode;
}

interface CardImageSliderState {
    current: number;
    favorite: boolean;
    imagesPreloaded: number[];
    direction: "prev" | "next" | "";
}

const MaxNumberOfImagesToPreload = 5;
const DefaultImageMaxWidth = 1140;

export default class CardImageSlider extends React.Component<
    CardImageSliderProps,
    CardImageSliderState
> {
    constructor(props: CardImageSliderProps) {
        super(props);
        this.state = {
            current: 0,
            favorite: false,
            imagesPreloaded: [0],
            direction: "",
        };
    }

    preloadImages = () => {
        const {
            images,
            getImageUrl,
            imageMaxWidth
        } = this.props;
        
        const { current, imagesPreloaded } = this.state;
        if (imagesPreloaded.length === 1) {
            const maxIndexToPreload: number = Math.min(MaxNumberOfImagesToPreload, images.length);
            const preloaded: number[] = [];
            for (let i = 1; i < maxIndexToPreload; i++) {
                const img: HTMLImageElement = document.createElement("img");
                if (getImageUrl) {
                    img.src = getImageUrl(images[i].url, imageMaxWidth || DefaultImageMaxWidth);
                } else {
                    img.src = images[i].url;
                }
                preloaded.push(i);
            }
            this.setState({
                imagesPreloaded: imagesPreloaded.concat(preloaded),
            });
        } else {
            const nextImageToPreload: number = current + (MaxNumberOfImagesToPreload - 1);
            if (nextImageToPreload < images.length) {
                const img: HTMLImageElement = document.createElement("img");
                if (getImageUrl) {
                    img.src = getImageUrl(images[nextImageToPreload].url, imageMaxWidth || DefaultImageMaxWidth);
                } else {
                    img.src = images[nextImageToPreload].url;
                }
                this.setState({
                    imagesPreloaded: imagesPreloaded.concat([nextImageToPreload]),
                });
            }
        }
    };

    handlePrev = (e: React.MouseEvent) => {
        e.preventDefault();
        const { current } = this.state;
        this.preloadImages();
        if (current > 0) {
            this.setState({
                direction: "prev",
                current: current - 1,
            });
        }
    };

    handleNext = (e: React.MouseEvent) => {
        e.preventDefault();
        const { current } = this.state;
        const images = this.props.images;
        this.preloadImages();
        if (current < images.length - 1) {
            this.setState({
                direction: "next",
                current: current + 1,
            });
        }
    };

    render(): JSX.Element {
        const {
            images = [],
            favourite,
            translations,
            disableSmartImage,
            imageMaxWidth,
            imageMaxHeight,
            nextUrl,
        } = this.props;

        const getImageUrl = this.props.getImageUrl || ((url) => url);

        const { current, imagesPreloaded } = this.state;
        const listStyles: React.CSSProperties = {
            left: `${current * -100}%`,
            width: `${Math.max(images.length * 100, 100)}%`,
        };

        return (
            <div className="bwp-card-image-slider">
                <a href={nextUrl}>
                    <div className="bwp-card-image-slider__list" style={listStyles}>
                        {images.map(
                            (image, index) =>
                                (imagesPreloaded.indexOf(index) > -1 &&
                                    ((disableSmartImage && (
                                        <div
                                            key={`card-image_${image.url}`}
                                            className="bwp-card-image-slider__image"
                                            style={{
                                                backgroundImage: `url(${getImageUrl(
                                                    image.url,
                                                    imageMaxWidth || DefaultImageMaxWidth
                                                )})`,
                                            }}
                                            title={this.props.title}
                                            aria-label={this.props.title}
                                        />
                                    )) || (
                                        <SmartBackgroundImage
                                            className="bwp-card-image-slider__image"
                                            imageUrlFunc={(width, height) =>
                                                getImageUrl(image.url, width, height)
                                            }
                                            key={`card-image_${image.url}`}
                                            title={this.props.title}
                                        />
                                    ))) || (
                                    <div
                                        key={`card-image_${image.url}`}
                                        className="bwp-card-image-slider__image bwp-card-image-slider__image--pending"
                                        title={this.props.title}
                                        aria-label={this.props.title}
                                    />
                                )
                        )}
                        {images.length === 0 && (
                            <div
                                className="bwp-card-image-slider__image bwp-card-image-slider__no-image"
                                title={translations.imageMissing}
                                aria-label={translations.imageMissing}
                            />
                        )}
                    </div>
                </a>
                {this.props.enableImageSlider && (
                    <React.Fragment>
                        <div
                            className="bwp-prev"
                            onClick={this.handlePrev}
                            title={translations.previousImage}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="11"
                                height="18"
                                viewBox="0 0 11 18"
                                fill="none"
                            >
                                <line
                                    y1="-0.5"
                                    x2="12.8909"
                                    y2="-0.5"
                                    transform="matrix(-0.715937 0.698165 0.715937 0.698165 10.4836 1)"
                                    stroke="white"
                                />
                                <line
                                    y1="-0.5"
                                    x2="11.4586"
                                    y2="-0.5"
                                    transform="matrix(-0.715937 -0.698165 -0.715937 0.698165 9.45819 18)"
                                    stroke="white"
                                />
                            </svg>
                        </div>
                        <div
                            className="bwp-next"
                            onClick={this.handleNext}
                            title={translations.nextImage}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="11"
                                height="18"
                                viewBox="0 0 11 18"
                                fill="none"
                            >
                                <line
                                    y1="-0.5"
                                    x2="12.8909"
                                    y2="-0.5"
                                    transform="matrix(0.715937 0.698165 -0.715937 0.698165 0.981812 1)"
                                    stroke="white"
                                />
                                <line
                                    y1="-0.5"
                                    x2="11.4586"
                                    y2="-0.5"
                                    transform="matrix(0.715937 -0.698165 0.715937 0.698165 2.00726 18)"
                                    stroke="white"
                                />
                            </svg>
                        </div>
                    </React.Fragment>
                )}
                {this.props.enableFavorite && (
                    <div
                        className="bwp-heart"
                        title={translations.addToFavourites}
                        onClick={(e) => {
                            e.preventDefault();
                            this.props.onFavouriteClick();
                        }}
                    >
                        {!favourite && (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={27}
                                height={23}
                                viewBox="0 0 27 23"
                                fill="none"
                            >
                                <path
                                    d="M1.50411 7.78749C1.8454 12.1866 13.7905 22 13.7905 22C13.7905 22 26.4181 11.5098 26.0768 7.78749C25.7355 4.06519 24.7117 1.69641 19.9336 1.01964C16.1795 1.35801 15.9952 1.97453 13.7905 3.72678C11.2056 1.45456 9.86336 0.880013 7.64729 1.01964C3.63024 2.14323 1.16282 3.3884 1.50411 7.78749Z"
                                    stroke="white"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        )}
                        {favourite && (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="27"
                                height="23"
                                viewBox="0 0 27 23"
                                fill="none"
                            >
                                <path
                                    d="M1.50411 7.78749C1.8454 12.1866 13.7905 22 13.7905 22C13.7905 22 26.4181 11.5098 26.0768 7.78749C25.7355 4.06519 24.7117 1.69641 19.9336 1.01964C16.1795 1.35801 15.9952 1.97453 13.7905 3.72678C11.2056 1.45456 9.86336 0.880013 7.64729 1.01964C3.63024 2.14323 1.16282 3.3884 1.50411 7.78749Z"
                                    fill="#ED303A"
                                    stroke="#8C3439"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        )}
                    </div>
                )}
                {this.props.children && (
                    <div className="bwp-card-image-slider__children">{this.props.children}</div>
                )}
            </div>
        );
    }

    handleImageClick = () => {
        window.location.href = this.props.nextUrl;
    };
}
