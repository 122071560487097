import React from "react";
import { LodgingPresentationQuery } from "./operations.generated";
import "./LodgingFacilityGroup.scss";

interface Props {
    groupName: string;
    facilityIds: number[];
}

export default function LodgingFacilityGroup({
    groupName,
    facilityIds,
    facilityValues,
}: Props & LodgingPresentationQuery["lodgingPresentation"]["lodging"]) {
    return (
        <div className="bwp-lodging-facility-group">
            <h3 className="bwp-lodging-facility-group__name">{groupName}</h3>
            {facilityIds
                .map((f) => facilityValues.find((fv) => fv.facilityId == f))
                .filter((fv) => fv != null)
                .map((fi) => (
                    <div key={fi.facilityId} className="bwp-lodging-facility-group__facility">
                        <div>{fi.facility.name}</div>
                        <div dangerouslySetInnerHTML={{ __html: fi.htmlDisplayValue }} />
                    </div>
                ))}
        </div>
    );
}
