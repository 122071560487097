import React, { useMemo } from "react";
import FieldWithLabel from "./FieldWithLabel";
import SelectWithLabel from "./SelectWithLabel";
import "./ContactDetailsForm.scss";
import "./Form.scss";
import { CheckoutTranslations } from "./translations/CheckoutTranslations";
import { CheckoutQuery } from "./operations.generated";
import { BookingRequestInput } from "./shared/graphql-types.generated";

export interface CountryProps {
    id: string;
    name: string;
}

type ValueType = Pick<
    BookingRequestInput["customer"],
    | "firstName"
    | "lastName"
    | "address"
    | "postalCode"
    | "city"
    | "country"
    | "privateTelephone"
    | "privateMobilephone"
    | "workTelephone"
    | "email"
>;

export interface ContactDetailsFormProps {
    value: ValueType;
    countries: CountryProps[];
    onChange: (customer: BookingRequestInput["customer"]) => void;
    translations: CheckoutTranslations;
}

export default function ContactDetailsForm({
    value,
    countries,
    onChange,
    translations,
}: ContactDetailsFormProps) {
    const countryOptions = useMemo(() => countries.map((c) => ({ value: c.id, text: c.name })), [
        countries,
    ]);

    return (
        <section className="bwp-form-section">
            <h2>{translations.contactDetailsFormTitle}</h2>
            <div className="bwp-form-section__list">
                <div className="bwp-form-section__col2">
                    <div className="bwp-form-section__cell">
                        <FieldWithLabel
                            required
                            value={value.firstName}
                            label={translations.firstname}
                            onChange={(newValue) =>
                                onChange({
                                    ...value,
                                    firstName: newValue,
                                } as BookingRequestInput["customer"])
                            }
                        />
                    </div>
                    <div className="bwp-form-section__cell">
                        <FieldWithLabel
                            required
                            value={value.lastName}
                            label={translations.lastname}
                            onChange={(newValue) =>
                                onChange({
                                    ...value,
                                    lastName: newValue,
                                } as BookingRequestInput["customer"])
                            }
                        />
                    </div>
                </div>
                <FieldWithLabel
                    required
                    value={value.address}
                    label={translations.address}
                    onChange={(newValue) =>
                        onChange({ ...value, address: newValue } as BookingRequestInput["customer"])
                    }
                />
                <div className="bwp-form-section__col2">
                    <div className="bwp-form-section__cell">
                        <FieldWithLabel
                            required
                            value={value.postalCode}
                            label={translations.postalCode}
                            onChange={(newValue) =>
                                onChange({
                                    ...value,
                                    postalCode: newValue,
                                } as BookingRequestInput["customer"])
                            }
                        />
                    </div>
                    <div className="bwp-form-section__cell">
                        <FieldWithLabel
                            required
                            value={value.city}
                            label={translations.city}
                            onChange={(newValue) =>
                                onChange({
                                    ...value,
                                    city: newValue,
                                } as BookingRequestInput["customer"])
                            }
                        />
                    </div>
                </div>
                <SelectWithLabel
                    value={value.country}
                    label={translations.country}
                    items={countryOptions}
                    onChange={(newValue) =>
                        onChange({ ...value, country: newValue } as BookingRequestInput["customer"])
                    }
                    id="country"
                />
                <FieldWithLabel
                    value={value.privateTelephone}
                    label={translations.telephone}
                    onChange={(newValue) =>
                        onChange({
                            ...value,
                            privateTelephone: newValue,
                        } as BookingRequestInput["customer"])
                    }
                    type="tel"
                />
                <FieldWithLabel
                    value={value.privateMobilephone}
                    label={translations.cellphone}
                    onChange={(newValue) =>
                        onChange({
                            ...value,
                            privateMobilephone: newValue,
                        } as BookingRequestInput["customer"])
                    }
                    type="tel"
                />
                <FieldWithLabel
                    value={value.workTelephone}
                    label={translations.workPhone}
                    onChange={(newValue) =>
                        onChange({
                            ...value,
                            workTelephone: newValue,
                        } as BookingRequestInput["customer"])
                    }
                    type="tel"
                />
                <FieldWithLabel
                    required
                    value={value.email}
                    label={translations.email}
                    onChange={(newValue) =>
                        onChange({ ...value, email: newValue } as BookingRequestInput["customer"])
                    }
                    type="email"
                />
            </div>
        </section>
    );
}
