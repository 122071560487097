import React, { useEffect, useMemo, useState } from "react";
import { LodgingCardTranslations } from "@bwp/translations/LodgingCardTranslations";
import useGlobalSearchContext from "@bwp/shared/useGlobalSearchContext";
import { defaultValue, formattedValue } from "@bwp/shared/helpers";
import { formatISO, parse, parseISO } from "date-fns";
import useQueryString from "@bwp/shared/useQueryString";
import { useLastMinuteQuery } from "@bwp/operations.generated";
import { lodgingUrl } from "@bwp/shared/lodgingurl";
import { LodgingListSetting, MasterFacilities, WeekInfo } from "@bwp/shared/types";
import SearchResultListSkeleton from "@bwp/SearchResultListSkeleton";
import DiscountLodgingList from "@bwp/DiscountLodgingList";
import { SearchResultListTranslations } from "@bwp/translations/SearchResultListTranslations";
import format from "date-fns/format";

export interface DiscountLodgingListWrapperProps {
    translations: SearchResultListTranslations;
    lodgingCardTranslations: LodgingCardTranslations;
    lodgingUrlPattern: string;
    daysAhead: number;
    lodgingListSetting: LodgingListSetting;
    tagFilter: string;
    emptyResultHtmlContent: string;
    weeks: WeekInfo[];
}

export default function DiscountLodgingListWrapper({
    translations,
    lodgingCardTranslations,
    lodgingUrlPattern,
    lodgingListSetting,
    daysAhead,
    tagFilter,
    emptyResultHtmlContent,
    weeks,
}: DiscountLodgingListWrapperProps) {
    const [sorting] = useQueryString("ord", null);

    const numberOfBedroomsFacilityId = 27;
    const numberOfBathroomsFacilityId = 35;
    const extraFacilities = [numberOfBedroomsFacilityId, numberOfBathroomsFacilityId];

    const [searchContext] = useGlobalSearchContext({ defaultValue, lodgingListSetting });

    const query = useLastMinuteQuery({
        variables: {
            query: searchContext.toQueryString(),
            onlyMasterFacilities: [],
            onlyFacilities: extraFacilities,
            displayDate: formatISO(new Date(), { representation: "date" }),
            sorting: sorting as any,
            daysAhead,
            tagFilter,
            onlyShowLodgingOnce: false,
        },
    });

    const [numberOfSkeletons, setNumberOfSeletons] = useState(6);

    useEffect(() => {
        if (query.data != null) {
            setNumberOfSeletons(query.data.lastMinute.results.length);
        }
    }, [query.data]);

    const queryString = useMemo(() => {
        return searchContext.toQueryString([
            "context:lodging-location",
            "context:lodging-address1",
        ]);
    }, [searchContext]);

    if (query.error) {
        return <div>{query.error}</div>;
    }

    if (query.loading) {
        return (
            <div className="bwp-lodging-list-grouped-container">
                {weeks && weeks.length && (
                    <div className="bwp-lodging-list-grouped-nav">
                        <h2>{translations.findDiscountForWeek}</h2>
                        <ul>
                            {weeks.map((w) => (
                                <li key={`week${w.weekNumber}`}>
                                    <a
                                        href={`?${searchContext
                                            .changeArrival(parseISO(w.arrivalDate.toString()))
                                            .changeDuration(7)
                                            .changeArrivalDensity(3)
                                            .toQueryString()}`}
                                    >
                                        {formattedValue(w.weekNumber, translations.week)}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                <SearchResultListSkeleton numberOfSkeletons={numberOfSkeletons} height={391} />;
            </div>
        );
    }

    return (
        <>
            <DiscountLodgingList
                translations={translations}
                lodgingCardTranslations={lodgingCardTranslations}
                {...query.data}
                lodgingUrlBuilder={(l) =>
                    `${lodgingUrl(lodgingUrlPattern, l.lodging)}?ari=${format(
                        parse(l.selectedBookingOption.arrival, "yyyy-MM-dd", new Date()),
                        "MM-dd-yyyy"
                    )}&dur=${l.selectedBookingOption.duration}&adu=${
                        l.selectedBookingOption.adults
                    }&chi=${l.selectedBookingOption.children}&pet=${l.selectedBookingOption.pets}`
                }
                extraFacilities={extraFacilities}
                emptyResultHtmlContent={emptyResultHtmlContent}
                weeks={weeks}
                searchContext={searchContext}
            />
        </>
    );
}
