import React, { useEffect, useMemo } from "react";
import { LodgingCardTranslations } from "./translations/LodgingCardTranslations";
import { LodgingListQuery } from "./operations.generated";
import LodgingCard2 from "./LodgingCard2";
import "./LodgingList.scss";
import useFavorites from "./shared/useFavorites";
import ReactDOM from "react-dom";
import SearchResultListHeader from "./SearchResultListHeader";
import { SearchResultListStyle } from "./shared/types";
import { SearchResultListTranslations } from "./translations/SearchResultListTranslations";
import useQueryString from "./shared/useQueryString";
import SearchResultMap from "./SearchResultMap";
import { viewList } from "./shared/trackingUtils";
import useScrollRestorationOnLoad from "./shared/useScrollRestorationOnLoad";

export default function LodgingList({
    lodgingCardTranslations,
    lodgingList: { results },
    lodgingUrlBuilder,
    extraFacilities,
    onlyShowFavorites,
    emptyResultHtmlContent,
    searchResultListTranslations,
    sorting,
    setSorting,
    headerElement,
    googleMapsApiKey,
    mapHeight,
    markerIconUrl,
    maxMapZoomLevel,
}: {
    lodgingCardTranslations: LodgingCardTranslations;
    lodgingUrlBuilder: (l: LodgingListQuery["lodgingList"]["results"][0]) => string;
    extraFacilities: number[];
    onlyShowFavorites?: boolean;
    emptyResultHtmlContent?: string;
    searchResultListTranslations: SearchResultListTranslations;
    sorting: string;
    setSorting: (sorting: string) => void;
    headerElement: HTMLElement;
    googleMapsApiKey: string;
    mapHeight: number;
    markerIconUrl: string;
    maxMapZoomLevel: number;
} & LodgingListQuery) {
    let [favorites, setFavorite] = useFavorites();
    let [listStyle, setListStyle] = useQueryString("listStyle", "Grid");

    let filteredResults = useMemo(() => {
        let r = results.filter((r) => (r.seasonPrices?.length ?? 0) > 0);

        if (onlyShowFavorites) {
            return r.filter((r) => favorites.includes(r.lodging.id));
        } else {
            return r;
        }
    }, [favorites, onlyShowFavorites, results]);

    useEffect(() => {
        window.dispatchEvent(
            new CustomEvent("numberOfSearchResultsUpdated", {
                detail: filteredResults.length,
            })
        );
    }, [filteredResults.length]);

    useEffect(() => {
        if (filteredResults.length > 0) {
            viewList(
                filteredResults.map((l) => ({
                    lodgingId: l.lodging.id,
                    lodgingName: l.lodging.name,
                }))
            );
        }
    }, [filteredResults]);

    useScrollRestorationOnLoad(filteredResults);

    if (filteredResults.length == 0) {
        return <div dangerouslySetInnerHTML={{ __html: emptyResultHtmlContent }} />;
    }

    return (
        <>
            {headerElement &&
                ReactDOM.createPortal(
                    <SearchResultListHeader
                        numberOfResults={filteredResults.length}
                        sorting={sorting}
                        onSortingChange={setSorting}
                        translations={searchResultListTranslations}
                        listStyle={listStyle as SearchResultListStyle}
                        onListStyleChange={(val) => setListStyle(val)}
                    />,
                    headerElement
                )}
            {listStyle == "Grid" && (
                <div className="bwp-lodging-list">
                    {filteredResults.map((d) => (
                        <LodgingCard2
                            key={
                                d.lodging.name +
                                " " +
                                d.selectedBookingOption?.arrivalDisplayValue +
                                " " +
                                d.selectedBookingOption?.departureDisplayValue +
                                " " +
                                d.selectedBookingOption?.priceWithMandatoryItemsDisplayValue
                            }
                            lodging={d.lodging}
                            seasonPrices={d.seasonPrices}
                            translations={lodgingCardTranslations}
                            extraFacilities={extraFacilities}
                            nextUrl={lodgingUrlBuilder(d)}
                            selectedBookingOption={d.selectedBookingOption}
                            isFavorite={favorites.includes(d.lodging.id)}
                            onSetFavorite={setFavorite}
                        />
                    ))}
                </div>
            )}
            {listStyle == "Map" && (
                <SearchResultMap
                    extraFacilities={extraFacilities}
                    lodgingUrlBuilder={(l) =>
                        lodgingUrlBuilder(results.find((r) => r.lodging.id == l.lodging.id))
                    }
                    lodgingCardTranslations={lodgingCardTranslations}
                    googleMapsApiKey={googleMapsApiKey}
                    searchResults={results.map((r) => ({
                        id: r.lodging.id,
                        latitude: r.lodging.latitude,
                        longitude: r.lodging.longitude,
                    }))}
                    mapHeight={mapHeight}
                    markerIconUrl={markerIconUrl}
                    maxMapZoomLevel={maxMapZoomLevel}
                    renderInfoWindow={(l) => {
                        let d = results.find((r) => r.lodging.id == l.id);

                        return (
                            <LodgingCard2
                                key={
                                    d.lodging.name +
                                    " " +
                                    d.selectedBookingOption?.arrivalDisplayValue +
                                    " " +
                                    d.selectedBookingOption?.departureDisplayValue +
                                    " " +
                                    d.selectedBookingOption?.priceWithMandatoryItemsDisplayValue
                                }
                                lodging={d.lodging}
                                seasonPrices={d.seasonPrices}
                                translations={lodgingCardTranslations}
                                extraFacilities={extraFacilities}
                                nextUrl={lodgingUrlBuilder(d)}
                                selectedBookingOption={d.selectedBookingOption}
                                isFavorite={favorites.includes(d.lodging.id)}
                                onSetFavorite={setFavorite}
                                disableSmartImage
                                disableImageSlider
                            />
                        );
                    }}
                />
            )}
        </>
    );
}
