import React, { useEffect, useMemo } from "react";
import { LodgingCardTranslations } from "./translations/LodgingCardTranslations";
import { LastMinuteQuery } from "./operations.generated";
import LodgingCard2 from "./LodgingCard2";
import "./LodgingList.scss";
import useFavorites from "./shared/useFavorites";
import { parseISO } from "date-fns";
import { SearchResultListTranslations } from "./translations/SearchResultListTranslations";
import { processTemplate } from "./shared/templates";
import useScrollRestorationOnLoad from "./shared/useScrollRestorationOnLoad";
import { WeekInfo } from "./shared/types";
import { SearchContext } from "./shared/searchcontext";
import { formattedValue } from "./shared/helpers";

export default function DiscountLodgingList({
    translations,
    lodgingCardTranslations,
    lastMinute: { results },
    lodgingUrlBuilder,
    extraFacilities,
    emptyResultHtmlContent,
    weeks,
    searchContext,
}: {
    translations: SearchResultListTranslations;
    lodgingCardTranslations: LodgingCardTranslations;
    lodgingUrlBuilder: (l: LastMinuteQuery["lastMinute"]["results"][0]) => string;
    extraFacilities: number[];
    onlyShowFavorites?: boolean;
    emptyResultHtmlContent?: string;
    weeks: WeekInfo[];
    searchContext: SearchContext;
} & LastMinuteQuery) {
    let [favorites, setFavorite] = useFavorites();

    let groupedResults = useMemo(() => {
        // First we group all the results into groups of "ticks" to be able to sort the dates and group them
        let byArrivalDate = results
            .filter((r) => r.selectedBookingOption != null)
            .reduce((list, cur) => {
                let ticks = parseISO(cur.selectedBookingOption.arrival).getTime();
                list = { ...list, [ticks]: [...(list[ticks] ?? []), cur] };
                return list;
            }, {} as { [key: string]: typeof results[0][] });

        // We take all the dates (represented by their ticks)
        // 1. We map the keys to integers - as objects' keys is string
        // 2. We sort the keys
        // 3. We map the items in the group out to a group where we give the group a title
        return Object.keys(byArrivalDate)
            .map((k) => parseInt(k))
            .sort((a, b) => (a > b ? 1 : -1))
            .map((key) => {
                return {
                    key,
                    title:
                        byArrivalDate[key.toString()][0].selectedBookingOption.arrivalDisplayValue,
                    items: byArrivalDate[key.toString()],
                };
            });
    }, [results]);

    useEffect(() => {
        window.dispatchEvent(
            new CustomEvent("numberOfSearchResultsUpdated", {
                detail: results.filter((r) => r.selectedBookingOption != null).length,
            })
        );
    }, [results]);

    useScrollRestorationOnLoad(results);

    if (groupedResults.length == 0) {
        return (
            <div className="bwp-lodging-list-grouped-container">
                {(weeks && weeks.length) && (
                    <div className="bwp-lodging-list-grouped-nav">
                        <h2>{translations.findDiscountForWeek}</h2>
                        <ul>
                            {weeks.map(w => (
                                <li key={`week${w.weekNumber}`}>
                                    <a href={`?${searchContext.changeArrival(parseISO(w.arrivalDate.toString())).changeDuration(7).changeArrivalDensity(3).toQueryString()}`}>{formattedValue(w.weekNumber, translations.week)}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                <div dangerouslySetInnerHTML={{ __html: emptyResultHtmlContent }} />
            </div>
        );
    }

    return (
        <div className="bwp-lodging-list-grouped-container">
            {(weeks && weeks.length) && (
                <div className="bwp-lodging-list-grouped-nav">
                    <h2>{translations.findDiscountForWeek}</h2>
                    <ul>
                        {weeks.map(w => (
                            <li key={`week${w.weekNumber}`}>
                                <a href={`?${searchContext.changeArrival(parseISO(w.arrivalDate.toString())).changeDuration(7).changeArrivalDensity(3).toQueryString()}`}>{formattedValue(w.weekNumber, translations.week)}</a>
                            </li>                            
                        ))}
                    </ul>
                </div>
            )}
            {groupedResults.map((g) => (
                <div key={g.key}>
                    <h2>{processTemplate(translations.discountsWithArrival, { date: g.title })}</h2>
                    <div className="bwp-lodging-list">
                        {g.items.map((d) => (
                            <LodgingCard2
                                key={
                                    d.lodging.name +
                                    " " +
                                    d.selectedBookingOption?.arrivalDisplayValue +
                                    " " +
                                    d.selectedBookingOption?.departureDisplayValue +
                                    " " +
                                    d.selectedBookingOption?.priceWithMandatoryItemsDisplayValue
                                }
                                lodging={d.lodging}
                                translations={lodgingCardTranslations}
                                extraFacilities={extraFacilities}
                                nextUrl={lodgingUrlBuilder(d)}
                                selectedBookingOption={d.selectedBookingOption}
                                isFavorite={favorites.includes(d.lodging.id)}
                                onSetFavorite={setFavorite}
                            />
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
}
