import React, { useCallback } from "react";
import { SearchContext } from "@bwp/shared/searchcontext";
import HeroSearch from "@bwp/HeroSearch";

export default function HeroSearchWrapper(props) {
    const urlReader = useCallback((location: Location) => {
        // So when we are server side rendering we don't have a location. So we will look for the special fallback url prop and use that
        if (location == null) {
            if (props.searchBox.fallbackUrl) {
                let tokens = props.searchBox.fallbackUrl.split("?");
                if (tokens.length > 1) {
                    let search = props.searchBox.fallbackUrl.split("?")[1];
                    return SearchContext.createFromQueryString(search);
                } else {
                    return new SearchContext();
                }
            } else {
                return new SearchContext();
            }
        } else {
            return SearchContext.createFromQueryString(location.search);
        }
    }, []);

    props.searchBox.urlReader = urlReader;
    props.searchBox.onSubmit = (value: SearchContext) => {
        window.location.href = props.searchBox.searchUrl + "?" + value.toQueryString();
    };

    return <HeroSearch {...props} />;
}
