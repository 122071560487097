import Button from "./Button";
import React from "react";
import SelectWithLabel, { SelectItem } from "./SelectWithLabel";
import { SearchBoxTranslations } from "./translations/SearchBoxTranslations";
import { SearchContext } from "./shared/searchcontext";
import useLocalSearchContext from "./shared/useLocalSearchContext";
import { UrlReader, UrlWriter } from "./shared/types";
import DatePickerWithLabel from "./DatePickerWithLabel";
import { dayManipulatorHighlightDays, SelectionMode } from "./DatePicker";
import { DatePickerTranslations } from "./translations/DatePickerTranslations";
import { DurationSelectWithLabel } from "./DurationSelectWithLabel";
import { NumberSelectWithLabel } from "./NumberSelectWithLabel";
import { defaultDateValue } from "./shared/helpers";
import PeopleSelectWithLabel from "./PeopleSelectWithLabel";

export type HeroSearchSearchBoxProps = {
    translations: SearchBoxTranslations;
    datePickerTranslations: DatePickerTranslations;
    locations: SelectItem[];
    durations: number[];
    maxPersons: number;
    maxPets: number;
    onSubmit?: (value: SearchContext) => void;
    advancedSearchUrl: string;
    urlWriter?: UrlWriter;
    urlReader?: UrlReader;
    culture: string;
    highlightDays?: number[];
};

export default function HeroSearchSearchBox({
    translations,
    locations,
    durations,
    maxPersons,
    maxPets,
    onSubmit,
    advancedSearchUrl,
    culture,
    datePickerTranslations,
    highlightDays,
}: HeroSearchSearchBoxProps) {
    const [searchContext, setSearchContext] = useLocalSearchContext({
        defaultValue: {
            adults: 2,
            children: 0,
            duration: 7,
            pets: null,
            arrival: defaultDateValue,
        },
    });

    function handleSubmit(e: React.SyntheticEvent) {
        e.preventDefault();

        if (onSubmit) {
            onSubmit(searchContext);
        }
    }

    function handleSearchForLodgingClicked(e: React.SyntheticEvent) {
        e.preventDefault();

        window.dispatchEvent(
            new CustomEvent("bwp:SearchForLodging")
        );
    }

    function linkWithSearchContext(url: string, searchContext: SearchContext) {
        const separator = (url.indexOf('?') > -1) ? "&" : "?";
        return `${url}${separator}${searchContext.toQueryString()}`;
    }

    return (
        <form onSubmit={handleSubmit}>
            <SelectWithLabel
                id="location"
                label={translations.locationLabel}
                value={
                    searchContext.locationIds == null || searchContext.locationIds.length == 0
                        ? null
                        : searchContext.locationIds[0].toString()
                }
                onChange={(v) =>
                    setSearchContext(searchContext.changeLocationId(v == null ? null : parseInt(v)))
                }
                items={locations}
            />
            <DatePickerWithLabel
                label={translations.arrivalLabel}
                value={searchContext.parsedArrival}
                onChange={(v) => setSearchContext(searchContext.changeArrival(v))}
                selectionMode={SelectionMode.Future}
                culture={culture}
                translations={datePickerTranslations}
                dayManipulator={dayManipulatorHighlightDays(highlightDays ?? [])}
            />
            <DurationSelectWithLabel
                id="duration"
                label={translations.durationLabel}
                value={searchContext.duration}
                onChange={(v) => setSearchContext(searchContext.changeDuration(v))}
                translations={translations}
                durations={durations}
            />
            <PeopleSelectWithLabel
                label={translations.personsLabel}
                maxPersons={maxPersons}
                value={{ adults: searchContext.adults, children: searchContext.children }}
                onChange={(v) =>
                    setSearchContext(
                        searchContext.changePersons(v.adults, v.children, 0, searchContext.pets)
                    )
                }
                translations={translations}
                showLabelAsPersons
            />
            <NumberSelectWithLabel
                id="pets"
                label={translations.petsLabel}
                itemTranslation={translations.pet}
                value={searchContext.pets}
                min={1}
                max={maxPets}
                nullValueText={translations.petsNotImportant}
                onChange={(v) => setSearchContext(searchContext.changePets(v))}
            />

            <Button type="primary" submitButton noWrap>
                {translations.searchButtonText}
            </Button>

            <div className="bwp-hero-search__search-box-links">
                {(advancedSearchUrl && translations.advancedSearchText) && (
                    <a href={linkWithSearchContext(advancedSearchUrl, searchContext)}>{translations.advancedSearchText}</a>
                )}                
                <button onClick={handleSearchForLodgingClicked} className="bwp-button-link ignore-react-onclickoutside">{translations.searchForLodging}</button>
            </div>
        </form>
    );
}
