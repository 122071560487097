import React, { useState, useRef, useEffect } from "react";
import { Language } from "./shared/types";
import { useSelect } from "downshift";
import "./LanguageSelect.scss";
import { isBrowser } from "./shared/helpers";

export interface LanguageSelectProps {
    languages: Language[];
    value: string;
    onChange?: (lang: Language) => void;
}

export default function LanguageSelect({ languages, value, onChange }: LanguageSelectProps) {
    const selectedLanguage = languages.find(lan => lan.isoCode == value);

    const {
        isOpen,
        getToggleButtonProps,
        getMenuProps,
        highlightedIndex,
        getItemProps,
    } = useSelect({
        id: "LanguageSelect",
        items: languages,
        onSelectedItemChange: (item) => {
            if (typeof onChange === "function") {
                onChange(languages.find(l => l.isoCode === item.selectedItem.isoCode));
            }
        },
        selectedItem: selectedLanguage
    });

    const defaultText = selectedLanguage?.name;

    const [selectWidth, setSelectWidth] = useState(0);

    const container: React.RefObject<HTMLDivElement> = useRef(null);

    useEffect(() => {
        if (container && container.current) {
            const newSelectWidth = container.current.getBoundingClientRect().width;
            if (!selectWidth) {
                setSelectWidth(newSelectWidth);
            } else if (selectWidth !== newSelectWidth) {
                setSelectWidth(newSelectWidth);
            }
        }
    }, []);

    const getLanguageItem = (lang: Language) => {
        if (typeof onChange === "function") {
            return <React.Fragment>{lang.name}</React.Fragment>;
        }
        return <a href={lang.url}>{lang.name}</a>;
    }

    const optionContainerStyles = {
        display: isOpen ? "block" : "none"
    };

    const flagClassNames = ['bwp-language-select__flag'];
    if ((selectedLanguage && selectedLanguage.isoCode) || defaultText) {
        flagClassNames.push(`bwp-language-select__flag--${(selectedLanguage?.isoCode || defaultText)}`);
    }

    return (
        <div className="bwp-language-select" ref={container}>
            <button type="button" {...getToggleButtonProps()}>
                <span className={flagClassNames.join(' ')}></span>
                <span className="bwp-language-select__arrow"></span>
            </button>
            <div className="bwp-select-items" style={optionContainerStyles}>
                <ul {...getMenuProps()}>
                    {isOpen &&
                        languages.map((lang, index) => (
                            <li
                                className={`bwp-flag--${lang.isoCode}`}
                                key={`${lang.isoCode}${index}`}
                                {...getItemProps({ item: lang, index })}
                            >
                                {getLanguageItem(lang)}
                            </li>
                        ))}
                </ul>
            </div>
        </div>
    );
}