import React from "react";
import FieldWithLabel from "./FieldWithLabel";
import "./GuestsForm.scss";
import "./Form.scss";
import Button from "./Button";
import AlertBox from "./AlertBox";
import { stringFormat } from "./shared/helpers";
import { CheckoutTranslations } from "./translations/CheckoutTranslations";
import { BookingRequestInput } from "./shared/graphql-types.generated";

export interface GuestsFormProps {
    customer: BookingRequestInput["customer"];
    guests: BookingRequestInput["guests"];
    onChange: (guests: BookingRequestInput["guests"]) => void;
    translations: CheckoutTranslations;
    alertMessage: string;
}

export default function GuestsForm({
    customer,
    guests,
    onChange,
    alertMessage,
    translations,
}: GuestsFormProps) {
    const handleClick = (e: React.SyntheticEvent) => {
        e.preventDefault();
        const newGuests = [...guests];
        newGuests[0].firstName = customer.firstName;
        newGuests[0].lastName = customer.lastName;

        onChange(newGuests);
    };

    const handleChange = (
        newValue: any,
        index: number,
        field: keyof BookingRequestInput["guests"][0]
    ) => {
        const newGuests = [...guests];
        newGuests[index][field as any] = newValue;
        onChange(newGuests);
    };

    return (
        <section className="bwp-form-section bwp-guests-form">
            <h2>{translations.guestsFormTitle}</h2>
            <p>{translations.guestsFormDescription}</p>
            {alertMessage && <AlertBox>{alertMessage}</AlertBox>}
            <Button onClick={handleClick}>{translations.guestsFormAutoFill}</Button>
            <div className="bwp-form-section__list">
                {guests.map((guest, index) => (
                    <React.Fragment key={`guest${index}`}>
                        <h3>{stringFormat(translations.guestNumber, (index + 1).toString())}</h3>
                        <div className="bwp-form-section__col3">
                            <div className="bwp-form-section__cell">
                                <FieldWithLabel
                                    value={guest.firstName}
                                    label={translations.firstname}
                                    onChange={(newValue) =>
                                        handleChange(newValue, index, "firstName")
                                    }
                                    required
                                />
                            </div>
                            <div className="bwp-form-section__cell">
                                <FieldWithLabel
                                    value={guest.lastName}
                                    label={translations.lastname}
                                    onChange={(newValue) =>
                                        handleChange(newValue, index, "lastName")
                                    }
                                    required
                                />
                            </div>
                            <div className="bwp-form-section__cell">
                                <FieldWithLabel
                                    value={guest.birthYear?.toString() ?? ""}
                                    label={translations.birthyear}
                                    onChange={(newValue) =>
                                        handleChange(parseInt(newValue), index, "birthYear")
                                    }
                                    placeholder={translations.birthyearPlaceholder}
                                    required
                                    type="number"
                                />
                            </div>
                        </div>
                    </React.Fragment>
                ))}
            </div>
        </section>
    );
}
