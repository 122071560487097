import React, { useState } from "react";
import CardImageSlider from "./CardImageSlider";
import { getImageUrl } from "./shared/images";
import "./GallerySpot.scss";
import useIsomorphicLayoutEffect from "./shared/useIsomorphicLayoutEffect";

export interface GallerySpotTranslations {
    imageMissing: string;
    nextImage: string;
    previousImage: string;
    addToFavourites: string;
}

export interface ImageProps {
    url: string;
    width?: number;
    height?: number;
}

export interface GallerySpotProps {
    headline?: string;
    images: ImageProps[];
    link?: string;
    buttonText?: string;
    disableSmartImage?: boolean;
    disableImageSlider?: boolean;
    translations: GallerySpotTranslations;
}

export default function GallerySpot({ headline, images, link, buttonText, disableSmartImage, disableImageSlider, translations }: GallerySpotProps) {
    const [containerWidth, setContainerWidth] = useState(1140)
    useIsomorphicLayoutEffect(() => {
        const viewportWidth = window.innerWidth;
        const sizes = [320, 375, 414, 600, 768, 992, 1140];
        const firstSizeLargerThanScreen = sizes.findIndex(size => size >= viewportWidth);        
        setContainerWidth(
            firstSizeLargerThanScreen > -1
                ? sizes[firstSizeLargerThanScreen]
                : sizes[sizes.length - 1]
        );
    }, []);

    const showBar = (headline || (buttonText && link));
    const barClassNames = ["bwp-gallery-spot__bar"];
    if (!headline) {
        barClassNames.push("bwp-gallery-spot__bar--no-headline");
    }
    if (!(buttonText && link)) {
        barClassNames.push("bwp-gallery-spot__bar--no-button");
    }

    return <div className="bwp-gallery-spot">
        <CardImageSlider
            images={images}
            favourite={false}
            enableFavorite={false}
            enableImageSlider={!disableImageSlider}
            nextUrl={link}
            title={headline}
            translations={translations}
            disableSmartImage={disableSmartImage}
            imageMaxWidth={containerWidth}
            getImageUrl={(url, width, height) => getImageUrl({ url }, width, height)}
        />
        {showBar && (
            <div className={barClassNames.join(' ')}>
                {headline && (
                    <h2>{headline}</h2>
                )}
                {(buttonText && link) && (
                    <a className="bwp-gallery-spot__button" href={link}>{buttonText}</a>
                )}
            </div>
        )}
    </div>;
}
