import React from "react";
import { LodgingCardTranslations } from "./translations/LodgingCardTranslations";
import { LastMinuteQuery } from "./operations.generated";
import LodgingCard2 from "./LodgingCard2";
import "./CompactDiscountLodgingList.scss";
import "./LodgingList.scss";
import useFavorites from "./shared/useFavorites";


export default function CompactDiscountLodgingList({
    lodgingCardTranslations,
    lastMinute: { results },
    lodgingUrlBuilder,
    extraFacilities,
    emptyResultHtmlContent,
}: {
    lodgingCardTranslations: LodgingCardTranslations;
    lodgingUrlBuilder: (l: LastMinuteQuery["lastMinute"]["results"][0]) => string;
    extraFacilities: number[];
    onlyShowFavorites?: boolean;
    emptyResultHtmlContent?: string;
} & LastMinuteQuery) {
    let [favorites, setFavorite] = useFavorites();

    if (results.length == 0) {
        return <div dangerouslySetInnerHTML={{ __html: emptyResultHtmlContent }} />;
    }

    return (
        <div className="bwp-compact-lodging-list">
            <div className="bwp-lodging-list">
                {results.map((result) => (
                    <LodgingCard2
                        key={
                            result.lodging.name +
                            " " +
                            result.selectedBookingOption?.arrivalDisplayValue +
                            " " +
                            result.selectedBookingOption?.departureDisplayValue +
                            " " +
                            result.selectedBookingOption?.priceWithMandatoryItemsDisplayValue
                        }
                        lodging={result.lodging}
                        translations={lodgingCardTranslations}
                        extraFacilities={extraFacilities}
                        nextUrl={lodgingUrlBuilder(result)}
                        selectedBookingOption={result.selectedBookingOption}
                        isFavorite={favorites.includes(result.lodging.id)}
                        onSetFavorite={setFavorite}
                    />
                ))}
            </div>
        </div>
    );
}
