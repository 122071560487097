import "./LodgingFacilityRow.scss";
import React from "react";
import { pluralize } from "./shared/helpers";
import { LodgingPageTranslations } from "./translations/LodgingPageTranslations";
import { LodgingPresentationQuery } from "./operations.generated";

interface Props {
    translations: LodgingPageTranslations;
}

export default function LodgingFacilityRow({
    maxPeople,
    petsAllowed,
    facilityValues,
    translations,
}: Props & LodgingPresentationQuery["lodgingPresentation"]["lodging"]) {
    const bedFacilityId = 27;
    const sizeFacilityId = 3;
    const smokingNotAllowedFacilityId = 5;
    const bedsFacility = facilityValues.find((f) => f.facilityId == bedFacilityId);
    const sizeFacility = facilityValues.find((f) => f.facilityId == sizeFacilityId);
    const smokingNotAllowedFacility = facilityValues.find(
        (f) => f.facilityId == smokingNotAllowedFacilityId
    );
    const showSmokingNotAllowed =
        smokingNotAllowedFacility && smokingNotAllowedFacility.value === "True";
    return (
        <div className="bwp-lodging-facility-row">
            <div className="bwp-lodging-facility-row__item">
                <PersonIcon />
                <span>{pluralize(maxPeople, translations.person)}</span>
            </div>
            {petsAllowed != null && petsAllowed && (
                <div className="bwp-lodging-facility-row__item">
                    <DogIcon />
                    <span>{translations.petsAllowed}</span>
                </div>
            )}
            {(petsAllowed == null || petsAllowed === false) && (
                <div className="bwp-lodging-facility-row__item">
                    <DogIcon />
                    <span>{translations.petsNotAllowed}</span>
                </div>
            )}
            {bedsFacility != null && (
                <div className="bwp-lodging-facility-row__item">
                    <BedIcon />
                    <span
                        dangerouslySetInnerHTML={{ __html: bedsFacility.combinedHtmlDisplayValue }}
                    />
                </div>
            )}
            {showSmokingNotAllowed && (
                <div className="bwp-lodging-facility-row__item">
                    <SmokingNotAllowedIcon />
                    <span>{smokingNotAllowedFacility.facility.name}</span>
                </div>
            )}
            {!showSmokingNotAllowed && sizeFacility != null && (
                <div className="bwp-lodging-facility-row__item">
                    <SizeIcon />
                    <span
                        dangerouslySetInnerHTML={{ __html: sizeFacility.combinedHtmlDisplayValue }}
                    />
                </div>
            )}
        </div>
    );
}

function PersonIcon() {
    return (
        <svg
            width={22}
            height={24}
            viewBox="0 0 22 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11 12C14.4719 12 17.2857 9.31406 17.2857 6C17.2857 2.68594 14.4719 0 11 0C7.52812 0 4.71429 2.68594 4.71429 6C4.71429 9.31406 7.52812 12 11 12ZM15.4 13.5H14.5799C13.4897 13.9781 12.2768 14.25 11 14.25C9.72321 14.25 8.51518 13.9781 7.42009 13.5H6.6C2.95625 13.5 0 16.3219 0 19.8V21.75C0 22.9922 1.0558 24 2.35714 24H19.6429C20.9442 24 22 22.9922 22 21.75V19.8C22 16.3219 19.0437 13.5 15.4 13.5Z"
                fill="white"
            />
        </svg>
    );
}

function DogIcon() {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M22.7334 3.19994H19.8001L19.4719 2.48444C19.3501 2.21867 19.1629 1.99516 18.9312 1.83893C18.6995 1.68271 18.4325 1.59995 18.1601 1.59993H15.7025L14.4522 0.23593C13.9897 -0.268072 13.2 0.0889292 13.2 0.801431V8.29345L19.0667 10.579V8.79996H20.5334C22.1536 8.79996 23.4667 7.36745 23.4667 5.59995V3.99994C23.4667 3.55794 23.1386 3.19994 22.7334 3.19994ZM17.6001 5.59995C17.1949 5.59995 16.8667 5.24194 16.8667 4.79994C16.8667 4.35794 17.1949 3.99994 17.6001 3.99994C18.0052 3.99994 18.3334 4.35794 18.3334 4.79994C18.3334 5.24194 18.0052 5.59995 17.6001 5.59995ZM4.40001 9.59996C3.59151 9.59996 2.93334 8.88196 2.93334 7.99995C2.93334 7.11645 2.27655 6.39995 1.46667 6.39995C0.656794 6.39995 0 7.11645 0 7.99995C0 10.083 1.22971 11.8425 2.93334 12.505V23.2C2.93334 23.642 3.26151 24 3.66668 24H6.60002C7.00519 24 7.33335 23.642 7.33335 23.2V17.6H14.6667V23.2C14.6667 23.642 14.9949 24 15.4 24H18.3334C18.7386 24 19.0667 23.642 19.0667 23.2V12.2775L12.194 9.59996H4.40001Z"
                fill="white"
            />
        </svg>
    );
}

function BedIcon() {
    return (
        <svg
            width={30}
            height={24}
            viewBox="0 0 30 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.06667 12C10.0884 12 11.7333 9.75688 11.7333 7C11.7333 4.24312 10.0884 2 8.06667 2C6.04496 2 4.4 4.24312 4.4 7C4.4 9.75688 6.04496 12 8.06667 12ZM24.2 4H13.9333C13.5282 4 13.2 4.4475 13.2 5V14H2.93333V1C2.93333 0.4475 2.60517 0 2.2 0H0.733333C0.328167 0 0 0.4475 0 1V23C0 23.5525 0.328167 24 0.733333 24H2.2C2.60517 24 2.93333 23.5525 2.93333 23V20H26.4V23C26.4 23.5525 26.7282 24 27.1333 24H28.6C29.0052 24 29.3333 23.5525 29.3333 23V11C29.3333 7.13375 27.0352 4 24.2 4Z"
                fill="white"
            />
        </svg>
    );
}

function SizeIcon() {
    return (
        <svg
            width="26"
            height="24"
            viewBox="0 0 26 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0 7.92857V1.28571C0 0.573214 0.60051 0 1.34694 0H8.30612C8.67653 0 8.97959 0.289286 8.97959 0.642857V2.78571C8.97959 3.13929 8.67653 3.42857 8.30612 3.42857H3.59184V7.92857C3.59184 8.28214 3.28878 8.57143 2.91837 8.57143H0.673469C0.303061 8.57143 0 8.28214 0 7.92857ZM16.1633 0.642857V2.78571C16.1633 3.13929 16.4663 3.42857 16.8367 3.42857H21.551V7.92857C21.551 8.28214 21.8541 8.57143 22.2245 8.57143H24.4694C24.8398 8.57143 25.1429 8.28214 25.1429 7.92857V1.28571C25.1429 0.573214 24.5423 0 23.7959 0H16.8367C16.4663 0 16.1633 0.289286 16.1633 0.642857ZM24.4694 15.4286H22.2245C21.8541 15.4286 21.551 15.7179 21.551 16.0714V20.5714H16.8367C16.4663 20.5714 16.1633 20.8607 16.1633 21.2143V23.3571C16.1633 23.7107 16.4663 24 16.8367 24H23.7959C24.5423 24 25.1429 23.4268 25.1429 22.7143V16.0714C25.1429 15.7179 24.8398 15.4286 24.4694 15.4286ZM8.97959 23.3571V21.2143C8.97959 20.8607 8.67653 20.5714 8.30612 20.5714H3.59184V16.0714C3.59184 15.7179 3.28878 15.4286 2.91837 15.4286H0.673469C0.303061 15.4286 0 15.7179 0 16.0714V22.7143C0 23.4268 0.60051 24 1.34694 24H8.30612C8.67653 24 8.97959 23.7107 8.97959 23.3571Z"
                fill="white"
            />
        </svg>
    );
}

function SmokingNotAllowedIcon() {
    return (
        <svg
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.1875 15.0938C5.1875 15.5234 5.53906 15.875 5.96875 15.875H11.7061L7.01855 11.1875H5.96875C5.53906 11.1875 5.1875 11.5391 5.1875 11.9688V15.0938ZM13 0.25C6.0957 0.25 0.5 5.8457 0.5 12.75C0.5 19.6543 6.0957 25.25 13 25.25C19.9043 25.25 25.5 19.6543 25.5 12.75C25.5 5.8457 19.9043 0.25 13 0.25ZM13 22.125C7.8291 22.125 3.625 17.9209 3.625 12.75C3.625 10.7285 4.27441 8.8584 5.36816 7.3252L18.4248 20.3818C16.8916 21.4756 15.0215 22.125 13 22.125ZM15.207 12.75H19.25V14.3125H16.7695L15.207 12.75ZM20.6318 18.1748L18.332 15.875H20.0312C20.4609 15.875 20.8125 15.5234 20.8125 15.0938V11.9688C20.8125 11.5391 20.4609 11.1875 20.0312 11.1875H13.6445L7.5752 5.11816C9.1084 4.02441 10.9785 3.375 13 3.375C18.1709 3.375 22.375 7.5791 22.375 12.75C22.375 14.7715 21.7256 16.6416 20.6318 18.1748V18.1748ZM16.1543 6.5C15.3926 6.5 14.7578 5.95312 14.6211 5.23535C14.5869 5.05957 14.4258 4.9375 14.2451 4.9375H13.4541C13.21 4.9375 13.0293 5.15723 13.0635 5.39648C13.2881 6.90527 14.5869 8.0625 16.1543 8.0625C16.916 8.0625 17.5508 8.60937 17.6875 9.32715C17.7217 9.50293 17.8828 9.625 18.0635 9.625H18.8545C19.0986 9.625 19.2793 9.40527 19.2451 9.16602C19.0205 7.65723 17.7217 6.5 16.1543 6.5Z"
                fill="white"
            />
        </svg>
    );
}
