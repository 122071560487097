import React from "react";
import { ButtonType } from "./shared/types";

import "./Button.scss";

export interface ButtonProps {
    type?: ButtonType;
    onClick?: (e?: React.MouseEvent | React.TouchEvent) => void;
    children: React.ReactNode;
    submitButton?: boolean;
    icon?: () => JSX.Element;
    noWrap?: boolean;
    className?: string;
}

export default function Button({
    type,
    onClick,
    submitButton,
    children,
    icon,
    noWrap,
    className,
}: ButtonProps) {
    const classNames = ["bwp-button"];
    if (type && type !== "default") {
        classNames.push(`bwp-button--${type}`);
    }
    if (noWrap) {
        classNames.push(`bwp-button--nowrap`);
    }

    let Icon = icon;

    let body = children;

    if (Icon != null) {
        classNames.push("bwp-button--with-icon");

        body = (
            <>
                <div>{children}</div>
                <Icon />
            </>
        );
    }

    if (className != null) {
        classNames.push(className);
    }

    return (
        <button
            type={submitButton === true ? "submit" : "button"}
            className={classNames.join(" ")}
            onClick={onClick}
        >
            {body}
        </button>
    );
}
