import React, { useMemo } from "react";

import "./StepVisualizer.scss";

interface Props {
    steps: Step[];
    activeStep: string;
    maxStep: string;
    onStepClicked: (step: string) => void;
}

export type StepName = "extras" | "yourDetails" | "payment" | "done";

export interface Step {
    id: StepName;
    name: React.ReactNode;
}

export default function StepVisualizer({ steps, activeStep, maxStep, onStepClicked }: Props) {
    function getStepCssClass(step: Step) {
        const classNames = ["bwp-step-visualizer__step"];

        if (step.id == activeStep) {
            classNames.push("bwp-step-visualizer__step__active");
        } else {
            const myIndex = steps.indexOf(step);
            const indexOfActive = steps.indexOf(steps.find((s) => s.id == activeStep));
            if (myIndex < indexOfActive) {
                classNames.push("bwp-step-visualizer__step__completed");
            }
        }

        return classNames.join(" ");
    }

    const maxStepIndex = useMemo(() => {
        return steps.findIndex((s) => s.id == maxStep);
    }, [maxStep, steps]);

    return (
        <div className="bwp-step-visualizer">
            {steps.map((s, index) => (
                <div
                    key={s.id}
                    className={getStepCssClass(s)}
                    onClick={() => onStepClicked(s.id)}
                    style={{ cursor: maxStepIndex >= index ? "pointer" : "unset" }}
                >
                    <svg
                        className="bwp-step-visualizer__step__line-before"
                        width="100%"
                        height="3"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <line x1="0" y1="0" x2="100%" y2="0" strokeWidth="3" />
                    </svg>
                    <svg
                        className="bwp-step-visualizer__step__line-after"
                        width="100%"
                        height="3"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <line x1="0" y1="0" x2="100%" y2="0" strokeWidth="3" />
                    </svg>

                    <svg
                        className="bwp-step-visualizer__step__circle"
                        width="33"
                        height="33"
                        viewBox="0 0 33 33"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle cx="16.5" cy="16.5" r="16.5" />
                        <circle cx="16.5" cy="16.5" r="7.5" />
                    </svg>

                    <div className="bwp-step-visualizer__step__name">{s.name}</div>
                </div>
            ))}
        </div>
    );
}
