import {
    CheckoutQuery,
    CheckoutTrackingPresentationFragment,
    LodgingPresentationQuery,
} from "../operations.generated";

/***
 * Imports the required tracking utility from window.
 * If it is available it is returned right away.
 * If not and CookieBot is available it will listen to the CookieBot "CookiebotOnTagsExecuted" event and look again
 * The promise will only resolve if the service is unavailable if not it will just never return.
 * @param name
 */
export function trackingRequire<T = any>(name: "ga" | "raptor" | "gtag"): Promise<T> {
    return new Promise<T>((resolve, reject) => {
        try {
            let service = window[name];

            console.debug("trackingRequire", name, service != null);

            if (service) {
                resolve(service);
                return;
            }

            let CookieBot = window["Cookiebot"];

            if (CookieBot) {
                let handler = () => {
                    window.removeEventListener("CookiebotOnTagsExecuted", handler);
                    setTimeout(() => {
                        service = window[name];
                        console.debug(
                            "trackingRequire",
                            "CookiebotOnTagsExecuted",
                            name,
                            service != null
                        );
                        if (service) {
                            resolve(service);
                        }
                    }, 1000);
                };
                console.debug("trackingRequire", "Listen CookiebotOnTagsExecuted", name);
                window.addEventListener("CookiebotOnTagsExecuted", handler);
            }
        } catch (err) {
            reject(err);
        }
    });
}

export function trackDynamicUrlChange() {
    let url = window.location.pathname + window.location.search + window.location.hash;
    console.debug("setPage", url);
    console.debug("pageview");

    trackingRequire("ga").then((ga) => {
        try {
            if (ga) {
                ga(
                    "set",
                    "page",
                    window.location.pathname + window.location.search + window.location.hash
                );
                ga("send", "pageview");
            }
        } catch (error) {
            console.error("ga threw error: ", error);
        }
    });
}

export function viewLodging(
    lodgingId: number,
    lodgingName: string,
    lodging: LodgingPresentationQuery["lodgingPresentation"]["lodging"]
) {
    trackingRequire<Gtag.Gtag>("ga").then((gtag) => {
        try {
            let args = {
                items: [createLodgingItem(lodgingId, lodgingName)],
            };
            gtag("event", "view_item", args);
            console.debug("Sent Lodging View Using gtag", args);
        } catch (error) {
            console.error("Unable to track view: ", error);
        }
    });
    trackingRequire("raptor").then((raptor) => {
        try {
            let args = {
                p1: "visit",
                p2: lodgingId,
                p3: lodgingName,
                p4: RaptorCategoryPath(lodging),
                p8: lodging.location.name,
            };
            raptor.push("trackEvent", args);
            console.debug("Sent Lodging View Using raptor", args);
        } catch (error) {
            console.error("Unable to raptor tracking (ProductView): ", error);
        }
    });
}

export function viewBasket(lodgingPresentation: CheckoutQuery["lodgingPresentation"]) {
    trackingRequire("raptor").then((raptor) => {
        try {
            let args = {
                p1: "basket",
                p2: lodgingPresentation.lodging.id.toString(),
                p3: lodgingPresentation.lodging.name,
                p4: RaptorCategoryPath(lodgingPresentation.lodging),
                p8: lodgingPresentation.lodging.location.name,
                p10: lodgingPresentation.lodging.id.toString(),
            };
            raptor.push("trackEvent", args);

            console.debug("Send Raptor Basket Tracking", args);
        } catch (error) {
            console.error("Unable to raptor tracking (Basket): ", error);
        }
    });
}

export function viewList(items: { lodgingId: number; lodgingName: string }[]) {
    trackingRequire<Gtag.Gtag>("gtag").then((gtag) => {
        try {
            let args = {
                items: items.map((l) => createLodgingItem(l.lodgingId, l.lodgingName)),
            };
            gtag("event", "view_item_list", args);
            console.debug("Sent Lodging View List Using gtag", args);
        } catch (error) {
            console.error("Unable to track view list: ", error);
        }
    });
}

export function trackBooking(
    orderItemId: number,
    lodgingPresentation: CheckoutQuery["lodgingPresentation"],
    checkout: CheckoutTrackingPresentationFragment
) {
    trackingRequire<Gtag.Gtag>("gtag").then((gtag) => {
        try {
            // GTAG (v3) Purchase event: https://developers.google.com/gtagjs/reference/event#purchase
            let lines = [];

            checkout.itemLines
                ?.filter((il) => il.quantity > 0)
                .forEach((itemLine) => {
                    // We need to add an item for each of the quantities as they don't support quantities
                    lines.push({
                        id: "item_" + itemLine.item.id.toString(),
                        name: itemLine.item.name.toString(),
                        price: itemLine.itemPrice.unitPrice,
                        currency: checkout.currency.toUpperCase(),
                        item_category: "item",
                        discount: 0,
                        quantity: itemLine.quantity,
                    } as Gtag.Item);
                });

            // gtag is a newer API than "ga"
            const args = {
                transaction_id: orderItemId.toString(),
                value: checkout.totalPrice,
                currency: checkout.currency.toUpperCase(),
                items: [
                    createLodgingLine(
                        lodgingPresentation.lodging.id,
                        lodgingPresentation.lodging.name,
                        checkout.bookingPrice,
                        -1 * checkout.discountReduction,
                        checkout.currency
                    ),
                    ...lines,
                ],
            };
            gtag("event", "purchase", args);
            console.debug("Sent Google Analytics Using gtag", args);
        } catch (error) {
            console.error("Unable to track booking: ", error);
            try {
                gtag("event", "exception", {
                    description: "Exception while tracking purchase: " + error.toString(),
                    fatal: false,
                });
            } catch (error2) {
                console.error("Unable to send error to GA: ", error2);
            }
        }
    });

    trackingRequire("raptor").then((raptor) => {
        try {
            let args = {
                p1: "buy",
                p2: lodgingPresentation.lodging.id.toString(),
                p3: lodgingPresentation.lodging.name,
                p4: RaptorCategoryPath(lodgingPresentation.lodging),
                p5: checkout.bookingPrice.toString(),
                p6: checkout.currency,
                p8: lodgingPresentation.lodging.location.name,
                p13: 1,
            };
            raptor.push("trackEvent", args);
            console.debug("Send Raptor Buy Tracking");
        } catch (error) {
            console.error("Unable to raptor tracking (Basket): ", error);
        }
    });
}

interface RaptorCategoryPathInput {
    location: {
        id: any;
        name: any;
    };
    name: any;
    address1: any;
}

function RaptorCategoryPath(input: RaptorCategoryPathInput) {
    let locationId = input.location.id;
    let locationName = input.location.name;
    let lodgingName = input.name;
    let lodgingAddress1 = input.address1;

    return `${locationId}#${locationName};${lodgingName}#${RaptorStreet(lodgingAddress1)}`;
}

function RaptorStreet(address1: string) {
    if (address1 == "" || address1 == null) {
        return "";
    }

    let parts = address1.split(" ");

    let nameParts = [];
    for (let part of parts) {
        if (/^[^0-9]/.test(part)) {
            nameParts.push(part);
        } else {
            break;
        }
    }

    return nameParts.join(" ");
}

function createLodgingItem(lodgingId: number, lodgingName: string) {
    return {
        id: "property_" + lodgingId.toString(),
        name: lodgingName,
        category: "property",
        quantity: 1,
    } as Gtag.Item;
}

function createLodgingLine(
    lodgingId: number,
    lodgingName: string,
    price: number,
    discount: number,
    currency: string
) {
    return {
        id: "property_" + lodgingId.toString(),
        name: lodgingName,
        category: "property",
        price: price,
        discount: discount,
        currency: currency?.toUpperCase(),
        quantity: 1,
    } as Gtag.Item;
}
