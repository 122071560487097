export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    Date: any;
    Decimal: any;
    Guid: any;
};

export type Booking = {
    __typename?: "Booking";
    balance?: Maybe<Scalars["Decimal"]>;
    balanceDisplayValue?: Maybe<Scalars["String"]>;
    bookingLines: Array<BookingLine>;
    bookingsTotalDiscount?: Maybe<Scalars["Decimal"]>;
    bookingsTotalDiscountDisplayValue?: Maybe<Scalars["String"]>;
    bookingsTotalPrice?: Maybe<Scalars["Decimal"]>;
    bookingsTotalPriceDisplayValue?: Maybe<Scalars["String"]>;
    currency: Scalars["String"];
    customer?: Maybe<Customer>;
    externalNote?: Maybe<Scalars["String"]>;
    itemLines?: Maybe<Array<Maybe<ItemLine>>>;
    payRates: PayRates;
    payment?: Maybe<Payment>;
    reservationId?: Maybe<Scalars["Int"]>;
    totalPrice?: Maybe<Scalars["Decimal"]>;
    totalPriceDisplayValue?: Maybe<Scalars["String"]>;
};

export type BookingLine = {
    __typename?: "BookingLine";
    adults?: Maybe<Scalars["Int"]>;
    arrivalDate?: Maybe<Scalars["Date"]>;
    calculatedLineTotal?: Maybe<Scalars["Decimal"]>;
    children?: Maybe<Scalars["Int"]>;
    description: Scalars["String"];
    duration?: Maybe<Scalars["Int"]>;
    infants?: Maybe<Scalars["Int"]>;
    lodging?: Maybe<Lodging>;
    lodgingId?: Maybe<Scalars["Int"]>;
    lodgingUnitTypeId?: Maybe<Scalars["Int"]>;
    pets: Scalars["Int"];
};

export type BookingOption = {
    __typename?: "BookingOption";
    adults: Scalars["Int"];
    arrival?: Maybe<Scalars["Date"]>;
    arrivalDisplayValue: Scalars["String"];
    children: Scalars["Int"];
    cleaningIncludedInPriceWithMandatoryItems: Scalars["Boolean"];
    currency: Scalars["String"];
    daysWithDiscount: Scalars["Int"];
    departure?: Maybe<Scalars["Date"]>;
    departureDisplayValue: Scalars["String"];
    discountName?: Maybe<Scalars["String"]>;
    discountReduction: Scalars["Decimal"];
    discountReductionDisplayValue: Scalars["String"];
    discountTags?: Maybe<Array<Maybe<Scalars["String"]>>>;
    duration: Scalars["Int"];
    hasDiscount: Scalars["Boolean"];
    infants: Scalars["Int"];
    isAvailable: Scalars["Boolean"];
    isRegularWeek: Scalars["Boolean"];
    itemPrices?: Maybe<Array<Maybe<ItemPrice>>>;
    languageId: Scalars["Int"];
    lodgingId: Scalars["Int"];
    normalPrice: Scalars["Decimal"];
    normalPriceDisplayValue: Scalars["String"];
    normalPriceWithMandatoryItems: Scalars["Decimal"];
    normalPriceWithMandatoryItemsDisplayValue: Scalars["Decimal"];
    pets: Scalars["Int"];
    price: Scalars["Decimal"];
    priceDisplayValue: Scalars["String"];
    priceWithMandatoryItems: Scalars["Decimal"];
    priceWithMandatoryItemsDisplayValue: Scalars["String"];
    priority: Scalars["String"];
    status: Scalars["String"];
    winterRuleWarning: Scalars["Boolean"];
};

export type BookingOptionArrivalDisplayValueArgs = {
    format: Scalars["String"];
};

export type BookingOptionDepartureDisplayValueArgs = {
    format: Scalars["String"];
};

export type BookingOptionInput = {
    adults: Scalars["Int"];
    arrival?: InputMaybe<Scalars["Date"]>;
    children: Scalars["Int"];
    duration: Scalars["Int"];
    infants: Scalars["Int"];
    languageId: Scalars["Int"];
    lodgingId: Scalars["Int"];
    pets: Scalars["Int"];
};

export type BookingRequestInput = {
    affiliateId?: InputMaybe<Scalars["Int"]>;
    bookingOption: BookingOptionInput;
    customer?: InputMaybe<CustomerInput>;
    customerAdvisorId?: InputMaybe<Scalars["Int"]>;
    customerId?: InputMaybe<Scalars["Int"]>;
    guests?: InputMaybe<Array<InputMaybe<GuestInput>>>;
    internalNote?: InputMaybe<Scalars["String"]>;
    items: Array<InputMaybe<BookingRequestItemInput>>;
    note?: InputMaybe<Scalars["String"]>;
    payment?: InputMaybe<PaymentInput>;
};

export type BookingRequestItemInput = {
    itemId: Scalars["Int"];
    quantity: Scalars["Int"];
};

export type Calendar = {
    __typename?: "Calendar";
    days: Array<CalendarDay>;
    lodgingId: Scalars["Int"];
};

export type CalendarDay = {
    __typename?: "CalendarDay";
    bookingOptions: Array<BookingOption>;
    date?: Maybe<Scalars["Date"]>;
    isAvailable: Scalars["Boolean"];
    isChangeDay: Scalars["Boolean"];
    seasonCode: Scalars["String"];
    selectedBookingOption?: Maybe<BookingOption>;
};

export type CheckoutPresentationView = {
    __typename?: "CheckoutPresentationView";
    available: Scalars["Boolean"];
    bookingPrice: Scalars["Decimal"];
    bookingPriceDisplayValue?: Maybe<Scalars["String"]>;
    currency: Scalars["String"];
    discountName?: Maybe<Scalars["String"]>;
    discountReduction?: Maybe<Scalars["Decimal"]>;
    discountReductionDisplayValue?: Maybe<Scalars["String"]>;
    hasDiscount: Scalars["Boolean"];
    itemLines?: Maybe<Array<CheckoutPresentationViewItemLine>>;
    itemLinesTotal?: Maybe<Scalars["Decimal"]>;
    itemLinesTotalDisplayValue?: Maybe<Scalars["String"]>;
    normalBookingPrice: Scalars["Decimal"];
    normalBookingPriceDisplayValue?: Maybe<Scalars["String"]>;
    totalPrice?: Maybe<Scalars["Decimal"]>;
    totalPriceDisplayValue?: Maybe<Scalars["String"]>;
};

export type CheckoutPresentationViewItemLinesTotalArgs = {
    includeMandatory?: InputMaybe<Scalars["Boolean"]>;
};

export type CheckoutPresentationViewItemLinesTotalDisplayValueArgs = {
    includeMandatory?: InputMaybe<Scalars["Boolean"]>;
};

export type CheckoutPresentationViewItemLine = {
    __typename?: "CheckoutPresentationViewItemLine";
    canDecrease?: Maybe<Scalars["Boolean"]>;
    canIncrease?: Maybe<Scalars["Boolean"]>;
    item: Item;
    itemPrice: ItemPrice;
    quantity: Scalars["Int"];
    showInSummary?: Maybe<Scalars["Boolean"]>;
    totalPrice?: Maybe<Scalars["Decimal"]>;
    totalPriceDisplayValue: Scalars["String"];
};

export type Country = {
    __typename?: "Country";
    id: Scalars["String"];
    name: Scalars["String"];
};

export type CreateBookingResult = {
    __typename?: "CreateBookingResult";
    basketId?: Maybe<Scalars["Guid"]>;
    bookingOptionNoLongerAvailable: Scalars["Boolean"];
    formForGateway?: Maybe<Scalars["String"]>;
    orderItem?: Maybe<OrderItem>;
    resultType?: Maybe<CreateBookingResultType>;
};

export enum CreateBookingResultType {
    FormForGateway = "FORM_FOR_GATEWAY",
    OrderItem = "ORDER_ITEM",
}

export type CustomFieldValueInput = {
    name: Scalars["String"];
    value?: InputMaybe<Scalars["String"]>;
};

export type Customer = {
    __typename?: "Customer";
    address?: Maybe<Scalars["String"]>;
    address2?: Maybe<Scalars["String"]>;
    city?: Maybe<Scalars["String"]>;
    country?: Maybe<Scalars["String"]>;
    customerId?: Maybe<Scalars["Int"]>;
    email?: Maybe<Scalars["String"]>;
    firstName?: Maybe<Scalars["String"]>;
    languageId: Scalars["Int"];
    lastName?: Maybe<Scalars["String"]>;
    postalCode?: Maybe<Scalars["String"]>;
    privateMobilephone?: Maybe<Scalars["String"]>;
    privateTelephone?: Maybe<Scalars["String"]>;
    subscribeToCatalogue: Scalars["Boolean"];
    subscribeToNewsletters?: Maybe<Scalars["String"]>;
    workTelephone?: Maybe<Scalars["String"]>;
};

export type CustomerInput = {
    address?: InputMaybe<Scalars["String"]>;
    city?: InputMaybe<Scalars["String"]>;
    country?: InputMaybe<Scalars["String"]>;
    customFieldValues: Array<CustomFieldValueInput>;
    customerId?: InputMaybe<Scalars["Int"]>;
    email?: InputMaybe<Scalars["String"]>;
    firstName?: InputMaybe<Scalars["String"]>;
    languageId: Scalars["Int"];
    lastName?: InputMaybe<Scalars["String"]>;
    postalCode?: InputMaybe<Scalars["String"]>;
    privateMobilephone?: InputMaybe<Scalars["String"]>;
    privateTelephone?: InputMaybe<Scalars["String"]>;
    subscribeToCatalogue: Scalars["Boolean"];
    subscribeToNewsletters?: InputMaybe<Scalars["String"]>;
    workTelephone?: InputMaybe<Scalars["String"]>;
};

export type CustomerReview = {
    __typename?: "CustomerReview";
    areaId: Scalars["Int"];
    from?: Maybe<Scalars["Date"]>;
    fromDisplayValue: Scalars["String"];
    lodgingId: Scalars["Int"];
    reviewOfAreaText: Scalars["String"];
    reviewOfBureauText: Scalars["String"];
    reviewOfLodgingText: Scalars["String"];
    reviewerName: Scalars["String"];
    to?: Maybe<Scalars["Date"]>;
    toDisplayValue: Scalars["String"];
};

export type CustomerReviewFromDisplayValueArgs = {
    format: Scalars["String"];
};

export type CustomerReviewToDisplayValueArgs = {
    format: Scalars["String"];
};

export type ExtendedLodging = {
    __typename?: "ExtendedLodging";
    bookingOptions: Array<BookingOption>;
    group?: Maybe<Array<ExtendedLodging>>;
    groupInfo?: Maybe<GroupInfo>;
    lodging: Lodging;
    nameInGroup: Scalars["String"];
    positionInGroup: Scalars["Int"];
    seasonCalendar: SeasonCalendar;
    seasonPrices: Array<SeasonPrice>;
    selectedBookingOption?: Maybe<BookingOption>;
};

export type Facility = {
    __typename?: "Facility";
    description?: Maybe<Scalars["String"]>;
    facilityGroupIds?: Maybe<Array<Maybe<Scalars["Int"]>>>;
    id: Scalars["Int"];
    image?: Maybe<Image>;
    masterFacilityId?: Maybe<Scalars["Int"]>;
    name: Scalars["String"];
    typeId: Scalars["Int"];
};

export type FacilityValue = {
    __typename?: "FacilityValue";
    combinedHtmlDisplayValue: Scalars["String"];
    facility: Facility;
    facilityId: Scalars["Int"];
    fromDate?: Maybe<Scalars["Date"]>;
    htmlDisplayValue: Scalars["String"];
    masterFacilityId?: Maybe<Scalars["Int"]>;
    toDate?: Maybe<Scalars["Date"]>;
    value: Scalars["String"];
};

export type GroupInfo = {
    __typename?: "GroupInfo";
    currency: Scalars["String"];
    highestMaxPeople: Scalars["Int"];
    lowestMaxPeople: Scalars["Int"];
    lowestPrice: Scalars["Decimal"];
};

export type GuestInput = {
    birthYear?: InputMaybe<Scalars["Int"]>;
    firstName?: InputMaybe<Scalars["String"]>;
    lastName?: InputMaybe<Scalars["String"]>;
};

export type Image = {
    __typename?: "Image";
    originalHeight: Scalars["Int"];
    originalWidth: Scalars["Int"];
    tagIds: Array<Scalars["Int"]>;
    url: Scalars["String"];
};

export type Item = {
    __typename?: "Item";
    description: Scalars["String"];
    fromDate?: Maybe<Scalars["Date"]>;
    id: Scalars["Int"];
    itemType?: Maybe<ItemTypes>;
    itemTypeId: Scalars["Int"];
    name: Scalars["String"];
    shortDescription: Scalars["String"];
    sortIndex: Scalars["Int"];
    toDate?: Maybe<Scalars["Date"]>;
};

export type ItemLine = {
    __typename?: "ItemLine";
    calculatedLineTotal?: Maybe<Scalars["Decimal"]>;
    calculatedUnitPrice?: Maybe<Scalars["Decimal"]>;
    description: Scalars["String"];
    itemId?: Maybe<Scalars["Int"]>;
    quantity?: Maybe<Scalars["Decimal"]>;
};

export type ItemPrice = {
    __typename?: "ItemPrice";
    currency: Scalars["String"];
    defaultQuantity: Scalars["Int"];
    isMandatory: Scalars["Boolean"];
    item: Item;
    itemId: Scalars["Int"];
    maxQuantity: Scalars["Int"];
    minQuantity: Scalars["Int"];
    priceWithQuantityDisplayValue: Scalars["String"];
    unitPrice: Scalars["Decimal"];
    unitPriceDisplayValue: Scalars["String"];
};

export type ItemPricePriceWithQuantityDisplayValueArgs = {
    quantity?: InputMaybe<Scalars["Int"]>;
};

export enum ItemTypes {
    Cleaning = "CLEANING",
    Consumables = "CONSUMABLES",
    Deposit = "DEPOSIT",
    Insurance = "INSURANCE",
    Standard = "STANDARD",
    Transportation = "TRANSPORTATION",
}

export type Location = {
    __typename?: "Location";
    id: Scalars["Int"];
    level: Scalars["Int"];
    locations?: Maybe<Array<Maybe<Location>>>;
    name: Scalars["String"];
    reviews: Array<CustomerReview>;
    shortDescription: Scalars["String"];
    slug: Scalars["String"];
};

export type LocationReviewsArgs = {
    limit: Scalars["Int"];
};

export type Lodging = {
    __typename?: "Lodging";
    activeDescription?: Maybe<LodgingDescription>;
    address1: Scalars["String"];
    address1Slug: Scalars["String"];
    address2: Scalars["String"];
    city: Scalars["String"];
    country: Scalars["String"];
    departmentId?: Maybe<Scalars["Int"]>;
    descriptions: Array<LodgingDescription>;
    facilityValue?: Maybe<FacilityValue>;
    facilityValues: Array<FacilityValue>;
    id: Scalars["Int"];
    images: Array<Image>;
    internalName: Scalars["String"];
    latitude: Scalars["String"];
    location: Location;
    locationId: Scalars["Int"];
    longitude: Scalars["String"];
    maxPeople?: Maybe<Scalars["Int"]>;
    maxPets?: Maybe<Scalars["Int"]>;
    name: Scalars["String"];
    nameSlug: Scalars["String"];
    petsAllowed?: Maybe<Scalars["Boolean"]>;
    postalCode: Scalars["String"];
    primaryImage?: Maybe<Image>;
    rating: Scalars["String"];
    reviews: Array<CustomerReview>;
    stars?: Maybe<Scalars["Int"]>;
    starsPostText: Scalars["String"];
    terms?: Maybe<TermsAndConditions>;
    typeId: Scalars["Int"];
    websiteUrl: Scalars["String"];
};

export type LodgingActiveDescriptionArgs = {
    displayDate?: InputMaybe<Scalars["Date"]>;
};

export type LodgingFacilityValueArgs = {
    displayDate: Scalars["Date"];
    facilityId: Scalars["Int"];
};

export type LodgingFacilityValuesArgs = {
    displayDate: Scalars["Date"];
    onlyFacilities?: InputMaybe<Array<Scalars["Int"]>>;
    onlyMasterFacilities?: InputMaybe<Array<Scalars["Int"]>>;
};

export type LodgingMaxPeopleArgs = {
    displayDate: Scalars["Date"];
};

export type LodgingMaxPetsArgs = {
    displayDate: Scalars["Date"];
};

export type LodgingPetsAllowedArgs = {
    displayDate: Scalars["Date"];
};

export type LodgingReviewsArgs = {
    take: Scalars["Int"];
};

export type LodgingTermsArgs = {
    displayDate: Scalars["Date"];
};

export type LodgingDescription = {
    __typename?: "LodgingDescription";
    body?: Maybe<Scalars["String"]>;
    embeddedVideoHtml?: Maybe<Scalars["String"]>;
    fromDate?: Maybe<Scalars["Date"]>;
    longHtmlDescription?: Maybe<Scalars["String"]>;
    summary?: Maybe<Scalars["String"]>;
    title?: Maybe<Scalars["String"]>;
    toDate?: Maybe<Scalars["Date"]>;
};

export type LodgingListView = {
    __typename?: "LodgingListView";
    results?: Maybe<Array<Maybe<ExtendedLodging>>>;
};

export type LodgingPresentationView = {
    __typename?: "LodgingPresentationView";
    lodging?: Maybe<Lodging>;
    selectedBookingOption?: Maybe<BookingOption>;
};

export type MutationResult_Boolean = {
    __typename?: "MutationResult_Boolean";
    errorMessage?: Maybe<Scalars["String"]>;
    result?: Maybe<Scalars["Boolean"]>;
    success: Scalars["Boolean"];
};

export type MutationResult_CreateBookingResult = {
    __typename?: "MutationResult_CreateBookingResult";
    errorMessage?: Maybe<Scalars["String"]>;
    result?: Maybe<CreateBookingResult>;
    success: Scalars["Boolean"];
};

export type Newsletter = {
    __typename?: "Newsletter";
    description?: Maybe<Scalars["String"]>;
    id: Scalars["Int"];
    name: Scalars["String"];
};

export type OrderItem = {
    __typename?: "OrderItem";
    id: Scalars["Int"];
    reservationId: Scalars["Int"];
    type: Scalars["String"];
};

export type PayRate = {
    __typename?: "PayRate";
    amount: Scalars["Decimal"];
    amountDisplayValue: Scalars["String"];
    currency: Scalars["String"];
    payDate?: Maybe<Scalars["Date"]>;
    payDateDisplayValue: Scalars["String"];
};

export type PayRatePayDateDisplayValueArgs = {
    format: Scalars["String"];
};

export type PayRates = {
    __typename?: "PayRates";
    rate1?: Maybe<PayRate>;
    rate2?: Maybe<PayRate>;
};

export type Payment = {
    __typename?: "Payment";
    amount: Scalars["Decimal"];
    currency: Scalars["String"];
    fee: Scalars["Decimal"];
    id: Scalars["Int"];
    status?: Maybe<PaymentStatus>;
    transact: Scalars["String"];
};

export type PaymentInput = {
    amount: Scalars["Decimal"];
    basketId?: InputMaybe<Scalars["String"]>;
    cardId: Scalars["String"];
    createOrderOnAccept: Scalars["Boolean"];
    currency: Scalars["String"];
    fee: Scalars["Decimal"];
    gatewayId: Scalars["Int"];
    id: Scalars["Int"];
    status?: InputMaybe<PaymentStatus>;
    transactionId: Scalars["String"];
};

export enum PaymentOption {
    BankTransfer = "BANK_TRANSFER",
    CreditCardWithOneRate = "CREDIT_CARD_WITH_ONE_RATE",
    CreditCardWithTwoRates = "CREDIT_CARD_WITH_TWO_RATES",
}

export enum PaymentStatus {
    Authorized = "AUTHORIZED",
    Cancelled = "CANCELLED",
    Captured = "CAPTURED",
    Completed = "COMPLETED",
    Expired = "EXPIRED",
    Failed = "FAILED",
    New = "NEW",
}

export type Query = {
    __typename?: "Query";
    booking?: Maybe<Booking>;
    bureauReviews?: Maybe<Array<Maybe<CustomerReview>>>;
    calendar?: Maybe<Calendar>;
    checkoutPresentation?: Maybe<CheckoutPresentationView>;
    countries: Array<Country>;
    facilities?: Maybe<Array<Maybe<Facility>>>;
    items?: Maybe<Array<Maybe<Item>>>;
    lastMinute?: Maybe<SearchResult>;
    locations?: Maybe<Array<Maybe<Location>>>;
    lodgingList?: Maybe<LodgingListView>;
    lodgingPresentation?: Maybe<LodgingPresentationView>;
    lodgings?: Maybe<Array<Maybe<Lodging>>>;
    newsletters: Array<Newsletter>;
    payRates?: Maybe<PayRates>;
    search?: Maybe<SearchResult>;
    terms?: Maybe<TermsAndConditions>;
};

export type QueryBookingArgs = {
    basketId: Scalars["String"];
};

export type QueryBureauReviewsArgs = {
    limit: Scalars["Int"];
};

export type QueryCalendarArgs = {
    month?: InputMaybe<Scalars["Int"]>;
    numberOfMonths?: InputMaybe<Scalars["Int"]>;
    query?: InputMaybe<Scalars["String"]>;
    year?: InputMaybe<Scalars["Int"]>;
};

export type QueryCheckoutPresentationArgs = {
    request?: InputMaybe<BookingRequestInput>;
};

export type QueryLastMinuteArgs = {
    daysAhead?: InputMaybe<Scalars["Int"]>;
    onlyShowLodgingOnce?: InputMaybe<Scalars["Boolean"]>;
    query?: InputMaybe<Scalars["String"]>;
    sorting?: InputMaybe<SearchSorting>;
    tagFilter?: InputMaybe<Scalars["String"]>;
    take?: InputMaybe<Scalars["Int"]>;
};

export type QueryLodgingListArgs = {
    query?: InputMaybe<Scalars["String"]>;
    sorting?: InputMaybe<SearchSorting>;
    take?: InputMaybe<Scalars["Int"]>;
    year?: InputMaybe<Scalars["Int"]>;
};

export type QueryLodgingPresentationArgs = {
    query?: InputMaybe<Scalars["String"]>;
    request?: InputMaybe<BookingRequestInput>;
    seasonPricesYear?: InputMaybe<Scalars["Int"]>;
};

export type QueryPayRatesArgs = {
    request: BookingRequestInput;
};

export type QuerySearchArgs = {
    durationFlexibility?: InputMaybe<Scalars["Int"]>;
    query?: InputMaybe<Scalars["String"]>;
    sorting?: InputMaybe<SearchSorting>;
};

export type QueryTermsArgs = {
    arrivalDate: Scalars["Date"];
    lodgingId: Scalars["Int"];
};

export type RootMutation = {
    __typename?: "RootMutation";
    createBooking?: Maybe<MutationResult_CreateBookingResult>;
    newsletterSignup?: Maybe<MutationResult_Boolean>;
};

export type RootMutationCreateBookingArgs = {
    payMethod?: InputMaybe<PaymentOption>;
    request?: InputMaybe<BookingRequestInput>;
};

export type RootMutationNewsletterSignupArgs = {
    email: Scalars["String"];
    firstName: Scalars["String"];
    lastName: Scalars["String"];
    mailConfigurationId: Scalars["String"];
    newsletterCategories: Array<Scalars["Int"]>;
};

export type SearchResult = {
    __typename?: "SearchResult";
    numberOfResults: Scalars["Int"];
    results?: Maybe<Array<Maybe<SearchResultLodging>>>;
    supportData?: Maybe<SupportData>;
};

export type SearchResultLodging = {
    __typename?: "SearchResultLodging";
    bookingOptions: Array<BookingOption>;
    group?: Maybe<Array<SearchResultLodging>>;
    groupInfo?: Maybe<GroupInfo>;
    lodging: Lodging;
    nameInGroup: Scalars["String"];
    positionInGroup: Scalars["Int"];
    selectedBookingOption: BookingOption;
};

export enum SearchSorting {
    PriceHighToLow = "PRICE_HIGH_TO_LOW",
    PriceLowToHigh = "PRICE_LOW_TO_HIGH",
    Standard = "STANDARD",
}

export type Season = {
    __typename?: "Season";
    id: Scalars["Int"];
    internalName: Scalars["String"];
    periods: Array<Maybe<SeasonPeriod>>;
    seasonCode: Scalars["String"];
    seasonCodeId: Scalars["Int"];
    seasonColorCode: Scalars["String"];
};

export type SeasonCalendar = {
    __typename?: "SeasonCalendar";
    id: Scalars["Int"];
    internalName: Scalars["String"];
    seasons: Array<Season>;
    year: Scalars["Int"];
};

export type SeasonPeriod = {
    __typename?: "SeasonPeriod";
    fromDate?: Maybe<Scalars["Date"]>;
    toDate?: Maybe<Scalars["Date"]>;
};

export type SeasonPrice = {
    __typename?: "SeasonPrice";
    colorCode: Scalars["String"];
    currency: Scalars["String"];
    lodgingId: Scalars["Int"];
    price: Scalars["Decimal"];
    priceDisplayValue: Scalars["String"];
    seasonCode: Scalars["String"];
    weeklyPriceDisplayValue: Scalars["String"];
    year: Scalars["Int"];
};

export type SupportData = {
    __typename?: "SupportData";
    facilities?: Maybe<Array<Maybe<Facility>>>;
    items?: Maybe<Array<Maybe<Item>>>;
    locations?: Maybe<Array<Maybe<Location>>>;
};

export type TermsAndConditions = {
    __typename?: "TermsAndConditions";
    description: Scalars["String"];
    id: Scalars["Int"];
};
