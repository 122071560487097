import React from "react";
import "./FieldWithLabel.scss";

export interface FieldWithLabelProps {
    label: string;
    value: string;
    onChange: (newValue) => void;
    pattern?: string;
    type?: string;
    required?: boolean;
    autoComplete?: string;
    placeholder?: string;
}

export default function FieldWithLabel({
    label,
    value,
    onChange,
    pattern,
    type = "text",
    required,
    autoComplete,
    placeholder,
}: FieldWithLabelProps) {
    const handleOnChange = (e: React.FormEvent<HTMLInputElement>) => {
        if (onChange) {
            onChange(e.currentTarget.value);
        }
    };

    if (required) {
        label = label + " *";
    }

    return (
        <div className="bwp-field-with-label">
            <label>
                <span>{label}</span>
                <input
                    className="bwp-field-with-label__value-container"
                    type={type}
                    required={required}
                    autoComplete={autoComplete}
                    value={value}
                    onChange={handleOnChange}
                    pattern={pattern}
                    placeholder={placeholder}
                />
            </label>
        </div>
    );
}
