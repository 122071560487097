import React, { ComponentProps, useEffect, useMemo } from "react";
import ReactDOM from "react-dom";
import { SearchResultListTranslations } from "./translations/SearchResultListTranslations";
import { LodgingCardTranslations } from "./translations/LodgingCardTranslations";
import useQueryString from "./shared/useQueryString";
import { SearchResultsPageQuery } from "./operations.generated";
import SearchResultListHeader from "./SearchResultListHeader";
import LodgingCard2 from "./LodgingCard2";
import "./LodgingList.scss";
import useFavorites from "./shared/useFavorites";
import { SearchResultListStyle } from "./shared/types";
import SearchResultMap from "./SearchResultMap";
import { isSameDay, parse, parseISO } from "date-fns";
import { viewList } from "./shared/trackingUtils";
import useScrollRestorationOnLoad from "./shared/useScrollRestorationOnLoad";
import { SearchContext } from "./shared/searchcontext";

export default function SearchResultList({
    translations,
    lodgingCardTranslations,
    search: { results, numberOfResults },
    headerElement,
    lodgingUrlBuilder,
    extraFacilities,
    sorting,
    setSorting,
    googleMapsApiKey,
    mapHeight,
    markerIconUrl,
    maxMapZoomLevel,
    emptyResultHtmlContent,
    searchContext,
}: {
    translations: SearchResultListTranslations;
    lodgingCardTranslations: LodgingCardTranslations;
    numberOfBedroomsFacilityId: number;
    numberOfBathroomsFacilityId: number;
    headerElement: HTMLElement;
    lodgingUrlBuilder: (l: SearchResultsPageQuery["search"]["results"][0]) => string;
    extraFacilities: number[];
    sorting: string;
    setSorting: (sorting: string) => void;
    googleMapsApiKey: string;
    mapHeight: number;
    markerIconUrl: string;
    maxMapZoomLevel: number;
    emptyResultHtmlContent?: string;
    searchContext: SearchContext;
} & SearchResultsPageQuery) {
    let [favorites, setFavorite] = useFavorites();
    let [listStyle, setListStyle] = useQueryString("listStyle", "Grid");

    useEffect(() => {
        window.dispatchEvent(
            new CustomEvent("numberOfSearchResultsUpdated", {
                detail: results.length,
            })
        );
    }, [results.length]);

    useEffect(() => {
        if (results.length > 0) {
            viewList(
                results.map((l) => ({
                    lodgingId: l.lodging.id,
                    lodgingName: l.lodging.name,
                }))
            );
        }
    }, [results]);

    useScrollRestorationOnLoad(results);

    return (
        <>
            {headerElement &&
                ReactDOM.createPortal(
                    <SearchResultListHeader
                        numberOfResults={numberOfResults}
                        sorting={sorting}
                        onSortingChange={setSorting}
                        translations={translations}
                        listStyle={listStyle as SearchResultListStyle}
                        onListStyleChange={(val) => setListStyle(val)}
                    />,
                    headerElement
                )}
            {results.length == 0 && (
                <div dangerouslySetInnerHTML={{ __html: emptyResultHtmlContent }} />
            )}
            {results.length != 0 && listStyle == "Grid" && (
                <SearchResultListGrid
                    {...{
                        translations,
                        lodgingCardTranslations,
                        extraFacilities,
                        lodgingUrlBuilder,
                        results,
                        setFavorite,
                        favorites,
                        searchContext,
                    }}
                />
            )}
            {results.length != 0 && listStyle == "Map" && (
                <SearchResultMap
                    extraFacilities={extraFacilities}
                    lodgingUrlBuilder={lodgingUrlBuilder}
                    lodgingCardTranslations={lodgingCardTranslations}
                    googleMapsApiKey={googleMapsApiKey}
                    searchResults={results.map((r) => ({
                        id: r.lodging.id,
                        longitude: r.lodging.longitude,
                        latitude: r.lodging.latitude,
                    }))}
                    mapHeight={mapHeight}
                    markerIconUrl={markerIconUrl}
                    maxMapZoomLevel={maxMapZoomLevel}
                    renderInfoWindow={(lodging) => {
                        let d = results.find((r) => r.lodging.id == lodging.id);
                        return (
                            <LodgingCard2
                                key={
                                    d.lodging.name +
                                    " " +
                                    d.selectedBookingOption.arrivalDisplayValue +
                                    " " +
                                    d.selectedBookingOption.departureDisplayValue +
                                    " " +
                                    d.selectedBookingOption.priceWithMandatoryItemsDisplayValue
                                }
                                lodging={d.lodging}
                                translations={lodgingCardTranslations}
                                extraFacilities={extraFacilities}
                                nextUrl={lodgingUrlBuilder(d)}
                                selectedBookingOption={d.selectedBookingOption}
                                searchContext={searchContext}
                                disableSmartImage
                                disableImageSlider
                            />
                        );
                    }}
                />
            )}
        </>
    );
}

interface SearchResultListGridProps {
    results: SearchResultsPageQuery["search"]["results"];
    translations: ComponentProps<typeof SearchResultList>["translations"];
    lodgingCardTranslations: ComponentProps<typeof SearchResultList>["lodgingCardTranslations"];
    extraFacilities: ComponentProps<typeof SearchResultList>["extraFacilities"];
    lodgingUrlBuilder: ComponentProps<typeof SearchResultList>["lodgingUrlBuilder"];
    searchContext: SearchContext;
    favorites: number[];
    setFavorite: (id: number) => void;
}

function SearchResultListGrid({
    results,
    lodgingCardTranslations,
    extraFacilities,
    favorites,
    lodgingUrlBuilder,
    setFavorite,
    searchContext,
    translations,
}: SearchResultListGridProps) {
    const arrivalDate = searchContext.arrival;

    const resultsOnArrivalDate = useMemo(
        () =>
            results.filter((r) =>
                isSameDay(parseISO(r.selectedBookingOption.arrival), arrivalDate)
            ),
        [results, arrivalDate]
    );
    const resultsOnOtherDaysSorted = useMemo(
        () =>
            results
                .filter((r) => !isSameDay(parseISO(r.selectedBookingOption.arrival), arrivalDate))
                .sort(
                    (a, b) =>
                        parseISO(a.selectedBookingOption.arrival).getTime() -
                        parseISO(b.selectedBookingOption.arrival).getTime()
                ),
        [results, arrivalDate]
    );

    function renderLodging(d: typeof results[0]) {
        return (
            <LodgingCard2
                key={
                    d.lodging.name +
                    " " +
                    d.selectedBookingOption.arrivalDisplayValue +
                    " " +
                    d.selectedBookingOption.departureDisplayValue +
                    " " +
                    d.selectedBookingOption.priceWithMandatoryItemsDisplayValue
                }
                lodging={d.lodging}
                translations={lodgingCardTranslations}
                extraFacilities={extraFacilities}
                nextUrl={lodgingUrlBuilder(d)}
                selectedBookingOption={d.selectedBookingOption}
                isFavorite={favorites.includes(d.lodging.id)}
                onSetFavorite={setFavorite}
                searchContext={searchContext}
            />
        );
    }

    return (
        <div className="bwp-lodging-list-grouped-container">
            <div className="bwp-lodging-list">
                {resultsOnArrivalDate.map((d) => renderLodging(d))}
            </div>
            {resultsOnArrivalDate.length == 0 && (
                <h2 className="bwp-lodging-list__group-heading">
                    {translations.noArrivalsOnDateShowsOtherDates}
                </h2>
            )}

            {resultsOnArrivalDate.length > 0 && <h2>{translations.resultsOnOtherArrivalDates}</h2>}
            <div className="bwp-lodging-list">
                {resultsOnOtherDaysSorted.map((d) => renderLodging(d))}
            </div>
        </div>
    );
}
