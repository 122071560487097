import * as React from "react";
import {
    getISOWeek,
    getDaysInMonth,
    format,
    addMonths,
    addDays,
    getISODay,
    parseISO,
    isEqual,
    isBefore,
    isAfter,
    parse,
} from "date-fns";
import { CalendarValue } from "./shared/types";
import "./DatePickerCalendar.scss";
import { PropsWithChildren } from "react";

export interface CalendarProps {
    days: DatePickerCalendarDay[];
    onChange: (value: CalendarValue) => void;
    onActiveMonthChanged?: (year: number, month: number) => void;
    displayYear: number;
    displayMonth: number;
    value: CalendarValue;
    showTwoMonths: boolean;
}

interface CalendarState {
    displayOverlay?: boolean;
    hoveredDay?: DayInfo;
    selectionMessage?: string;
}

interface DayInfo {
    day: DatePickerCalendarDay;
    parsedDate: Date;
    key: string;
}

export interface DatePickerCalendarDay {
    overrideValue?: string;
    title?: string;
    suggestionRange?: { from: string; to: string };
    date: string;
    parsedDate: Date;
    selectable?: boolean;
    unSelectableReason?: string;
    classNames: string[];
}

interface Cell {
    day: number;
    component: any;
}

/**
 * Finds the weeknumber by looking at the first day in the week
 * We will out the cells that is duds
 */
function getRowWeekNumer(date: Date, row: Cell[]) {
    const cell = row.filter((d) => d.day > 0)[0];
    if (cell?.day != null) {
        const day = addDays(date, cell.day - 1);
        const week = getISOWeek(day);
        return week;
    } else {
        return -1;
    }
}

export class DatePickerCalendar extends React.Component<CalendarProps, CalendarState> {
    constructor(props: CalendarProps) {
        super(props);

        this.state = {
            displayOverlay: false,
            selectionMessage: null,
        };
    }

    private renderCell = (day: number, dayInfo: DayInfo) => {
        const { displayYear, displayMonth } = this.props;
        const props: DayCellProps = {
            key: day.toString() + "-" + displayMonth + "-" + displayYear,
            className: dayInfo.day.classNames.join(" "),
            onMouseOver: () => {
                this.setState({ hoveredDay: dayInfo });
            },
            onMouseOut: () => {
                this.setState((state) =>
                    state.hoveredDay == dayInfo
                        ? { hoveredDay: null }
                        : { hoveredDay: state.hoveredDay }
                );
            },
        };

        props.title = dayInfo.day.title;

        if (dayInfo.day.selectable) {
            props.onClick = () => this.handleDayClicked(day, dayInfo);
        } else {
            props.title = dayInfo.day.unSelectableReason;
        }

        const suggestionRange = this.state.hoveredDay?.day?.suggestionRange;
        if (suggestionRange != null) {
            let from = parse(suggestionRange.from, "yyyy-MM-dd", new Date());
            let to = parse(suggestionRange.to, "yyyy-MM-dd", new Date());
            let parsedDate = dayInfo.parsedDate;
            if (isEqual(parsedDate, from)) {
                props.className += " bwp-calendar__cell-suggestion-arrival-date";
            }
            if (isAfter(parsedDate, from) && isBefore(parsedDate, to)) {
                props.className += " bwp-calendar__cell-suggestion";
            }
            if (isEqual(parsedDate, to)) {
                props.className += " bwp-calendar__cell-suggestion-departure-date";
            }
        }

        if (this.props.value != null) {
            let date = new Date(
                this.props.value.year,
                this.props.value.month - 1,
                this.props.value.day,
                0,
                0,
                0
            );

            let suggestionRange = this.props.days.find((d) => isEqual(d.parsedDate, date))
                ?.suggestionRange;

            if (suggestionRange != null) {
                let from = parse(suggestionRange.from, "yyyy-MM-dd", new Date());
                let to = parse(suggestionRange.to, "yyyy-MM-dd", new Date());
                let parsedDate = dayInfo.parsedDate;
                if (isEqual(parsedDate, from)) {
                    props.className += " bwp-calendar__cell-selected-arrival-date";
                }
                if (isAfter(parsedDate, from) && isBefore(parsedDate, to)) {
                    props.className += " bwp-calendar__cell-selected";
                }
                if (isEqual(parsedDate, to)) {
                    props.className += " bwp-calendar__cell-selected-departure-date";
                }
            } else {
                if (isEqual(dayInfo.day.parsedDate, date)) {
                    props.className += " bwp-calendar__cell-selected-single";
                }
            }
        }

        return <DayCell {...props}>{day.toString()}</DayCell>;
    };

    handleDayClicked = (day: number, dayInfo: DayInfo) => {
        if (dayInfo.day.selectable) {
            this.setState({ selectionMessage: null });
            if (dayInfo.day.overrideValue == null) {
                this.props.onChange(this.dayToValue(day));
            } else {
                let value = parse(dayInfo.day.overrideValue, "yyyy-MM-dd", new Date());
                this.props.onChange({
                    year: value.getFullYear(),
                    month: value.getMonth() + 1,
                    day: value.getDate(),
                });
            }
        } else {
            this.setState({ selectionMessage: dayInfo.day.unSelectableReason });
        }
    };

    generateCells = (date: Date, dayInfos: DayInfo[]) => {
        const cells: Cell[] = [];

        // we need to add duds for days not in this month
        const numberOfDuds = getISODay(date) - 1;
        for (let x = 1; x <= numberOfDuds; x++) {
            cells.push({ day: null, component: <DayCell key={"dud" + x} /> });
        }

        for (let x = 1; x <= getDaysInMonth(date); x++) {
            const key = format(new Date(date.getFullYear(), date.getMonth(), x), "yyyy-MM-dd");
            const dayInfo = dayInfos.find((x) => x.key === key);
            if (dayInfo) {
                cells.push({ day: x, component: this.renderCell(x, dayInfo) });
            }
        }

        return cells;
    };

    render() {
        const { displayYear, displayMonth, days, showTwoMonths } = this.props;
        if (!days) {
            return;
        }
        const dayInfos = days.map(
            (day) =>
                ({
                    day,
                    parsedDate: parseISO(day.date),
                    key: day.date.substr(0, "yyyy-MM-dd".length),
                } as DayInfo)
        );

        const monthNumber = displayMonth;
        const year = displayYear;

        const monthDate = new Date(year, monthNumber, 1, 0, 0, 0);
        const monthName = format(monthDate, "MMM");

        const nextMonthDate = new Date(year, monthNumber + 1, 1, 0, 0, 0);
        const nextMonthName = format(nextMonthDate, "MMM");
        const nextMonthYear = nextMonthDate.getFullYear();

        let weekDayNames = ["Søn", "Man", "Tirs", "Ons", "Tors", "Fre", "Lør"];

        switch (window["culture"]) {
            case "en-GB":
                weekDayNames = ["Sun", "Mon", "Tue", "Wen", "Thu", "Fri", "Sun"];
                break;
            case "de-DE":
                weekDayNames = ["Son", "Mon", "Die", "Mit", "Don", "Fre", "Sam"];
                break;
        }

        const cells: Cell[] = this.generateCells(monthDate, dayInfos);
        const nextMonthCells: Cell[] = this.generateCells(nextMonthDate, dayInfos);

        const rows = cells.reduce(
            (result: Array<Array<Cell>>, cur) => {
                let curRow = result[result.length - 1];
                if (curRow.length === 7) {
                    curRow = [];
                    result.push(curRow);
                }
                curRow.push(cur);
                return result;
            },
            [[]]
        ) as Array<Array<Cell>>;

        const nextMonthRows = nextMonthCells.reduce(
            (result: Array<Array<Cell>>, cur) => {
                let curRow = result[result.length - 1];
                if (curRow.length === 7) {
                    curRow = [];
                    result.push(curRow);
                }
                curRow.push(cur);
                return result;
            },
            [[]]
        ) as Array<Array<Cell>>;

        const showWeeks = window.innerWidth > 400;

        return (
            <div className="bwp-calendar-container">
                <div className="bwp-calendar">
                    <table className="table-condenced" style={{ width: "100%" }}>
                        <thead>
                            <tr>
                                <th
                                    className="bwp-calendar__navigator"
                                    onClick={this.handleNavigateBackward}
                                >
                                    «
                                </th>
                                <th className="bwp-calendar__header">
                                    {monthName} {year}
                                </th>
                                {showTwoMonths && (
                                    <th className="bwp-calendar__header">
                                        {nextMonthName} {nextMonthYear}
                                    </th>
                                )}
                                <th
                                    className="bwp-calendar__navigator"
                                    onClick={this.handleNavigateForward}
                                >
                                    »
                                </th>
                            </tr>
                        </thead>
                    </table>
                    <div style={{ display: "flex" }}>
                        <table className="table-condensed" style={{ flexGrow: 1 }}>
                            <thead>
                                <tr>
                                    {showWeeks ? <WeekCell /> : null}
                                    <th className="bwp-calendar__week-day-cell">
                                        {weekDayNames[1]}
                                    </th>
                                    <th className="bwp-calendar__week-day-cell">
                                        {weekDayNames[2]}
                                    </th>
                                    <th className="bwp-calendar__week-day-cell">
                                        {weekDayNames[3]}
                                    </th>
                                    <th className="bwp-calendar__week-day-cell">
                                        {weekDayNames[4]}
                                    </th>
                                    <th className="bwp-calendar__week-day-cell">
                                        {weekDayNames[5]}
                                    </th>
                                    <th className="bwp-calendar__week-day-cell">
                                        {weekDayNames[6]}
                                    </th>
                                    <th className="bwp-calendar__week-day-cell">
                                        {weekDayNames[0]}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {rows.map((row, index) => (
                                    <tr key={index}>
                                        {showWeeks ? (
                                            <WeekCell>{getRowWeekNumer(monthDate, row)}</WeekCell>
                                        ) : null}
                                        {row.map((r) => r.component)}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {showTwoMonths && (
                            <table className="table-condensed" style={{ flexGrow: 1 }}>
                                <thead>
                                    <tr>
                                        {showWeeks ? <WeekCell /> : null}
                                        <th className="bwp-calendar__week-day-cell">
                                            {weekDayNames[1]}
                                        </th>
                                        <th className="bwp-calendar__week-day-cell">
                                            {weekDayNames[2]}
                                        </th>
                                        <th className="bwp-calendar__week-day-cell">
                                            {weekDayNames[3]}
                                        </th>
                                        <th className="bwp-calendar__week-day-cell">
                                            {weekDayNames[4]}
                                        </th>
                                        <th className="bwp-calendar__week-day-cell">
                                            {weekDayNames[5]}
                                        </th>
                                        <th className="bwp-calendar__week-day-cell">
                                            {weekDayNames[6]}
                                        </th>
                                        <th className="bwp-calendar__week-day-cell">
                                            {weekDayNames[0]}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {nextMonthRows.map((row, index) => (
                                        <tr key={index}>
                                            {showWeeks ? (
                                                <WeekCell>
                                                    {getRowWeekNumer(nextMonthDate, row)}
                                                </WeekCell>
                                            ) : null}
                                            {row.map((r) => r.component)}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </div>
                    {this.state.selectionMessage && (
                        <span className="bwp-calendar__selection-message">
                            {this.state.selectionMessage}
                        </span>
                    )}
                </div>
            </div>
        );
    }

    private dayToValue = (day: number): CalendarValue => {
        const { displayYear, displayMonth } = this.props;
        return {
            year: displayYear,
            month: displayMonth + 1,
            day: day,
        };
    };

    private handleNavigateBackward = (e: React.SyntheticEvent<HTMLTableCellElement>) => {
        e.preventDefault();
        e.stopPropagation();

        const { displayYear, displayMonth } = this.props;
        const date = addMonths(new Date(displayYear, displayMonth, 1, 0, 0, 0), -1);

        this.props.onActiveMonthChanged(date.getFullYear(), date.getMonth());
    };

    private handleNavigateForward = (e: React.SyntheticEvent<HTMLTableCellElement>) => {
        e.preventDefault();
        e.stopPropagation();

        const { displayYear, displayMonth } = this.props;
        const date = addMonths(new Date(displayYear, displayMonth, 1), 1);

        this.props.onActiveMonthChanged(date.getFullYear(), date.getMonth());
    };
}

type DayCellProps = React.HTMLProps<HTMLTableCellElement>;

function DayCell(props: DayCellProps) {
    let basicClassName = "bwp-calendar__day-cell";

    if (props.onClick) {
        basicClassName = basicClassName + " bwp-calendar__cell-clickable";
    } else {
        basicClassName = basicClassName + " bwp-calendar__cell-not-clickable";
    }

    const localProps = {
        ...props,
        className: [basicClassName, props.className].join(" "),
    };

    return <td {...localProps}>{localProps.children}</td>;
}

const WeekCell = (props: PropsWithChildren<unknown>) => {
    return <td className="bwp-calendar__week-cell">{props.children}</td>;
};
