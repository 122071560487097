import * as React from "react";
import ImageViewer from "./ImageViewer";
import SmartBackgroundImage from "./SmartBackgroundImage";
import { LodgingPageTranslations } from "./translations/LodgingPageTranslations";
import Button from "./Button";
import Video from "./Video";

interface Props {
    disableViewerTools?: boolean;
    backgroundMode?: boolean;
    images: { url: string; tagIds: number[] }[];
    clusterImageTagId: number;
    floorPlainImageTagId: number;
    isGrouped: boolean;
    translations: LodgingPageTranslations;
    embeddedVideoHtml?: string;
}

interface State {
    viewerOpen: boolean;
    openingImageId: number;
    showVideo: boolean;
}

function imagesForCluster(
    images: { url: string; tagIds: number[] }[],
    clusterImageTagId: number,
    minNumberOfImages: number = null
) {
    if (images === undefined || images === null || images.length === 0) {
        return [];
    }

    if (
        clusterImageTagId === undefined ||
        clusterImageTagId === null ||
        isNaN(clusterImageTagId) ||
        clusterImageTagId <= 0
    ) {
        return [];
    }

    let clusteredImages = images.filter((img) => img.tagIds.some((id) => id === clusterImageTagId));

    if (minNumberOfImages == null) {
        return clusteredImages;
    } else {
        if (clusteredImages.length < minNumberOfImages) {
            let remainingImages = images.filter((i) => clusteredImages.find((c) => c == i) == null);
            let missingImages = minNumberOfImages - clusteredImages.length;
            return [...clusteredImages, ...remainingImages.slice(0, missingImages)];
        } else {
            return clusteredImages;
        }
    }
}

class LodgingImagesViewer extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            openingImageId: null,
            viewerOpen: false,
            showVideo: false,
        };
    }

    viewerToolsEnabled = () => {
        let disableViewerTools = this.props.disableViewerTools;
        if (typeof disableViewerTools === "undefined") {
            disableViewerTools = false;
        }
        return disableViewerTools === false;
    };

    handleViewerOpen = (imageId: number | null) => {
        if (this.viewerToolsEnabled()) {
            this.setState({
                viewerOpen: true,
                openingImageId: imageId,
            });
        }
    };

    handleViewerClose = () => {
        this.setState({
            viewerOpen: false,
            openingImageId: null,
        });
    };

    classnames = () => {
        const names = ["bwp-lodging-images"];
        if (this.backgroundModeEnabled()) {
            names.push("bwp-background-mode");
        }
        return names.join(" ");
    };

    backgroundModeEnabled = () => {
        let backgroundMode = this.props.backgroundMode;
        if (typeof backgroundMode === "undefined") {
            backgroundMode = false;
        }
        return backgroundMode;
    };

    getImageUrl = (image: { url: string }, width: number, height: number) => {
        let url = image.url.replace("/default", "");
        url = url.replace("/cl/", "/v2/");
        url = url + "?width=" + width + "&height=" + height + "&mode=crop";
        return url;
    };

    handlePlayVideo = () => {
        this.setState({ showVideo: true });
    };

    handleHideVideo = () => {
        this.setState({ showVideo: false });
    };

    render() {
        if (this.props.images === null) {
            const images = [0, 1, 2, 3, 4, 5, 6, 7];
            return (
                <div className={this.classnames()}>
                    <div className="bwp-lodging-image-grid1">
                        {images.slice(0, Math.min(images.length, 6)).map((img, index) => (
                            <div
                                key={`lodgingImages_${index}`}
                                className={`bwp-image-cell${index + 1} bwp-image-cell`}
                            />
                        ))}
                    </div>
                </div>
            );
        }

        const { viewerOpen, openingImageId } = this.state;
        const { clusterImageTagId, isGrouped } = this.props;

        let images: { url: string }[] = [];
        let clusteredImages: { url: string }[] = [];
        if (isGrouped) {
            images = imagesForCluster(this.props.images, clusterImageTagId);
            clusteredImages = images;
        } else {
            images = this.props.images;
            clusteredImages = imagesForCluster(this.props.images, clusterImageTagId, 6);
        }

        let floorPlanImageIndex = this.props.images.findIndex((i) =>
            i.tagIds.includes(this.props.floorPlainImageTagId)
        );

        return (
            <React.Fragment>
                <div className={this.classnames()}>
                    <div className="bwp-lodging-image-grid1">
                        {clusteredImages.slice(0, 6).map((img, index) => {
                            return (
                                <SmartBackgroundImage
                                    key={`lodgingImages_${index}`}
                                    className={`bwp-image-cell${index + 1} bwp-image-cell`}
                                    imageUrlFunc={(width, height) =>
                                        this.getImageUrl(img, width, height)
                                    }
                                    onClick={() => this.handleViewerOpen(index)}
                                />
                            );
                        })}
                        {this.props.embeddedVideoHtml && (
                            <div className="bwp-lodging-image-tools-left">
                                {this.state.showVideo == false && (
                                    <Button type="secondary" onClick={() => this.handlePlayVideo()}>
                                        <div
                                            style={{
                                                display: "grid",
                                                gridGap: "12px",
                                                gridAutoFlow: "column",
                                            }}
                                        >
                                            <VideoIcon />
                                            {this.props.translations.video}
                                        </div>
                                    </Button>
                                )}
                            </div>
                        )}
                        {this.state.showVideo && (
                            <div className="bwp-lodging-image__video">
                                <div className="bwp-lodging-image__video__close">
                                    <Button type="secondary" onClick={() => this.handleHideVideo()}>
                                        {this.props.translations.closeVideo}
                                    </Button>
                                </div>
                                <div className="bwp-lodging-image__video__container">
                                    <Video
                                        autoPlay
                                        url={this.props.embeddedVideoHtml}
                                        embedElementManipulator={(el) => {
                                            el.style.height = "100%";
                                            el.style.width = "100%";
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                        {this.viewerToolsEnabled() && (
                            <div className="bwp-lodging-image-tools">
                                {floorPlanImageIndex != -1 && (
                                    <Button
                                        type="secondary"
                                        className="bwp-lodging-image-tools__floorplan"
                                        onClick={() => this.handleViewerOpen(floorPlanImageIndex)}
                                    >
                                        {this.props.translations.showFloorPlan}
                                    </Button>
                                )}
                                <Button
                                    type="secondary"
                                    onClick={() => this.handleViewerOpen(null)}
                                >
                                    {this.props.translations.showImages}
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
                {viewerOpen && (
                    <ImageViewer
                        images={images}
                        onClose={this.handleViewerClose}
                        translations={this.props.translations}
                        openingImageIndex={openingImageId}
                    />
                )}
            </React.Fragment>
        );
    }
}

function VideoIcon() {
    return (
        <svg
            width={21}
            height={22}
            viewBox="0 0 21 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.0833 0C4.5131 0 0 4.92339 0 11C0 17.0766 4.5131 22 10.0833 22C15.6536 22 20.1667 17.0766 20.1667 11C20.1667 4.92339 15.6536 0 10.0833 0ZM14.7875 12.0645L7.63162 16.5444C6.98921 16.9347 6.18011 16.4335 6.18011 15.6129V6.3871C6.18011 5.57097 6.98515 5.06532 7.63162 5.45565L14.7875 10.2016C15.4543 10.6097 15.4543 11.6609 14.7875 12.0645Z"
                fill="black"
            />
        </svg>
    );
}

export default LodgingImagesViewer;
