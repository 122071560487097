import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from "react";
import "./FieldWithLabel.scss";
import DatePicker, { SelectionMode } from "./DatePicker";
import { CalendarValue } from "./shared/types";
import { formattedDate, getDateFromCalendarValue } from "./shared/helpers";
import { DatePickerTranslations } from "./translations/DatePickerTranslations";
import useOnClickOutside from "use-onclickoutside";

export interface FieldWithLabelProps {
    label: string;
    value: CalendarValue;
    onChange: (newValue: CalendarValue) => void;
    required?: boolean;
    culture: string;
    selectionMode: SelectionMode;
    translations: DatePickerTranslations;
    dayManipulator?: React.ComponentProps<typeof DatePicker>["dayManipulator"];
    showTwoMonths?: boolean;
}

export interface DatePickerWithLabelRef {
    focusAndShowCalendar: () => void;
}

function DatePickerWithLabel(
    {
        label,
        value,
        onChange,
        required,
        selectionMode,
        translations,
        culture,
        dayManipulator,
        showTwoMonths,
    }: FieldWithLabelProps,
    ref
) {
    let [showCalendar, setShowCalendar] = useState(false);
    let containerRef = useRef();
    useOnClickOutside(containerRef, () => setShowCalendar(false));
    let inputRef = useRef<HTMLInputElement>();

    useImperativeHandle(ref, () => ({
        focusAndShowCalendar: () => {
            setShowCalendar(true);
            inputRef.current.focus();
        },
    }));

    const handleInputClick = useCallback((e: React.SyntheticEvent<HTMLInputElement>) => {
        e.preventDefault();
        setShowCalendar((value) => !value);
    }, []);

    const handleOnChange = useCallback(
        (value: CalendarValue) => {
            onChange(value);
            setShowCalendar(false);
        },
        [onChange]
    );

    return (
        <div ref={containerRef} className="bwp-field-with-label">
            <label>
                <span>{label}</span>
                <input
                    ref={inputRef}
                    className="bwp-field-with-label__value-container"
                    style={{ cursor: "pointer" }}
                    type="text"
                    required={required}
                    readOnly
                    value={formattedDate(getDateFromCalendarValue(value), culture)}
                    onClick={handleInputClick}
                />
                <div className="bwp-field-with-label__icon">
                    <CalendarIcon />
                </div>
            </label>
            {showCalendar && (
                <div
                    className={
                        "bwp-datepicker-popup" +
                        (showTwoMonths ? " bwp-datepicker-popup--two-months" : "")
                    }
                >
                    <DatePicker
                        value={value}
                        onChange={handleOnChange}
                        selectionMode={selectionMode}
                        translations={translations}
                        culture={culture}
                        dayManipulator={dayManipulator}
                        showTwoMonths={showTwoMonths}
                    />
                </div>
            )}
        </div>
    );
}

export default forwardRef(DatePickerWithLabel);

function CalendarIcon() {
    return (
        <svg
            width="17"
            height="19"
            viewBox="0 0 17 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.28571 17.2857V14.3929H4.17857V17.2857H1.28571ZM4.82143 17.2857V14.3929H8.03571V17.2857H4.82143ZM1.28571 13.75V10.5357H4.17857V13.75H1.28571ZM4.82143 13.75V10.5357H8.03571V13.75H4.82143ZM1.28571 9.89286V7H4.17857V9.89286H1.28571ZM8.67857 17.2857V14.3929H11.8929V17.2857H8.67857ZM4.82143 9.89286V7H8.03571V9.89286H4.82143ZM12.5357 17.2857V14.3929H15.4286V17.2857H12.5357ZM8.67857 13.75V10.5357H11.8929V13.75H8.67857ZM5.14286 5.07143C5.14286 5.24219 4.99219 5.39286 4.82143 5.39286H4.17857C4.00781 5.39286 3.85714 5.24219 3.85714 5.07143V2.17857C3.85714 2.00781 4.00781 1.85714 4.17857 1.85714H4.82143C4.99219 1.85714 5.14286 2.00781 5.14286 2.17857V5.07143ZM12.5357 13.75V10.5357H15.4286V13.75H12.5357ZM8.67857 9.89286V7H11.8929V9.89286H8.67857ZM12.5357 9.89286V7H15.4286V9.89286H12.5357ZM12.8571 5.07143C12.8571 5.24219 12.7065 5.39286 12.5357 5.39286H11.8929C11.7221 5.39286 11.5714 5.24219 11.5714 5.07143V2.17857C11.5714 2.00781 11.7221 1.85714 11.8929 1.85714H12.5357C12.7065 1.85714 12.8571 2.00781 12.8571 2.17857V5.07143ZM16.7143 4.42857C16.7143 3.72545 16.1317 3.14286 15.4286 3.14286H14.1429V2.17857C14.1429 1.29464 13.4196 0.571427 12.5357 0.571427H11.8929C11.0089 0.571427 10.2857 1.29464 10.2857 2.17857V3.14286H6.42857V2.17857C6.42857 1.29464 5.70536 0.571427 4.82143 0.571427H4.17857C3.29464 0.571427 2.57143 1.29464 2.57143 2.17857V3.14286H1.28571C0.582589 3.14286 0 3.72545 0 4.42857V17.2857C0 17.9888 0.582589 18.5714 1.28571 18.5714H15.4286C16.1317 18.5714 16.7143 17.9888 16.7143 17.2857V4.42857Z"
                fill="#888888"
            />
        </svg>
    );
}
