import React from "react";

export default function getLodgingAutoComplete() {
    return {
        itemToString: item => item?.name,
        itemFilter: (item, inputValue) => {
            if (!inputValue) return false;
            const name = item?.name?.toLowerCase();
            const area = item?.location?.name?.toLowerCase();
            const address = item?.address1?.toLowerCase();
            return (
                (name && name.startsWith(inputValue.toLowerCase())) ||
                (area && area.startsWith(inputValue.toLowerCase())) ||
                (address && address.startsWith(inputValue.toLowerCase()))
            );
        },
        // eslint-disable-next-line react/display-name
        itemListItem: (item) => (
            <React.Fragment>
                <strong>{item?.name}</strong><br />
                {item?.address1}, {item?.location?.name}
            </React.Fragment>
        ),
        itemCompare: (a, b) => {
            if (a.name === b.name) {
                return 0;
            }
            return a.name < b.name ? -1 : 1;
        }
    };
}