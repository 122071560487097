import React from "react";
import "./PayRates.scss";
import "./Form.scss";
import { formattedDate, stringFormat } from "./shared/helpers";
import { CheckoutTranslations } from "./translations/CheckoutTranslations";
import PaymentOptions from "./PaymentOptions";
import { PaymentOption } from "./shared/types";
import {
    allowBankTransfer,
    allowCreditCard,
    hasTwoCreditCardPaymentOptions,
} from "./shared/paymentoptions";

export interface PayRateProps {
    amountDisplayValue: string;
    payDateDisplayValue: string;
}

export interface PayRatesProps {
    culture: string;
    totalPriceDisplayValue: string;
    payRates: PayRateProps[];
    paymentOptions: number[];
    isLastMinute?: boolean;
    allowCreditCardFullAmountWhenTwoRates: boolean;
    requireCreditCardFullAmount: boolean;
    lateBookingCreditCardRequired: boolean;
    payMethod: PaymentOption | null;
    onChange: (payMethod: PaymentOption) => void;
    translations: CheckoutTranslations;
}

export default function PayRates({
    culture,
    totalPriceDisplayValue,
    payRates,
    paymentOptions,
    isLastMinute,
    allowCreditCardFullAmountWhenTwoRates,
    requireCreditCardFullAmount,
    lateBookingCreditCardRequired,
    payMethod,
    onChange,
    translations,
}: PayRatesProps) {
    const handlePayMethodChange = (value) => {
        onChange(value);
    };

    const hasTwoRates = () => {
        return payRates && Array.isArray(payRates) && payRates.length === 2;
    };

    const getDescription = () => {
        if (
            allowBankTransfer(paymentOptions, isLastMinute, lateBookingCreditCardRequired) &&
            allowCreditCard(paymentOptions)
        ) {
            if (isLastMinute) {
                return translations.paymentBankCreditCardLastMinute;
            }

            if (requireCreditCardFullAmount) {
                return translations.paymentBankCreditCardOneRate;
            }

            return hasTwoRates()
                ? translations.paymentBankCreditCardTwoRates
                : translations.paymentBankCreditCardOneRate;
        }

        if (allowCreditCard(paymentOptions)) {
            if (isLastMinute) {
                return translations.paymentCreditCardLastMinute;
            }

            if (requireCreditCardFullAmount) {
                return translations.paymentCreditCardOneRate;
            }

            return hasTwoRates()
                ? translations.paymentCreditCardTwoRates
                : translations.paymentCreditCardOneRate;
        }

        if (isLastMinute) {
            return translations.descriptionLastMinute;
        }

        return hasTwoRates()
            ? translations.descriptionWithTwoRates
            : translations.descriptionWithOneRate;
    };

    const filteredPaymentOptions =
        paymentOptions?.indexOf(PaymentOption.BankTransfer) > -1 &&
        isLastMinute &&
        lateBookingCreditCardRequired
            ? paymentOptions.filter((po) => po != PaymentOption.BankTransfer)
            : paymentOptions;

    const showPaymentOptions =
        (allowBankTransfer(paymentOptions, isLastMinute, lateBookingCreditCardRequired) &&
            allowCreditCard(paymentOptions)) ||
        (allowCreditCard(paymentOptions) &&
            hasTwoRates() &&
            hasTwoCreditCardPaymentOptions(
                filteredPaymentOptions,
                allowCreditCardFullAmountWhenTwoRates,
                requireCreditCardFullAmount
            ));

    const creditCardFullAmountRequired =
        !allowBankTransfer(paymentOptions, isLastMinute, lateBookingCreditCardRequired) &&
        allowCreditCard(paymentOptions) &&
        requireCreditCardFullAmount;

    return (
        <section className="bwp-form-section">
            <h2>{translations.payRatesTitle}</h2>
            <p>{getDescription()}</p>
            <div className="bwp-form-section__list">
                {creditCardFullAmountRequired && (
                    <PayRateRow
                        payRate={{
                            payDateDisplayValue: formattedDate(new Date(), culture),
                            amountDisplayValue: totalPriceDisplayValue,
                        }}
                        label={stringFormat(translations.rateLabel, "1")}
                        translations={translations}
                    />
                )}
                {!creditCardFullAmountRequired &&
                    payRates.map((payRate, index) => (
                        <PayRateRow
                            key={`payrate${index}`}
                            payRate={payRate}
                            label={stringFormat(translations.rateLabel, (index + 1).toString())}
                            translations={translations}
                        />
                    ))}
                {showPaymentOptions && (
                    <PaymentOptions
                        payRates={payRates}
                        payMethod={payMethod}
                        onPayMethodChange={handlePayMethodChange}
                        paymentOptions={filteredPaymentOptions}
                        allowCreditCardFullAmountWhenTwoRates={
                            allowCreditCardFullAmountWhenTwoRates
                        }
                        requireCreditCardFullAmount={requireCreditCardFullAmount}
                        translations={translations}
                    />
                )}
            </div>
        </section>
    );
}

export interface PayRateRowProps {
    payRate: PayRateProps;
    label: string;
    translations: CheckoutTranslations;
}

function PayRateRow({ payRate, label, translations }: PayRateRowProps) {
    return (
        <div className="bwp-payrate-row">
            <div className="bwp-payrate-row__label">{label}</div>
            <div className="bwp-payrate-row__value">{payRate.amountDisplayValue}</div>
            <div className="bwp-payrate-row__details">
                {stringFormat(translations.isDueOn, payRate.payDateDisplayValue)}
            </div>
        </div>
    );
}
