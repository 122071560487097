import React, { useState, useMemo, useEffect, useRef } from "react";
import "./FieldWithLabel.scss";
import "./AutoCompleteFieldWithLabel.scss";
import { useCombobox } from "downshift";

export interface AutoCompleteFieldWithLabelProps {
    label: string;
    focus?: boolean;
    onChange: (newValue) => void;
    items: any[];
    itemFilter: (item: any, inputValue: string) => boolean;
    itemToString: (item: any) => string;
    itemListItem: (item: any) => React.ReactNode;
    itemCompare: (a: any, b: any) => number;
    onLoadItems: () => void;
}

export default function AutoCompleteFieldWithLabel({
    label,
    focus,
    onChange,
    items,
    itemFilter,
    itemToString,
    itemListItem,
    itemCompare,
    onLoadItems,
}: AutoCompleteFieldWithLabelProps) {
    const [allItems, setAllItems] = useState([]);
    const [inputItems, setInputItems] = useState([]);
    const [hasRequestedItems, setHasRequestedItems] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
        if (hasRequestedItems && items && items.length) {
            const copy = [...items];
            copy.sort((a, b) => itemCompare(a, b));
            setAllItems(copy);
            setInputItems(copy);
        }
    }, [items, hasRequestedItems]);

    useEffect(() => {
        if (focus && inputRef && inputRef.current) {
            inputRef.current.focus();
        }
    }, [focus]);

    const {
        isOpen,
        getToggleButtonProps,
        getLabelProps,
        getMenuProps,
        highlightedIndex,
        getItemProps,
        getInputProps,
        getComboboxProps,
    } = useCombobox({
        items: inputItems,
        itemToString,
        onInputValueChange: ({ inputValue }) => {
            if (!hasRequestedItems && inputValue) {
                onLoadItems();
                setHasRequestedItems(true);
            }
            setInputItems(allItems.filter((item) => itemFilter(item, inputValue)));
        },
        onSelectedItemChange: (changes) => {
            handleChange(changes.selectedItem);
        },
        defaultHighlightedIndex: 0,
    });

    const handleChange = (selectedItem) => {
        if (onChange && typeof onChange === "function") {
            onChange(selectedItem);
        }
    };

    const listClassName = isOpen && inputItems.length ? "bwp-open" : "bwp-closed";

    return (
        <div className="bwp-auto-complete-field-with-label">
            <div className="bwp-field-with-label" {...getComboboxProps()}>
                <label {...getLabelProps()}>{label}</label>
                <input type="text" {...getInputProps({ ref: inputRef })} className="bwp-auto-complete-field-with-label__value-container" />
            </div>
            <ul {...getMenuProps()} className={listClassName}>
                {isOpen &&
                    inputItems.map((item, index) => {
                        return (
                            <li
                                key={`${itemToString(item)}`}
                                className={index === highlightedIndex ? "bwp-active" : undefined}
                                {...getItemProps({ item, index })}
                            >
                                <div className="bwp-listitem">{itemListItem(item)}</div>
                            </li>
                        );
                    })}
            </ul>
        </div>
    );
}
