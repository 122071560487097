import React, { useState } from "react";
import "./ItemsSelector.scss";
import NumberPicker from "./NumberPicker";
import { stringFormat } from "./shared/helpers";
import useModal from "./shared/useModal";
import { CheckoutTranslations } from "./translations/CheckoutTranslations";
import { ItemSelectorCheckoutPresentationFragment } from "./operations.generated";

export interface ItemsSelectorTranslations {
    headline: string;
    perItem: string;
    information: string;
    allExtrasTotal: string;
    totalPrice: string;
}

export interface ItemProps {
    name: string;
    shortDescription: string;
    description: string;
}

export interface ItemPriceProps {
    itemId: number;
    defaultQuantity: number;
    minQuantity: number;
    maxQuantity: number;
    unitPriceDisplayValue: string;
    priceWithQuantityDisplayValue: string;
    item: ItemProps;
}

export interface ItemsSelectorProps {
    data: ItemSelectorCheckoutPresentationFragment;
    translations: CheckoutTranslations;
    onItemQuantityChange: (itemId: number, quantity: number) => void;
}

export default function ItemsSelector({
    data,
    translations,
    onItemQuantityChange,
}: ItemsSelectorProps) {
    const [currentItem, setCurrentItem] = useState<ItemProps>(null);
    const { Modal, showModal } = useModal();

    const handleItemInfoClick = (item: ItemProps) => {
        setCurrentItem(item);
        showModal();
    };

    return (
        <>
            <div className="bwp-items-selector">
                <h2>{translations.itemSelectorHeadline}</h2>
                <div className="bwp-items-selector__list">
                    {data.itemLines
                        .filter((ip) => ip.itemPrice.isMandatory == false)
                        .map((ip) => (
                            <SelectorRow
                                key={`item${ip.item.id}`}
                                value={ip.quantity}
                                onChange={(value) => onItemQuantityChange(ip.item.id, value)}
                                onItemInfoClick={handleItemInfoClick}
                                translations={translations}
                                item={ip.item}
                                maxQuantity={ip.itemPrice.maxQuantity}
                                minQuantity={ip.itemPrice.minQuantity}
                                totalPriceDisplayValue={ip.totalPriceDisplayValue}
                                unitPriceDisplayValue={ip.itemPrice.unitPriceDisplayValue}
                            />
                        ))}
                </div>
                <div className="bwp-items-selector__totals">
                    <TotalsRow
                        label={translations.allExtrasTotal}
                        value={data.itemLinesTotalDisplayValue}
                    />
                    <TotalsRow
                        label={translations.totalPrice}
                        value={data.totalPriceDisplayValue}
                    />
                </div>
            </div>
            {currentItem && (
                <Modal
                    className="bwp-items-selector__item-info-modal"
                    title={translations.information}
                    translations={{ close: "Close" }}
                >
                    <h3>{currentItem.name}</h3>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: currentItem.description || currentItem.shortDescription,
                        }}
                    />
                </Modal>
            )}
        </>
    );
}

export interface TotalsRowProps {
    label: string;
    value: string;
}

function TotalsRow({ label, value }: TotalsRowProps) {
    return (
        <div className="bwp-items-selector__total">
            <div className="bwp-items-selector__total-label">{label}</div>
            <div className="bwp-items-selector__total-value">{value}</div>
        </div>
    );
}

export interface SelectorRowProps {
    value: number;
    onChange: (newValue: number) => void;
    onItemInfoClick?: (item: ItemProps) => void;
    translations: CheckoutTranslations;
    unitPriceDisplayValue: string;
    maxQuantity: number;
    minQuantity: number;
    item: ItemSelectorCheckoutPresentationFragment["itemLines"][0]["item"];
    totalPriceDisplayValue: string;
}

function SelectorRow({
    value,
    onChange,
    onItemInfoClick,
    translations,
    unitPriceDisplayValue,
    item,
    maxQuantity,
    minQuantity,
    totalPriceDisplayValue,
}: SelectorRowProps) {
    const unitPrice = () => {
        if (maxQuantity > 1) {
            return stringFormat(translations.perItem, unitPriceDisplayValue);
        }
        return unitPriceDisplayValue;
    };

    const handleNameClick = (item) => {
        if (onItemInfoClick) {
            onItemInfoClick(item);
        }
    };

    const label =
        onItemInfoClick && (item.shortDescription || item.description) ? (
            <a
                href="#open"
                onClick={(e) => {
                    e.preventDefault();
                    handleNameClick(item);
                }}
            >
                {item.name}
            </a>
        ) : (
            item.name
        );

    return (
        <div className="bwp-items-selector__list-item">
            <div className="bwp-items-selector__list-item-label">
                <span>{label}</span>
                <span className="bwp-items-selector__list-item-unit-price">{unitPrice()}</span>
            </div>
            <div className="bwp-items-selector__list-item-picker">
                <NumberPicker
                    value={value}
                    onChange={onChange}
                    min={minQuantity}
                    max={maxQuantity}
                />
                <div className="bwp-items-selector__list-item-picker-amount">
                    {totalPriceDisplayValue}
                </div>
            </div>
        </div>
    );
}
