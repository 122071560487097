import { SearchContext } from "./searchcontext";

export type ButtonType = "default" | "primary" | "secondary" | "filter";

export type SearchResultListStyle = "Grid" | "Map";

export interface SelectItem {
    value: string;
    text: string;
}

export interface NavNode {
    name: string;
    url?: string;
    icon?: "facebook" | "instagram";
    nodes?: NavNode[];
}

export interface NavGroup {
    headline: string;
    icon?: "info-circle" | "comment";
    nodes: NavNode[];
}

export interface Language {
    isoCode: string;
    name: string;
    url: string;
    isCurrent: boolean;
}

export interface Brand {
    name: string;
    logoUrl: string;
    logoWidth?: string;
    legalName?: string;
    url?: string;
    address?: string;
    telephone?: string;
    email?: string;
}

export interface WeekInfo {
    arrivalDate: Date;
    weekNumber: number;
}

export type FacilityValueComparison = "equal" | "min" | "max";

export enum MasterFacilities {
    MaxPeople = 1,
    NumberOfBedrooms = 2,
    HouseArea = 3,
    GroundArea = 4,
    BuiltYear = 5,
    ReconstructedYear = 6,
    PetsAllowed = 7,
    PetsNotAllowed = 8,
    NumberOfPetsAllowed = 9,
    Internet = 10,
    Swimmingpool = 11,
    Spa = 12,
    Sauna = 13,
    Dishwasher = 14,
    WashingMachine = 15,
    WoodStove = 16,
    SmokingAllowed = 17,
    SmokingNotAllowed = 18,
}

export interface Money {
    amount: number;
    currency: string;
}

export interface PluralizedTranslation {
    zero?: string;
    one?: string;
    // According to the spec (https://lingohub.com/blog/2019/02/pluralization) - then theese can be included as well - but we don't support them yet
    // two?: string;
    // few?: string;
    // many?: string;
    other: string;
}

export type UrlWriter = (searchContext: SearchContext) => string;
export type UrlReader = (location: Location) => SearchContext;

export interface CalendarValue {
    year: number;
    month: number;
    day: number;
}

export type Dictionary = { [key: string]: string };

export interface GenericDictionary<T> {
    [key: string]: T;
}

export interface CultureInfo {
    isoName: string;
    languageCode: string;
    bsLanguageId: number;
}

export interface SearchBoxSettings {
    id: number;
    maxPeople: number;
    maxPets: number;
    name: string;
    version: number;
    availableDurations: number[];
    booleanFacilities: {
        facilityId: number;
        displayName: string;
    }[];
    rangeFacilities: {
        facilityId: number;
        displayName: string;
        min: number;
        max: number;
        minName?: string;
        maxName?: string;
    }[];
}

export interface Coordinate {
    lat: number;
    lng: number;
}

export interface LodgingListSetting {
    id: number;
    name: string;
    locations: number[];
    facilities: { facilityId: number; value: string; minValue: string; maxValue: string }[];
}

export interface Favourite {
    id: number;
    isFavourite: boolean;
}

export interface CommonStyles {
    marginTop?: string;
    marginBottom?: string;
    marginLeft?: string;
    marginRight?: string;
    paddingTop?: string;
    paddingBottom?: string;
    paddingLeft?: string;
    paddingRight?: string;
}

export enum PaymentOption {
    BankTransfer,
    CreditCardOneRate,
    CreditCardTwoRates,
}
