import { BookingOption } from "./graphql-types.generated";
import { SearchContext } from "./searchcontext";
import { SearchResultsPageQuery } from "../operations.generated";
import { format, formatISO, parse } from "date-fns";

export type LodgingUrlData = {
    id: number;
    nameSlug: string;
    address1Slug: string;
    location: {
        slug: string;
    };
};

export function lodgingUrl(pattern: string, lodging: LodgingUrlData) {
    const values = [
        { key: "lodging:id", value: lodging.id.toString() },
        { key: "lodging:name", value: lodging.nameSlug },
        { key: "lodging:area", value: lodging.location.slug },
        { key: "lodging:address", value: lodging.address1Slug },
    ];

    let url = pattern;
    for (const value of values) {
        url = url.replace(`{${value.key}}`, value.value);
    }

    return url;
}

export function lodgingUrlWithBookingOption(
    pattern: string,
    lodging: LodgingUrlData,
    bookingOption: SearchResultsPageQuery["search"]["results"][0]["selectedBookingOption"]
) {
    let searchContext = SearchContext.createFromSearchContextProps({
        adults: bookingOption.adults,
        children: bookingOption.children,
        infants: bookingOption.infants,
        pets: bookingOption.pets,
        arrival: format(parse(bookingOption.arrival, "yyyy-MM-dd", new Date()), "MM-dd-yyyy"),
        duration: bookingOption.duration,
    });
    return `${lodgingUrl(pattern, lodging)}?${searchContext.toQueryString()}`;
}
