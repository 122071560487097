import * as React from "react";
import format from "date-fns/format";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import subDays from "date-fns/subDays";
import * as queryString from "query-string";
import { CalendarValue, FacilityValueComparison } from "../../shared/types";
import { isValidDate, facilityKey } from "../helpers";
import slugify from "slugify";
import { isDate, parse, parseISO } from "date-fns";

type Dictionary = { [key: string]: string };
const contextPrefix = "context:";

function parseQueryStringDate(date: string | string[]) {
    if (!date) {
        return null;
    }

    const dateStr = Array.isArray(date) ? date[0] : date;

    const tokens = dateStr.split("-");

    return new Date(Date.parse(tokens[2] + "-" + tokens[0] + "-" + tokens[1]));
}

export interface SearchContextProps {
    arrival?: string;
    duration?: number;
    adults?: number;
    children?: number;
    infants?: number;
    pets?: number;
    locationIds?: number[];
    lodgingId?: number;
    lodgingIds?: number[];
    page?: number;
    pageSize?: number;
    allItemPrices?: boolean;
    facilityValues?: Dictionary;
}

export interface SearchContextValues {
    adults?: string;
    children?: string;
    infants?: string;
    pets?: string;
    arrival?: string;
    duration?: string;
    lodgingId?: string;
    lodgingIds?: string[];
    priceFrom?: string;
    priceTo?: string;
    locationIds?: string[];
    page?: string;
    pageSize?: string;
    allItemPrices?: string;
    facilityValues?: Dictionary;
}

export interface SearchContextRouterOptions {
    getPathname?: () => string;
    getSearch?: () => string;
}

export type RoutePropertyMap = { [key: string]: string };

export type RouteValueMap = {
    [key: string]: RoutePropertyMap;
};

export interface SearchContextRouterConfig {
    url: string;
    map: RouteValueMap;
}

export function serializeNumber(value: number) {
    if (value === null || value === undefined) {
        throw new Error("Argument value for serializeNumber is not valid");
    }

    return value.toString();
}

export function deserializeNumber(value: string) {
    if (value === null || value === undefined) {
        return undefined;
    }

    return parseInt(value, 10);
}

export function serializeString(value: string) {
    if (value === null || value === undefined) {
        throw new Error("Argument value for serializeString is not valid");
    }

    return slugify(value).toLowerCase();
}

export function serializeDate(date: Date) {
    if (!isValidDate(date)) {
        throw new Error("Invalid date given to serializeDate");
    }

    return format(date, "MM-DD-YYYY");
}

export function deserializeDate(value: string) {
    return parseQueryStringDate(value);
}

export function serializeNumberArray(numbers: number[]) {
    if (numbers === null || numbers === undefined) {
        throw new Error("Invalid numbers array given to serializeNumbersArray");
    }

    return numbers.join(",");
}

export function deserializeNumberArray(value: string) {
    if (value === null || value === undefined) {
        return undefined;
    }

    return value
        .split(",")
        .map((p) => parseInt(p, 10))
        .filter((n) => !isNaN(n));
}

/**
 * Parses the query object and returns all the keys that start with fac, as a object with the facility id and the value
 */
function queryStringToFacilityValues(query: any): Dictionary {
    const facilityValues: Dictionary = {};
    Object.keys(query)
        .filter((name) => name.startsWith("fac"))
        .forEach((name) => {
            facilityValues[name] = query[name];
        });

    return facilityValues;
}

/**
 * Parses the query object and returns all the keys that start with context:, as a object with the key and the value
 */
function queryStringToContextValues(query: any): Dictionary {
    const contextValues: Dictionary = {};
    Object.keys(query)
        .filter((name) => name.startsWith(contextPrefix))
        .forEach((name) => {
            contextValues[name.substring(contextPrefix.length)] = query[name];
        });

    return contextValues;
}

/**
 * The SearchContext is a object that contains information about a search in BookingStudio.
 * It can convert to and from a querystring. That querystring can then be converted to a C# SearchContext on the backend and used in the API
 */
export class SearchContext {
    private _adults = null as number;
    private _children = null as number;
    private _infants = null as number;
    private _pets = null as number;
    private _duration = null as number;
    private _lodgingId = null as number;
    private _lodgingIds = null as number[];
    private _arrival = null as Date;
    private _priceFrom = null as number;
    private _priceTo = null as number;
    private _locationIds = null as number[];
    private _facilityValues = {} as Dictionary;
    private _pageSize = null as number;
    private _page = null as number;
    private _allItemPrices = false;
    private _contextValues = {} as Dictionary;
    private _preset = null as SearchContext;
    private _arrivalDensity = null as number;

    get pageSize() {
        return this._pageSize;
    }

    get page() {
        return this._page;
    }

    get adults() {
        return this._adults;
    }
    get children() {
        return this._children;
    }
    get infants() {
        return this._infants;
    }
    get pets() {
        return this._pets;
    }
    get duration() {
        return this._duration;
    }
    get lodgingId() {
        return this._lodgingId;
    }
    get lodgingIds() {
        return this._lodgingIds;
    }
    get arrival() {
        return this._arrival;
    }
    get arrivalDensity() {
        return this._arrivalDensity;
    }
    get parsedArrival() {
        if (!isValidDate(this._arrival)) {
            null;
        }

        return {
            day: this._arrival.getDate(),
            month: this._arrival.getMonth() + 1,
            year: this._arrival.getFullYear(),
        };
    }
    get priceFrom() {
        return this._priceFrom;
    }
    get priceTo() {
        return this._priceTo;
    }
    get locationIds() {
        return this._locationIds;
    }
    get facilityValues() {
        return this._facilityValues;
    }
    get allItemPrices() {
        return this._allItemPrices;
    }

    get contextValues() {
        return this._contextValues;
    }

    get preset() {
        return this._preset;
    }

    changeLodging = (lodgingId: number) => {
        const clone = this.clone();
        clone._lodgingId = lodgingId;
        return clone;
    };

    changeLodgings = (lodgingIds: number[]) => {
        const clone = this.clone();
        clone._lodgingIds = lodgingIds;
        return clone;
    };

    changeDuration = (value: number) => {
        const clone = this.clone();
        clone._duration = value;
        return clone;
    };

    changeAdults = (adults: number) => {
        const clone = this.clone();
        clone._adults = adults;
        return clone;
    };

    changeChildren = (children: number) => {
        const clone = this.clone();
        clone._children = children;
        return clone;
    };

    changeInfants = (infants: number) => {
        const clone = this.clone();
        clone._infants = infants;
        return clone;
    };

    changePets = (pets: number) => {
        const clone = this.clone();
        clone._pets = pets;
        return clone;
    };

    changePersons = (adults: number, children: number, infants: number, pets: number) => {
        const clone = this.clone();
        clone._adults = adults;
        clone._children = children;
        clone._infants = infants;
        clone._pets = pets;
        return clone;
    };

    changeArrival = (arrival: Date | CalendarValue) => {
        let value: Date;
        if (isDate(arrival)) {
            value = arrival as Date;
        } else {
            let cArrival = arrival as CalendarValue;
            value = new Date(cArrival.year, cArrival.month - 1, cArrival.day, 0, 0, 0);
        }

        const clone = this.clone();
        clone._arrival = value;
        return clone;
    };

    changeArrivalDensity = (value: number) => {
        const clone = this.clone();
        clone._arrivalDensity = value;
        return clone;
    };

    changeDeparture = (departure: Date) => {
        let arrival = this.arrival;
        let duration = this.duration;

        // Handle where arrival hasn't been set.
        // Set arrival to departure minus duration (default default is one week)
        if (!arrival) {
            if (duration) {
                duration = 7;
            }
            arrival = subDays(departure, duration);
        }

        // Handle where depareture is before arrival.
        // Move arrival to be same amount before departure
        if (differenceInCalendarDays(departure, arrival) < 0) {
            arrival = subDays(departure, duration);
        }

        const diffInDays = differenceInCalendarDays(departure, arrival);

        let clone = this.changeDuration(diffInDays);
        if (arrival !== this.arrival) {
            clone = clone.changeArrival(arrival);
        }
        return clone;
    };

    changePage = (value: number) => {
        const clone = this.clone();
        clone._page = value;
        return clone;
    };

    changePageSize = (value: number) => {
        const clone = this.clone();
        clone._pageSize = value;
        return clone;
    };

    changeLocationId = (locationId: number) => {
        const clone = this.clone();
        if (locationId !== null) {
            clone._locationIds = [locationId];
        } else {
            clone._locationIds = [];
        }
        return clone;
    };

    changeLocationIds = (locationIds: number[]) => {
        const clone = this.clone();
        clone._locationIds = locationIds;
        return clone;
    };

    changeAllItemPrices = (allItemPrices: boolean) => {
        const clone = this.clone();
        clone._allItemPrices = allItemPrices;
        return clone;
    };

    changeFacilityValue = (
        id: number,
        value: string,
        comparison: FacilityValueComparison = "equal"
    ) => {
        const clone = this.clone();
        clone._facilityValues[facilityKey(id, comparison)] = value;
        return clone;
    };

    changeContextValue = (name: string, value: string) => {
        const clone = this.clone();
        clone._contextValues[name] = value;
        return clone;
    };

    changeFacilityRange = (id: number, range: { min: number; max: number | null }) => {
        const clone = this.clone();
        clone._facilityValues[facilityKey(id, "min")] = range.min?.toString();
        clone._facilityValues[facilityKey(id, "max")] = range.max?.toString();
        return clone;
    };

    getFacilityRange = (id: number) => {
        let minRaw = this._facilityValues[facilityKey(id, "min")];
        let maxRaw = this._facilityValues[facilityKey(id, "max")];

        let min: number = null;
        let max: number = null;

        if (minRaw !== undefined && minRaw !== null) {
            min = parseInt(minRaw);
        }
        if (maxRaw !== undefined && maxRaw !== null) {
            max = parseInt(maxRaw);
        }

        return { min, max };
    };

    getFacilityEqualValue = (id: number) => {
        return this.facilityValues[facilityKey(id, "equal")];
    };

    canSearchBookingOptions = () => {
        return (
            (this._adults || 0) + (this._children || 0) > 0 &&
            this._duration &&
            this._duration > 0 &&
            this._arrival &&
            isValidDate(this._arrival)
        );
    };

    canChangeFacilityValue(facilityId: number) {
        if (this.preset == null) {
            return true;
        } else {
            let strFacilityId = "fac" + facilityId.toString();
            return (
                Object.keys(this.preset.facilityValues).every((f) => f != strFacilityId) &&
                Object.keys(this.preset.facilityValues).every((f) => f != strFacilityId + "_min") &&
                Object.keys(this.preset.facilityValues).every((f) => f != strFacilityId + "_max")
            );
        }
    }

    static createFromQueryStringWithDefaults = (qs: string, defaults: SearchContextProps) => {
        let fromQueryString = SearchContext.createFromQueryString(qs);
        return SearchContext.createFromSearchContextProps(defaults).merge(fromQueryString);
    };

    /**
     * Parses a querystring and returns an instance of the SearchContext
     */
    static createFromQueryString = (qs: string) => {
        const query = queryString.parse(qs);
        const instance = new SearchContext();
        const parseIntValue = (value: string | string[]) => {
            const parsedValue = Array.isArray(value) ? parseInt(value[0], 10) : parseInt(value, 10);

            if (isNaN(parsedValue)) {
                return null;
            }

            return parsedValue;
        };

        const parseFloatValue = (value: string | string[]) => {
            const parsedValue = Array.isArray(value) ? parseFloat(value[0]) : parseFloat(value);

            if (isNaN(parsedValue)) {
                return null;
            }

            return parsedValue;
        };

        const parseBooleanValue = (value: string | string[]) => {
            const parsedValue = Array.isArray(value)
                ? value[0]?.toLowerCase() === "true"
                : value?.toLowerCase() === "true";

            return parsedValue;
        };

        instance._page = parseIntValue(query.pge) || 0;
        instance._pageSize = parseIntValue(query.psz) || null;
        instance._adults = parseIntValue(query.adu);
        instance._children = parseIntValue(query.chi);
        instance._infants = parseIntValue(query.inf);
        instance._pets = parseIntValue(query.pet);
        instance._duration = parseIntValue(query.dur);
        instance._lodgingId = parseIntValue(query.lod);
        instance._lodgingIds = query.lods
            ? (query.lods as string).split(",").map((x) => parseInt(x, 10))
            : [];
        instance._arrival = parseQueryStringDate(query.ari);
        instance._arrivalDensity = parseIntValue(query.ari_density);
        instance._priceFrom = parseFloatValue(query.prfro);
        instance._priceTo = parseFloatValue(query.prto);
        instance._locationIds = query.loc
            ? (query.loc as string).split(",").map((x) => parseInt(x, 10))
            : [];
        instance._allItemPrices = parseBooleanValue(query.itp);
        instance._facilityValues = queryStringToFacilityValues(query);
        instance._contextValues = queryStringToContextValues(query);
        return instance;
    };

    static createFromSearchContextProps = (props: SearchContextProps) => {
        const searchContext = new SearchContext();
        searchContext._duration = props.duration;
        searchContext._arrival = parse(props.arrival, "MM-dd-yyyy", new Date());
        searchContext._adults = props.adults;
        searchContext._children = props.children;
        searchContext._infants = props.infants;
        searchContext._pets = props.pets;
        searchContext._locationIds = props.locationIds;
        searchContext._facilityValues = props.facilityValues || {};
        searchContext._lodgingId = props.lodgingId;
        searchContext._lodgingIds = props.lodgingIds;
        searchContext._page = props.page || 0;
        searchContext._pageSize = props.pageSize || null;
        searchContext._allItemPrices = props.allItemPrices || false;
        return searchContext;
    };

    /**
     * Converts the SearchContext to a querystring that can be used in the backend. Is not prefixed by "?"
     */
    toQueryString = (ignored: string[] = [], ignorePresets = false) => {
        const result = [];
        // We add a element to the querystring and omit empty values
        const addPart = (propertyName: string, qsName: string, value: string | number) => {
            const isIgnored = ignored.indexOf(propertyName) > -1;
            if (!isIgnored) {
                if (value !== null && value !== undefined) {
                    result.push(qsName + "=" + encodeURIComponent(value.toString()));
                }
            }
        };

        if (this.adults) addPart("adults", "adu", this.adults);
        if (this.children) addPart("children", "chi", this.children);
        if (this.infants) addPart("infants", "inf", this.infants);
        addPart("pets", "pet", this.pets);
        if (this.duration) addPart("duration", "dur", this.duration);
        if (this.lodgingId) addPart("lodgingId", "lod", this.lodgingId);
        if (this.lodgingIds && this.lodgingIds.length > 0) {
            addPart("lodgingIds", "lods", this.lodgingIds.join(","));
        }
        if (this.arrival && isValidDate(this.arrival))
            addPart("arrival", "ari", format(this.arrival, "MM-dd-yyyy"));
        if (this.arrivalDensity) addPart("arrivalDensity", "ari_density", this.arrivalDensity);
        if (this.priceFrom) addPart("priceFrom", "prfro", this.priceFrom);
        if (this.priceTo) addPart("priceTo", "prto", this.priceTo);
        if (this.page) addPart("page", "pge", this.page);
        if (this.pageSize) addPart("pageSize", "psz", this.pageSize);
        if (
            this.locationIds &&
            this.locationIds.length > 0 &&
            (!ignorePresets || !this.preset?.locationIds)
        ) {
            addPart("locationIds", "loc", this.locationIds.join(","));
        }
        if (this.allItemPrices) {
            addPart("allItemPrices", "itp", "true");
        }

        // We add each of the facility filters to the query using standard pattern used by BookingStudio's SearchContext
        let presetFilterValues = Object.keys(this.preset?.facilityValues ?? {});
        const facilityValueKeys = Object.keys(this.facilityValues).filter(
            (key) => !ignorePresets || !presetFilterValues.includes(key)
        );
        facilityValueKeys.sort();
        facilityValueKeys.forEach((key) => {
            const value = this.facilityValues[key];
            if (value !== "") {
                addPart(key, key, value);
            }
        });

        // We add each of the facility filters to the query using standard pattern used by BookingStudio's SearchContext
        const contextValueKeys = Object.keys(this.contextValues);
        contextValueKeys.sort();
        contextValueKeys.forEach((key) => {
            const value = this.contextValues[key];
            if (value !== "") {
                const fullKey = contextPrefix + key;
                addPart(fullKey, fullKey, value);
            }
        });

        // We combine the parts and returns the result
        return result.join("&");
    };

    public toUrl(router: SearchContextRouter) {
        return router.makeUrl(this);
    }

    toProps = (): SearchContextProps => {
        return {
            adults: this.adults,
            children: this.children,
            arrival: (this.arrival && format(this.arrival, "MM-dd-yyyy")) || undefined,
            duration: this.duration,
            facilityValues: this.facilityValues,
            lodgingId: this.lodgingId,
            lodgingIds: this.lodgingIds,
            page: this.page,
            pageSize: this.pageSize,
            pets: this.pets,
            allItemPrices: this.allItemPrices,
        };
    };

    values = (): SearchContextValues => {
        return {
            arrival: (this.arrival && format(this.arrival, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")) || "",
            duration: (this.duration && this.duration.toString()) || "",
            adults: this.adults !== null ? this.adults.toString() : "",
            children: this.children !== null ? this.children.toString() : "",
            infants: this.infants !== null ? this.infants.toString() : "",
            pets: this.pets !== null ? this.pets.toString() : "",
            lodgingId: (this.lodgingId && this.lodgingId.toString()) || "",
            lodgingIds: this.lodgingIds !== null ? this.lodgingIds.map((id) => id.toString()) : [],
            locationIds:
                this.locationIds !== null ? this.locationIds.map((id) => id.toString()) : [],
            facilityValues: this.facilityValues !== null ? this.facilityValues : {},
            page: this.page !== null ? this.page.toString() : "",
            pageSize: this.pageSize !== null ? this.pageSize.toString() : "",
            priceFrom: this.priceFrom !== null ? this.priceFrom.toString() : "",
            priceTo: this.priceTo !== null ? this.priceTo.toString() : "",
            allItemPrices: this.allItemPrices ? "true" : "false",
        };
    };

    clone = () => {
        let context = SearchContext.createFromQueryString(this.toQueryString());
        if (this.preset != null) {
            return context.usePreset(this.preset);
        } else {
            return context;
        }
    };

    merge(other: SearchContext) {
        let thisProps = queryString.parse(this.toQueryString());
        let otherProps = queryString.parse(other.toQueryString());

        let result = SearchContext.createFromQueryString(
            queryString.stringify({ ...thisProps, ...otherProps })
        );        
        return result;
    }

    usePreset(other: SearchContext) {
        let thisProps = queryString.parse(this.toQueryString());
        let otherProps = queryString.parse(other.toQueryString());
        let result = SearchContext.createFromQueryString(
            queryString.stringify({ ...thisProps, ...otherProps })
        );

        result._preset = other;

        return result;
    }

    static isSearchContextQueryStringKeyName(name: string) {
        if (
            name == "adu" ||
            name == "chi" ||
            name == "inf" ||
            name == "pet" ||
            name == "dur" ||
            name == "lod" ||
            name == "lods" ||
            name == "ari" ||
            name == "ari_density" ||
            name == "prfro" ||
            name == "prto" ||
            name == "pge" ||
            name == "psz" ||
            name == "loc" ||
            name == "itp"
        ) {
            return true;
        }

        if (/^fac\d+(_min|_max)?$/.test(name)) {
            return true;
        }

        if (name.startsWith(contextPrefix)) {
            return true;
        }

        return false;
    }
}

export class SearchContextRouter {
    private url: string;
    private routeValueMap: RouteValueMap;
    private options: SearchContextRouterOptions;

    constructor(
        url: string,
        routeValueMap: RouteValueMap = null,
        options: SearchContextRouterOptions = null
    ) {
        if (!url) {
            throw new Error("SearchContextRouter constructor parameter url is missing");
        }

        this.url = url.replace(/\/+$/, "");
        this.routeValueMap = routeValueMap;
        this.options = options;
    }

    static createFromConfig = (config: SearchContextRouterConfig) => {
        return new SearchContextRouter(config.url, config.map);
    };

    matchesUrl = (url: string = null) => {
        const patternUrlPath = this.splitPathSeqments(this.url);
        const currentUrlPath = url ? this.splitPathSeqments(url) : this.getValues();

        for (let i = 0; i < patternUrlPath.length; i++) {
            if (i < currentUrlPath.length) {
                if (this.pathSegmentIsParameterKey(patternUrlPath[i])) {
                    continue;
                } else if (patternUrlPath[i] !== currentUrlPath[i]) {
                    return false;
                }
            } else {
                return false;
            }
        }

        return true;
    };

    getFieldConfig = () => {
        return {
            arrival: {
                read: (value) => deserializeDate(value),
                write: (value) => serializeDate(value),
            },
            duration: {
                read: (value) => deserializeNumber(value),
                write: (value) => serializeNumber(value),
            },
            adults: {
                read: (value) => deserializeNumber(value),
                write: (value) => serializeNumber(value),
            },
            children: {
                read: (value) => deserializeNumber(value),
                write: (value) => serializeNumber(value),
            },
            infants: {
                read: (value) => deserializeNumber(value),
                write: (value) => serializeNumber(value),
            },
            pets: {
                read: (value) => deserializeNumber(value),
                write: (value) => serializeNumber(value),
            },
            locationIds: {
                read: (value) => deserializeNumberArray(value),
                write: (value) => serializeNumberArray(value),
            },
            lodgingId: {
                read: (value) => deserializeNumber(value),
                write: (value) => serializeNumber(value),
            },
            lodgingIds: {
                read: (value) => deserializeNumberArray(value),
                write: (value) => serializeNumberArray(value),
            },
        };
    };

    getSearchContext = (searchContext: SearchContext = null) => {
        let newSearchContext =
            searchContext || SearchContext.createFromQueryString(this.getSearch());
        const parameters = this.getParameters();
        const fieldConfig = this.getFieldConfig();

        if (parameters === null) {
            return newSearchContext;
        }

        // Override with path parameters (if not set in query string)
        Object.keys(parameters).forEach((key) => {
            if (Object.keys(fieldConfig).indexOf(key) > -1) {
                const value = fieldConfig[key].read(parameters[key]);
                if (key === "arrival" && newSearchContext.arrival === null) {
                    newSearchContext = newSearchContext.changeArrival(value);
                } else if (key === "duration" && newSearchContext.duration === null) {
                    newSearchContext = newSearchContext.changeDuration(value);
                } else if (key === "adults" && newSearchContext.adults === null) {
                    newSearchContext = newSearchContext.changePersons(
                        value,
                        newSearchContext.children,
                        newSearchContext.infants,
                        newSearchContext.pets
                    );
                } else if (key === "children" && newSearchContext.children === null) {
                    newSearchContext = newSearchContext.changePersons(
                        newSearchContext.adults,
                        value,
                        newSearchContext.infants,
                        newSearchContext.pets
                    );
                } else if (key === "infants" && newSearchContext.infants === null) {
                    newSearchContext = newSearchContext.changePersons(
                        newSearchContext.adults,
                        newSearchContext.children,
                        value,
                        newSearchContext.pets
                    );
                } else if (key === "pets" && newSearchContext.pets === null) {
                    newSearchContext = newSearchContext.changePersons(
                        newSearchContext.adults,
                        newSearchContext.children,
                        newSearchContext.infants,
                        value
                    );
                } else if (key === "locationIds" && newSearchContext.locationIds === null) {
                    newSearchContext = newSearchContext.changeLocationIds(value);
                } else if (key === "lodgingId" && newSearchContext.lodgingId === null) {
                    newSearchContext = newSearchContext.changeLodging(value);
                } else if (key === "lodgingIds" && newSearchContext.lodgingIds === null) {
                    newSearchContext = newSearchContext.changeLodgings(value);
                }
            }
        });

        return newSearchContext;
    };

    makeUrl = (searchContext: SearchContext) => {
        const parameters = this.getParameters();
        const fieldConfig = this.getFieldConfig();
        const ignored = Object.keys(parameters);
        const lodgingId = searchContext.lodgingId;
        const routeValueProperties =
            this.routeValueMap !== null && this.routeValueMap !== undefined
                ? Object.keys(this.routeValueMap)
                : [];

        // Replace keywords with values in url
        let processedUrl = this.url;
        Object.keys(parameters).forEach((key) => {
            // Is it a normal field (and the search context has a value)
            if (Object.keys(fieldConfig).indexOf(key) > -1 && searchContext[key]) {
                processedUrl = processedUrl.replace(
                    "{" + key + "}",
                    fieldConfig[key].write(searchContext[key])
                );
                // Is it route value property (and we know the lodging id)
            } else if (routeValueProperties.indexOf(key) > -1 && lodgingId) {
                const value = this.routeValueMap[key][lodgingId.toString()];
                processedUrl = processedUrl.replace("{" + key + "}", serializeString(value));
            }
        });
        if (!processedUrl.endsWith("/")) {
            processedUrl += "/";
        }

        // Append querystring with keywords ignored
        const queryString = searchContext.toQueryString(ignored);
        return queryString ? processedUrl + "?" + queryString : processedUrl;
    };

    getParameters() {
        const keys = this.getKeys();
        const values = this.getValues();
        const isRouterUrl = this.matchesUrl();

        const parameters: any = {};
        for (let i = 0; i < keys.length; i++) {
            if (this.pathSegmentIsParameterKey(keys[i])) {
                if (isRouterUrl) {
                    parameters[keys[i].replace(/^\{/, "").replace(/\}$/, "")] =
                        values.length > i ? values[i] : undefined;
                } else {
                    parameters[keys[i].replace(/^\{/, "").replace(/\}$/, "")] = undefined;
                }
            }
        }

        return parameters;
    }

    private pathSegmentIsParameterKey(segment: string) {
        if (!segment) {
            return false;
        }

        return segment.startsWith("{") && segment.endsWith("}");
    }

    private getPathname = () => {
        if (
            this.options &&
            this.options.getPathname &&
            typeof this.options.getPathname === "function"
        ) {
            return this.options.getPathname();
        }
        return window.location.pathname;
    };

    private getSearch = () => {
        if (
            this.options &&
            this.options.getSearch &&
            typeof this.options.getSearch === "function"
        ) {
            return this.options.getSearch();
        }
        return window.location.search;
    };

    private getKeys = () => {
        return this.splitPathSeqments(this.url);
    };

    private getValues = () => {
        return this.splitPathSeqments(this.getPathname());
    };

    private splitPathSeqments = (path: string) => {
        if (!path) {
            return [];
        }

        return path.split("/").filter((part) => part !== "");
    };
}

export interface WithSearchContextProps {
    onSearchContextChanged?: (searchContext: SearchContext) => void;
    searchContext?: SearchContext;
}

interface ComponentWithSearchContextState {
    searchContext: SearchContext;
}

// export type SearchContextAdapter = {
//     processFacilityGroupBeforeSearchContext: (
//         searchContextProps: SearchContextProps,
//         facilityGroup: Facility[],
//         currentFacilityId: number,
//         currentFacilityValue: string,
//         comparison: FacilityValueComparison
//     ) => SearchContextProps;
//
//     getSelectedFacilityFromSearchContext: (
//         searchContextProps: SearchContextProps,
//         facilityGroup: Facility[]
//     ) => { id: string; value: string };
// };

export const withSearchContext = <P extends WithSearchContextProps>(
    WrappedComponent: React.ComponentType<P>
) =>
    class ComponentWithSearchContext extends React.Component<
        P & WithSearchContextProps,
        ComponentWithSearchContextState
    > {
        state = {
            searchContext: undefined,
        };

        handleSearchContextChanged = (searchContext: SearchContext) => {
            const { onSearchContextChanged } = this.props;
            this.setState({ searchContext });
            if (typeof onSearchContextChanged === "function" && onSearchContextChanged !== null) {
                onSearchContextChanged(searchContext);
            }
        };

        render() {
            return (
                <WrappedComponent
                    searchContext={this.state ? this.state.searchContext : null}
                    onSearchContextChanged={this.handleSearchContextChanged}
                    {...(this.props as P)}
                />
            );
        }
    };
