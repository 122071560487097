import React, { useEffect, useCallback, useState } from "react";
import { createPortal } from "react-dom";
import { isBrowser } from "./helpers";
import Modal, { ModalProps } from "../Modal";

export default function useModal() {
    const [mount, setMount] = useState(false);

    const [portal, setPortal] = useState<HTMLDivElement>(null);

    useEffect(() => {
        if (isBrowser()) {
            setPortal((portal) => {
                if (mount) {
                    const element = document.createElement("div");
                    document.body.appendChild(element);
                    return element;
                } else {
                    if (portal != null) {
                        portal.remove();
                        return null;
                    }
                }
            });
        }
    }, [mount]);

    const handleShow = useCallback(() => {
        setMount(true);
    }, []);

    const handleClose = useCallback(() => {
        setMount(false);
    }, []);

    const ModalWrapper = useCallback(
        (props: ModalProps) => {
            if (portal != null) {
                return createPortal(<Modal {...props} onClose={handleClose} />, portal);
            } else {
                return null;
            }
        },
        [handleClose, portal]
    );

    return {
        Modal: ModalWrapper,
        showModal: handleShow,
        hideModal: handleClose,
    };
}
