import React, { useCallback } from "react";
import "./FramedSpot.scss";
import "./Spot.scss";
interface Props {
    subject: string;
    image: {
        src: string;
        srcSet?: string;
        sizes?: string;
        alt: string;
    };
    category?: string;
    headline: string;
    imageHeight: string;
    link?: string;
    overlay?: "video";
    openInNewTab?: boolean;
}

export default function FramedSpot({
    subject,
    image,
    category,
    headline,
    link,
    openInNewTab,
    overlay
}: Props) {
    const onClickHandler = useCallback(() => {
        if (link) {
            window.location.href = link;
        }
    }, [link]);

    let linkTarget = undefined;
    let rel = undefined;
    if (openInNewTab) {
        linkTarget = "_blank";
        rel = "noopener noreferrer";
    }

    const classNames = ['bwp-framed-spot'];
    if (overlay) {
        classNames.push(`bwp-framed-spot__overlay--${overlay}`);
    }

    return (
        <div className={classNames.join(' ')}>
            <div className="bwp-framed-spot__subject">{subject}</div>
            <div
                className="bwp-spot"
                style={{ cursor: link ? "pointer" : undefined }}
                onClick={onClickHandler}
            >
                {image && <img {...image} loading="lazy" />}
                {category && <div className="bwp-category">{category}</div>}
                {headline && (
                    <header>
                        {link ? (
                            <a target={linkTarget} rel={rel} href={link}>
                                {headline}
                            </a>
                        ) : (
                                headline
                            )}
                    </header>
                )}
            </div>
        </div>
    );
}
