import React, { useCallback, useEffect, useMemo, useState } from "react";
import BookingSummary from "./BookingSummary";
import ItemsSummary from "./ItemsSummary";
import {
    BookingRequestInput,
    CreateBookingResultType,
    CustomerInput,
} from "./shared/graphql-types.generated";
import {
    CheckoutQuery,
    CreateBookingMutation,
    PayRatesQuery,
    TermsQuery,
} from "./operations.generated";
import { CheckoutTranslations } from "./translations/CheckoutTranslations";
import LodgingFacilitySummary from "./LodgingFacilitySummary";
import ItemsSelector from "./ItemsSelector";
import ContactDetailsForm from "./ContactDetailsForm";
import Button from "./Button";
import InfoBox from "./InfoBox";
import StepVisualizer, { Step, StepName } from "./StepVisualizer";
import GuestsForm from "./GuestsForm";
import NotesForm from "./NotesForm";
import CommunicationForm from "./CommunicationForm";
import PayRates from "./PayRates";
import { isToday, parseISO } from "date-fns";
import TermsForm from "./TermsForm";
import "./Checkout.scss";
import "./global/shared.scss";
import Spinner from "./Spinner";
import { replaceAll } from "./shared/helpers";
import { trackBooking, trackDynamicUrlChange } from "./shared/trackingUtils";
import { Redirect, Route, Switch, useHistory, useRouteMatch } from "react-router";
import { PaymentOption } from "./shared/types";

interface Props {
    value: BookingRequestInput;
    onChange: (value: BookingRequestInput) => void;
    onPayMethodChange: (payMethod: PaymentOption) => void;
    data: CheckoutQuery;
    translations: CheckoutTranslations;
    languageId: number;
    culture: string;
    onBook: () => void;
    defaultCountry?: string;
    infoBoxContent?: string;
    guestsFormAlertMessage?: string;
    catalogueLabel?: string;
    payRates: PayRatesQuery;
    terms: TermsQuery;
    createBookingResult: CreateBookingMutation;
    doneText: string;
    doneContactInformation: string;
    payMethod: PaymentOption | null;
    paymentOptions?: PaymentOption[];
    allowCreditCardFullAmountWhenTwoRates: boolean;
    lateBookingCreditCardRequired: boolean;
    requireCreditCardFullAmount: boolean;
}

type StepWithLocalizedUrl = Step & { routeParam: string };

function Checkout({
    languageId,
    culture,
    data,
    value,
    onChange,
    onPayMethodChange,
    translations,
    onBook,
    defaultCountry,
    infoBoxContent,
    guestsFormAlertMessage,
    catalogueLabel,
    payRates,
    terms,
    createBookingResult,
    doneText,
    doneContactInformation,
    payMethod,
    paymentOptions,
    allowCreditCardFullAmountWhenTwoRates,
    lateBookingCreditCardRequired,
    requireCreditCardFullAmount,
}: Props) {
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [firstRender, setFirstRender] = useState(true);
    const isProbabilityWithoutPrice =
        data.lodgingPresentation.selectedBookingOption?.status == "Probability";
    const [maxStep, setMaxStep] = useState<StepName>(
        isProbabilityWithoutPrice ? "yourDetails" : "extras"
    );
    const match = useRouteMatch<{ step: StepName }>();
    const history = useHistory();
    const steps: StepWithLocalizedUrl[] = useMemo(
        () =>
            [
                isProbabilityWithoutPrice == false
                    ? {
                          id: "extras" as StepName,
                          name: translations.stepExtras,
                          routeParam: translations.stepExtrasUrlName,
                      }
                    : null,
                {
                    id: "yourDetails" as StepName,
                    name: translations.stepYourDetails,
                    routeParam: translations.stepYourDetailsUrlName,
                },
                {
                    id: "payment" as StepName,
                    name: "",
                    routeParam: "payment",
                },
                {
                    id: "done" as StepName,
                    name: translations.stepDone,
                    routeParam: translations.stepDoneUrlName,
                },
            ].filter((x) => x != null),
        [isProbabilityWithoutPrice, translations]
    );

    const step = steps.find((s) => s.routeParam == match.params.step);

    const setStep = useCallback(
        (stepName: StepName) => {
            window.scrollTo(0, 0);
            let step = steps.find((s) => s.id == stepName);
            history.push("/" + step.routeParam + "/" + window.location.search);
        },
        [steps, history]
    );

    useEffect(() => {
        if (firstRender == true) {
            setFirstRender(false);
        }
    }, [firstRender]);

    useEffect(() => {
        if (!firstRender) {
            trackDynamicUrlChange();
        }
        // We disable ESLint here as we don't want to trigger on firstRender change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step]);

    useEffect(() => {
        if (steps.indexOf(step) > steps.findIndex((s) => s.id == maxStep)) {
            setStep(maxStep);
        }
    }, [step, maxStep, setStep, steps]);

    const blankCustomer: BookingRequestInput["customer"] = {
        firstName: "",
        lastName: "",
        address: "",
        postalCode: "",
        city: "",
        country: defaultCountry,
        privateTelephone: "",
        privateMobilephone: "",
        workTelephone: "",
        email: "",
        subscribeToCatalogue: false,
        customerId: null,
        customFieldValues: [],
        languageId: languageId,
        subscribeToNewsletters: null,
    };

    const blankGuest: BookingRequestInput["guests"][0] = {
        firstName: "",
        lastName: "",
        birthYear: null,
    };

    const blankGuests = [];
    const numberOfGuests =
        data.lodgingPresentation.selectedBookingOption.adults +
        data.lodgingPresentation.selectedBookingOption.children;
    for (let x = 1; x <= numberOfGuests; x++) {
        blankGuests.push({ ...blankGuest });
    }

    const handleItemQuantityChange = useCallback(
        (itemId: number, quantity: number) => {
            const items = [...(value.items || [])];

            let item = items.find((i) => i.itemId == itemId);
            if (item != null) {
                const index = items.indexOf(item);
                items[index] = { ...item, quantity };
            } else {
                items.push({ itemId, quantity });
            }

            onChange({
                ...value,
                items,
            });
        },
        [onChange, value]
    );

    const onChangeCustomer = useCallback(
        (customer: Partial<CustomerInput>) => {
            onChange({
                ...value,
                customer: { ...blankCustomer, ...value.customer, ...customer },
            });
        },
        [blankCustomer, onChange, value]
    );

    const handleFormSubmit = useCallback(
        (e: React.SyntheticEvent<HTMLFormElement>) => {
            e.preventDefault();
            if (step.id == "yourDetails") {
                onBook();
                if (
                    !isProbabilityWithoutPrice &&
                    (payMethod == PaymentOption.CreditCardOneRate ||
                        payMethod == PaymentOption.CreditCardTwoRates)
                ) {
                    setMaxStep("payment");
                    setStep("payment");
                } else {
                    setMaxStep("done");
                    setStep("done");
                }
            } else if (step.id == "extras") {
                setMaxStep("yourDetails");
                setStep("yourDetails");
            } else {
                return;
            }
        },
        [onBook, setStep, step]
    );

    if (step == null) {
        return <Redirect to={"/" + steps[0].routeParam + "/" + window.location.search} />;
    }

    return (
        <FormOrContainer
            isForm={step.id !== "payment"}
            onFormSubmit={handleFormSubmit}
            className="bwp-checkout"
        >
            <div className="bwp-checkout__steps bwp-only-large-screen">
                <StepVisualizer
                    steps={steps.filter((s) => s.id != "payment")}
                    activeStep={step.id}
                    maxStep={maxStep}
                    onStepClicked={setStep}
                />
            </div>
            <aside>
                <div>
                    <div style={{ position: "relative", zIndex: 1 }}>
                        <BookingSummary
                            translations={translations}
                            bookingOption={data.lodgingPresentation.selectedBookingOption}
                            lodging={data.lodgingPresentation.lodging}
                        />
                    </div>
                    {isProbabilityWithoutPrice == false && (
                        <ItemsSummary
                            lodging={data.lodgingPresentation.lodging}
                            checkoutPresentation={data.checkoutPresentation}
                            translations={translations}
                            showBackToItems={step.id == "yourDetails"}
                            onChangeItemsClick={() => setStep("extras")}
                        />
                    )}
                </div>
                <div className="bwp-only-large-screen">
                    <LodgingFacilitySummary
                        lodging={data.lodgingPresentation.lodging}
                        translations={translations}
                    />
                </div>
                <div className="bwp-only-large-screen">
                    {infoBoxContent && step.id != "done" && (
                        <InfoBox>
                            <div dangerouslySetInnerHTML={{ __html: infoBoxContent }} />
                        </InfoBox>
                    )}
                    {doneContactInformation && step.id == "done" && (
                        <InfoBox>
                            <div dangerouslySetInnerHTML={{ __html: doneContactInformation }} />
                        </InfoBox>
                    )}
                </div>
            </aside>
            {step.id == "extras" && (
                <main>
                    <ItemsSelector
                        data={data.checkoutPresentation}
                        translations={translations}
                        onItemQuantityChange={handleItemQuantityChange}
                    />
                    <div className="bwp-checkout__next-button">
                        <Button submitButton type="primary">
                            {translations.nextStep}
                        </Button>
                    </div>
                </main>
            )}
            {step.id == "yourDetails" && (
                <main>
                    <ContactDetailsForm
                        value={value?.customer ?? blankCustomer}
                        countries={data.countries}
                        onChange={(customer) => onChange({ ...value, customer })}
                        translations={translations}
                    />
                    <GuestsForm
                        customer={value?.customer}
                        guests={value?.guests ?? blankGuests}
                        onChange={(guests) => onChange({ ...value, guests })}
                        translations={translations}
                        alertMessage={guestsFormAlertMessage}
                    />
                    <NotesForm
                        value={value?.note ?? ""}
                        onChange={(note) => onChange({ ...value, note: note })}
                        translations={translations}
                    />
                    <CommunicationForm
                        catalogue={value?.customer?.subscribeToCatalogue ?? false}
                        newsletters={data.newsletters}
                        onCatalogueChange={(subscribeToCatalogue) =>
                            onChangeCustomer({ subscribeToCatalogue })
                        }
                        onNewslettersChange={(subscribeToNewsletters) =>
                            onChangeCustomer({
                                subscribeToNewsletters: subscribeToNewsletters.join(","),
                            })
                        }
                        translations={translations}
                        selectedNewsletters={
                            value?.customer?.subscribeToNewsletters
                                ?.split(",")
                                .map((x) => parseInt(x)) ?? []
                        }
                        catalogueLabel={catalogueLabel}
                    />
                    {payRates && !isProbabilityWithoutPrice && (
                        <PayRates
                            culture={culture}
                            totalPriceDisplayValue={
                                data?.checkoutPresentation?.totalPriceDisplayValue
                            }
                            payRates={[payRates.payRates.rate1, payRates.payRates.rate2].filter(
                                (p) => p != null
                            )}
                            isLastMinute={isToday(parseISO(payRates.payRates.rate1.payDate))}
                            translations={translations}
                            paymentOptions={paymentOptions}
                            allowCreditCardFullAmountWhenTwoRates={
                                allowCreditCardFullAmountWhenTwoRates
                            }
                            requireCreditCardFullAmount={requireCreditCardFullAmount}
                            lateBookingCreditCardRequired={lateBookingCreditCardRequired}
                            payMethod={payMethod}
                            onChange={onPayMethodChange}
                        />
                    )}
                    {terms?.terms?.description && (
                        <TermsForm
                            termsAccepted={termsAccepted}
                            onTermsAcceptedChange={setTermsAccepted}
                            terms={
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: terms.terms.description,
                                    }}
                                />
                            }
                            translations={translations}
                        />
                    )}

                    <div className="bwp-checkout__next-button">
                        <Button submitButton type="primary">
                            {isProbabilityWithoutPrice ? translations.reserve : translations.book}
                        </Button>
                        {!isProbabilityWithoutPrice && (
                            <a
                                href="#"
                                className="bwp-checkout__back-link bwp-only-large-screen"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setStep("extras");
                                }}
                            >
                                {translations.backToExtras}
                            </a>
                        )}
                    </div>
                </main>
            )}
            {step.id === "payment" && (
                <main>
                    {createBookingResult?.createBooking == null && (
                        <WaitSpinner showDelay={0} text={translations.pleaseWait} />
                    )}
                    {createBookingResult?.createBooking && (
                        <PaymentView {...{ createBookingResult, translations }} />
                    )}
                </main>
            )}
            {step.id == "done" && (
                <main>
                    {createBookingResult?.createBooking == null && (
                        <div style={{ display: "grid", justifyContent: "center" }}>
                            <Spinner showDelay={0} />
                        </div>
                    )}
                    {createBookingResult?.createBooking && (
                        <DoneView
                            {...{ doneText, createBookingResult, translations }}
                            checkoutPresentation={data.checkoutPresentation}
                            lodgingPresentation={data.lodgingPresentation}
                            value={value}
                            payRates={isProbabilityWithoutPrice ? null : payRates}
                            culture={culture}
                        />
                    )}
                </main>
            )}
        </FormOrContainer>
    );
}

function WaitSpinner({ showDelay, text }: { showDelay: number; text?: string }) {
    return (
        <div className="bwp-checkout__spinner">
            {text && <h2>{text}</h2>}
            <Spinner showDelay={showDelay} />
        </div>
    );
}

function FormOrContainer({
    isForm,
    onFormSubmit,
    className,
    children,
}: {
    isForm: boolean;
    onFormSubmit: (e: React.SyntheticEvent<HTMLFormElement>) => void;
    className: string;
    children: React.ReactNode;
}) {
    if (isForm) {
        return (
            <form className={className} onSubmit={onFormSubmit}>
                <DisableEnterSubmitOfForm />
                {children}
            </form>
        );
    }

    return <div className={className}>{children}</div>;
}

function DoneView({
    culture,
    doneText,
    createBookingResult,
    value,
    payRates,
    translations,
    checkoutPresentation,
    lodgingPresentation,
}: Pick<Props, "doneText" | "createBookingResult" | "payRates" | "translations" | "culture"> & {
    value: BookingRequestInput;
    checkoutPresentation: CheckoutQuery["checkoutPresentation"];
    lodgingPresentation: CheckoutQuery["lodgingPresentation"];
}) {
    useEffect(() => {
        if (
            createBookingResult.createBooking.success &&
            createBookingResult.createBooking.result.resultType == CreateBookingResultType.OrderItem
        ) {
            trackBooking(
                createBookingResult.createBooking.result.orderItem.id,
                lodgingPresentation,
                checkoutPresentation
            );
        }
    }, [
        checkoutPresentation,
        createBookingResult.createBooking.result.orderItem.id,
        createBookingResult.createBooking.result.resultType,
        createBookingResult.createBooking.success,
        lodgingPresentation,
    ]);

    if (createBookingResult.createBooking.success) {
        return (
            <>
                <InfoBox>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: prepareDoneText(
                                doneText,
                                createBookingResult.createBooking.result.orderItem.reservationId,
                                value.customer.email
                            ),
                        }}
                    />
                </InfoBox>
                {payRates && (
                    <PayRates
                        culture={culture}
                        totalPriceDisplayValue={checkoutPresentation?.totalPriceDisplayValue}
                        payRates={[payRates.payRates.rate1, payRates.payRates.rate2].filter(
                            (p) => p != null
                        )}
                        isLastMinute={isToday(parseISO(payRates.payRates.rate1.payDate))}
                        translations={translations}
                        paymentOptions={[]}
                        allowCreditCardFullAmountWhenTwoRates={false}
                        requireCreditCardFullAmount={false}
                        lateBookingCreditCardRequired={false}
                        payMethod={null}
                        onChange={(v) => {
                            return;
                        }}
                    />
                )}
            </>
        );
    } else {
        return <div>{createBookingResult.createBooking.errorMessage}</div>;
    }
}

function PaymentView({ createBookingResult }: Pick<Props, "createBookingResult">) {
    const formForGateway = createBookingResult?.createBooking?.result?.formForGateway;

    useEffect(() => {
        if (formForGateway) {
            const form = document.querySelector<HTMLFormElement>(
                ".bwp-payment-form-container form"
            );
            if (form) {
                form.submit();
            }
        }
    }, [formForGateway]);

    if (
        !createBookingResult?.createBooking?.success ||
        createBookingResult.createBooking.result.resultType !=
            CreateBookingResultType.FormForGateway
    ) {
        return (
            <div className="bwp-widget-uncaught-error">
                <p>An error occurred while processing the request on the server.</p>
                <p>Please contact us for further assistance.</p>
            </div>
        );
    }

    return (
        <div className="bwp-payment-form-container">
            <div
                dangerouslySetInnerHTML={{
                    __html: createBookingResult.createBooking.result.formForGateway,
                }}
            ></div>
        </div>
    );
}

// Taken from: https://stackoverflow.com/a/51507806
function DisableEnterSubmitOfForm() {
    return <button type="submit" disabled style={{ display: "none" }} aria-hidden="true" />;
}

function prepareDoneText(doneText: string, reservationId: number, customerEmail: string) {
    doneText = replaceAll(doneText, "{reservationId}", reservationId.toString());
    doneText = replaceAll(doneText, "{customerEmail}", customerEmail);
    return doneText;
}

export default function CheckoutRouteWrapper(props: Props) {
    return (
        <Switch>
            <Route path={"/:step?/"} render={() => <Checkout {...props} />} />
        </Switch>
    );
}
