import React, { useRef, useEffect, useState } from "react";
import { useSelect } from "downshift";
import "./FieldWithLabel.scss";

export interface SelectItem {
    text: string;
    value: string;
}

export interface SelectWithLabelProps {
    id: string;
    label: string;
    value: string;
    onChange: (value: string) => void;
    items?: SelectItem[];
    disabled?: boolean;
}

export default function SelectWithLabel({
    id,
    label,
    value,
    items,
    onChange,
    disabled,
}: SelectWithLabelProps) {
    const selectedItem = items.find((i) => i.value == value);
    const {
        isOpen,
        getToggleButtonProps,
        getLabelProps,
        getMenuProps,
        highlightedIndex,
        getItemProps,
    } = useSelect({
        items,
        onSelectedItemChange: (item) => onChange(item.selectedItem.value),
        selectedItem: selectedItem,
        id,
    });

    const defaultText = selectedItem?.text;

    const [selectWidth, setSelectWidth] = useState(0);

    const container: React.RefObject<HTMLDivElement> = useRef(null);

    useEffect(() => {
        if (container && container.current) {
            const newSelectWidth = container.current.getBoundingClientRect().width;
            if (!selectWidth) {
                setSelectWidth(newSelectWidth);
            } else if (selectWidth !== newSelectWidth) {
                setSelectWidth(newSelectWidth);
            }
        }
    }, [selectWidth]);

    const optionContainerStyles = selectWidth
        ? {
              width: `calc(${selectWidth}px - 1rem - 2px)`,
              display: isOpen ? "block" : "none",
              zIndex: 1,
          }
        : { display: isOpen ? "block" : "none", zIndex: 1 };

    const classNames = ["bwp-field-with-label", "bwp-field-with-label--select"];

    if (disabled) {
        classNames.push("bwp-field-with-label--disabled");
    }

    return (
        <div className={classNames.join(" ")} ref={container}>
            <label {...getLabelProps()}>
                <span>{label}</span>
            </label>
            <button
                className="bwp-field-with-label__value-container"
                type="button"
                {...(disabled ? {} : getToggleButtonProps())}
            >
                {(selectedItem && selectedItem.text) || defaultText || ""}
            </button>
            <div className="bwp-select-items" style={optionContainerStyles}>
                <ul {...getMenuProps()}>
                    {isOpen &&
                        items.map((item, index) => (
                            <li
                                style={
                                    highlightedIndex === index ? { backgroundColor: "#bde4ff" } : {}
                                }
                                key={`${item.value}${index}`}
                                {...getItemProps({ item, index })}
                            >
                                {item.text}
                            </li>
                        ))}
                </ul>
            </div>
        </div>
    );
}
