import {PluralizedTranslation} from "./types";
import {useMemo} from "react";
import {SelectItem} from "../SelectWithLabel";
import {pluralize} from "./helpers";

/**
 * A helper hook that helps creating the options for a select list that lets you choose durations. It will automaticly use weeks if the durations is whole weeks
 * @param dayTranslation The pluralized translation for day
 * @param weekTranslation The pluralized translation for week
 * @param durations an array of durations that should be converted to select options
 */
export default function useDurationOptions(dayTranslation: PluralizedTranslation, weekTranslation: PluralizedTranslation, durations: number[]) {
    return useMemo(() => {

        const result: SelectItem[] = [];

        durations.forEach(days => {
            let text: string;

            if (days % 7 == 0) {
                let weeks = days / 7;
                text = pluralize(weeks, weekTranslation);
            } else {
                text = pluralize(days, dayTranslation);
            }

            result.push({value: days.toString(), text})
        });

        return result;

    }, [dayTranslation, weekTranslation, durations]);
}
