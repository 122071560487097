import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import Checkout from "@bwp/Checkout";
import {
    CheckoutQueryVariables,
    CreateBookingMutation,
    useCheckoutQuery,
    useCreateBookingMutation,
    usePayRatesQuery,
    useTermsQuery,
} from "@bwp/operations.generated";
import {
    BookingRequestInput,
    CreateBookingResultType,
    PaymentOption as GraphQLPaymentOption,
} from "@bwp/shared/graphql-types.generated";
import GraphQLErrorPanel from "@bwp/GraphQLErrorPanel";
import { CheckoutTranslations } from "@bwp/translations/CheckoutTranslations";
import useGlobalSearchContext from "@bwp/shared/useGlobalSearchContext";
import { defaultValue } from "@bwp/shared/helpers";
import Spinner from "@bwp/Spinner";
import { BrowserRouter } from "react-router-dom";
import { viewBasket } from "@bwp/shared/trackingUtils";
import { PaymentOption } from "@bwp/shared/types";
import {
    allowBankTransfer,
    allowCreditCard,
    hasTwoCreditCardPaymentOptions,
} from "@bwp/shared/paymentoptions";

export default function CheckoutWrapper({
    languageId,
    culture,
    translations,
    bathroomFacilityId,
    bedroomFacilityId,
    houseAreaFacilityId,
    internetFacilityId,
    defaultCountry,
    infoBoxContent,
    guestsFormAlertMessage,
    catalogueLabel,
    doneContactInformation,
    doneText,
    basePath,
    paymentOptions,
    allowCreditCardFullAmountWhenTwoRates,
    lateBookingCreditCardRequired,
    requireCreditCardFullAmount,
}: {
    languageId: number;
    culture: string;
    translations: CheckoutTranslations;
    bathroomFacilityId: number;
    bedroomFacilityId: number;
    houseAreaFacilityId: number;
    internetFacilityId: number;
    defaultCountry: string;
    infoBoxContent: string;
    guestsFormAlertMessage: string;
    catalogueLabel: string;
    doneText: string;
    doneContactInformation: string;
    basePath: string;
    paymentOptions: PaymentOption[];
    allowCreditCardFullAmountWhenTwoRates: boolean;
    lateBookingCreditCardRequired: boolean;
    requireCreditCardFullAmount: boolean;
}) {
    const [searchContext] = useGlobalSearchContext({ defaultValue });

    const [request, setRequest] = useState<BookingRequestInput>({
        bookingOption: {
            adults: searchContext.adults,
            children: searchContext.children ?? 0,
            arrival: searchContext.arrival,
            duration: searchContext.duration,
            infants: searchContext.infants ?? 0,
            languageId: languageId,
            lodgingId: searchContext.lodgingId,
            pets: searchContext.pets ?? 0,
        },
        items: [],
    });

    const [filteredPaymentOptions, setFilteredPaymentOptions] =
        useState<PaymentOption[]>(paymentOptions);

    const [createBookingResult, setCreateBookingResult] = useState<CreateBookingMutation>(null);

    const [viewBasketTracked, setViewBasketTracked] = useState(false);

    const checkoutQueryParameters = useMemo(() => {
        return {
            bookingOption: request.bookingOption,
            items: request.items,
        } as CheckoutQueryVariables["request"];
    }, [request]);

    const { data, loading, error } = useCheckoutQuery({
        variables: {
            request: checkoutQueryParameters,
            displayDate: request.bookingOption.arrival,
            bathroomFacilityId: bathroomFacilityId,
            bedroomFacilityId: bedroomFacilityId,
            houseAreaFacilityId: houseAreaFacilityId,
            internetFacilityId: internetFacilityId,
        },
    });

    const payRatesQuery = usePayRatesQuery({
        variables: {
            request: {
                bookingOption: request.bookingOption,
                items: data?.checkoutPresentation?.itemLines
                    ? data.checkoutPresentation.itemLines
                          .filter((il) => il.quantity > 0)
                          .map((il) => ({ quantity: il.quantity, itemId: il.item.id }))
                    : [],
            },
        },
        skip: data?.checkoutPresentation?.itemLines === undefined,
    });

    const [payMethod, setPayMethod] = useState<PaymentOption>(null);

    // Determine paymentOptions based on available pay rates
    useEffect(() => {
        const payRates = payRatesQuery?.data?.payRates;
        if (payRates && paymentOptions && paymentOptions.length) {
            const hasTwoRates =
                typeof payRates.rate1 === "object" &&
                payRates.rate1 != null &&
                typeof payRates.rate2 === "object" &&
                payRates.rate2 != null;
            const isLastMinute = !hasTwoRates;

            const newFilteredPaymentOptions = allowBankTransfer(
                paymentOptions,
                isLastMinute,
                lateBookingCreditCardRequired
            )
                ? paymentOptions
                : paymentOptions.filter((po) => po != PaymentOption.BankTransfer);

            setFilteredPaymentOptions(newFilteredPaymentOptions);
        }
    }, [payRatesQuery, paymentOptions, lateBookingCreditCardRequired]);

    // Determine which payment option is selected if user doesn't choose
    useEffect(() => {
        const payRates = payRatesQuery?.data?.payRates;
        if (payRates && paymentOptions && paymentOptions.length) {
            const hasTwoRates =
                typeof payRates.rate1 === "object" &&
                payRates.rate1 != null &&
                typeof payRates.rate2 === "object" &&
                payRates.rate2 != null;
            const isLastMinute = !hasTwoRates;

            const isBankTransferAndCreditCardAllowed =
                allowBankTransfer(paymentOptions, isLastMinute, lateBookingCreditCardRequired) &&
                allowCreditCard(paymentOptions);

            const isCreditCardWithTwoPayRatesAllowed =
                allowCreditCard(paymentOptions) &&
                hasTwoRates &&
                hasTwoCreditCardPaymentOptions(
                    filteredPaymentOptions,
                    allowCreditCardFullAmountWhenTwoRates,
                    requireCreditCardFullAmount
                );

            const showPaymentOptions =
                isBankTransferAndCreditCardAllowed || isCreditCardWithTwoPayRatesAllowed;

            const isPayMethodSet =
                payMethod === PaymentOption.BankTransfer ||
                payMethod === PaymentOption.CreditCardOneRate ||
                payMethod === PaymentOption.CreditCardTwoRates;

            if (!showPaymentOptions && !isPayMethodSet) {
                if (data.lodgingPresentation?.selectedBookingOption?.status === "Probability") {
                    setPayMethod(PaymentOption.BankTransfer);
                } else {
                    if (allowCreditCard(paymentOptions)) {
                        setPayMethod(
                            hasTwoRates
                                ? PaymentOption.CreditCardTwoRates
                                : PaymentOption.CreditCardOneRate
                        );
                    } else {
                        setPayMethod(PaymentOption.BankTransfer);
                    }
                }
            }
        }
    }, [
        data,
        payRatesQuery,
        paymentOptions,
        lateBookingCreditCardRequired,
        allowCreditCardFullAmountWhenTwoRates,
    ]);

    const termsQuery = useTermsQuery({
        variables: {
            lodgingId: searchContext.lodgingId,
            arrivalDate: searchContext.arrival,
        },
    });

    const [cachedData, setCachedData] = useState(data);

    useLayoutEffect(() => {
        if (data != null) {
            setCachedData(data);
        }
    }, [data]);

    useEffect(() => {
        if (data && !viewBasketTracked) {
            setTimeout(() => {
                viewBasket(data.lodgingPresentation);
            }, 0);
            setViewBasketTracked(true);
        }
    }, [data, viewBasketTracked]);

    const [createBooking] = useCreateBookingMutation();

    const handleBook = useCallback(() => {
        setCreateBookingResult(null);
        if (window.location.search.includes("test=1")) {
            setCreateBookingResult({
                createBooking: {
                    success: true,
                    result: {
                        resultType: CreateBookingResultType.OrderItem,
                        bookingOptionNoLongerAvailable: false,
                        orderItem: {
                            id: 1,
                            reservationId: 1337,
                            type: "invoice",
                        },
                    },
                },
            });
        } else {
            let graphQLPayMethod: GraphQLPaymentOption | null;
            if (payMethod === PaymentOption.CreditCardOneRate) {
                graphQLPayMethod = GraphQLPaymentOption.CreditCardWithOneRate;
            } else if (payMethod === PaymentOption.CreditCardTwoRates) {
                graphQLPayMethod = GraphQLPaymentOption.CreditCardWithTwoRates;
            } else {
                graphQLPayMethod = GraphQLPaymentOption.BankTransfer;
            }

            createBooking({
                variables: {
                    request,
                    payMethod: graphQLPayMethod,
                },
            }).then((result) => {
                setCreateBookingResult(result.data);
            });
        }
    }, [createBooking, payMethod, request]);

    if (loading && cachedData == null) {
        return (
            <div style={{ display: "grid", justifyContent: "center", marginTop: "64px" }}>
                <Spinner />
            </div>
        );
    }

    if (error) {
        return <GraphQLErrorPanel error={error} />;
    }

    if (data?.checkoutPresentation.available === false) {
        return <div>Booking Not Avilable</div>;
    }
    return (
        <BrowserRouter basename={basePath}>
            <Checkout
                languageId={languageId}
                culture={culture}
                value={request}
                onChange={setRequest}
                onPayMethodChange={setPayMethod}
                data={cachedData || data}
                translations={translations}
                onBook={handleBook}
                defaultCountry={defaultCountry}
                infoBoxContent={infoBoxContent}
                guestsFormAlertMessage={guestsFormAlertMessage}
                catalogueLabel={catalogueLabel}
                payRates={payRatesQuery.data}
                terms={termsQuery.data}
                createBookingResult={createBookingResult}
                doneText={doneText}
                doneContactInformation={doneContactInformation}
                payMethod={payMethod}
                paymentOptions={filteredPaymentOptions}
                allowCreditCardFullAmountWhenTwoRates={allowCreditCardFullAmountWhenTwoRates}
                lateBookingCreditCardRequired={lateBookingCreditCardRequired}
                requireCreditCardFullAmount={requireCreditCardFullAmount}
            />
        </BrowserRouter>
    );
}
