import { useEffect } from "react";

export default function useScrollRestorationOnLoad(variableToCheck: any) {
    return useEffect(() => {
        if (variableToCheck != null) {
            try {
                if (window.history.state) {
                    let jumpBackToX = window.history.state["jumpBackToX"];
                    let jumpBackToY = window.history.state["jumpBackToY"];
                    if (jumpBackToX !== undefined && jumpBackToY !== undefined) {
                        window.scroll({
                            top: jumpBackToY,
                            left: jumpBackToX,
                        });

                        let newState = window.history.state;
                        delete newState["jumpBackToX"];
                        delete newState["jumpBackToY"];
                        window.history.replaceState({ ...newState }, null, null);
                    }
                }
            } catch (error) {
                console.error("Unable to restore scroll position", error);
            }
        }
    }, [variableToCheck]);
}
