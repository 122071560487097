import React, { useRef, useState } from "react";
import "./FieldWithLabel.scss";
import { SearchBoxTranslations } from "./translations/SearchBoxTranslations";
import useOnClickOutside from "use-onclickoutside";
import { pluralize } from "./shared/helpers";
import NumberPicker from "./NumberPicker";

interface PeopleValue {
    adults: number;
    children: number;
}

export interface SelectWithLabelProps {
    label: string;
    value: PeopleValue;
    onChange: (value: PeopleValue) => void;
    maxPersons: number;
    translations: SearchBoxTranslations;
    showLabelAsPersons?: boolean;
    warningMessage?: string;
}

export default function PeopleSelectWithLabel({
    label,
    value = { adults: 0, children: 0 },
    onChange,
    translations,
    maxPersons,
    showLabelAsPersons,
    warningMessage,
}: SelectWithLabelProps) {
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef<HTMLDivElement>();
    useOnClickOutside(ref, () => {
        setIsOpen(false);
    });

    return (
        <div className="bwp-field-with-label bwp-field-with-label--people-select" ref={ref}>
            <label>
                <span>{label}</span>
            </label>
            <div>
                <button
                    className="bwp-field-with-label__value-container"
                    type="button"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    {showLabelAsPersons ? (
                        <>{pluralize(value.adults + value.children, translations.person)}</>
                    ) : (
                        <>
                            {pluralize(value.adults, translations.adult)}
                            {", "}
                            {pluralize(value.children, translations.child)}
                        </>
                    )}
                </button>
            </div>
            {isOpen && (
                <>
                    <div className="bwp-select-items">
                        <div className="bwp-select-items__selector">
                            <div>{translations.adults}</div>
                            <NumberPicker
                                min={0}
                                max={maxPersons}
                                value={value.adults}
                                onChange={(adults) =>
                                    onChange({ adults, children: value.children })
                                }
                            />
                        </div>
                        <div className="bwp-select-items__selector">
                            <div>{translations.children}</div>
                            <NumberPicker
                                min={0}
                                max={maxPersons}
                                value={value.children}
                                onChange={(children) =>
                                    onChange({ adults: value.adults, children: children })
                                }
                            />
                        </div>
                        <div className="bwp-select-items__warning-message">
                            <p>{warningMessage}</p>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}
