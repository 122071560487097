import React, { useEffect, useMemo, useState } from "react";
import { LodgingCardTranslations } from "@bwp/translations/LodgingCardTranslations";
import useGlobalSearchContext from "@bwp/shared/useGlobalSearchContext";
import { defaultValue } from "@bwp/shared/helpers";
import { formatISO } from "date-fns";
import useQueryString from "@bwp/shared/useQueryString";
import { useLodgingListQuery } from "@bwp/operations.generated";
import { lodgingUrl } from "@bwp/shared/lodgingurl";
import LodgingList from "@bwp/LodgingList";
import { LodgingListSetting } from "@bwp/shared/types";
import SearchResultListSkeleton from "@bwp/SearchResultListSkeleton";
import { SearchResultListTranslations } from "@bwp/translations/SearchResultListTranslations";

export interface LodgingListWrapperProps {
    translations: LodgingCardTranslations;
    lodgingUrlPattern: string;
    year: number;
    lodgingListSetting: LodgingListSetting;
    onlyShowFavorites: boolean;
    emptyResultHtmlContent: string;
    searchResultListTranslations: SearchResultListTranslations;
    markerIconUrl: string;
    mapHeight: number;
    googleMapsApiKey: string;
    maxMapZoomLevel: number;
}

export default function LodgingListWrapper({
    translations,
    lodgingUrlPattern,
    lodgingListSetting,
    year,
    onlyShowFavorites,
    emptyResultHtmlContent,
    searchResultListTranslations,
    markerIconUrl,
    mapHeight,
    googleMapsApiKey,
    maxMapZoomLevel,
}: LodgingListWrapperProps) {
    const [headerElement] = React.useState(document.getElementById("bwp-search-result-header"));
    const [sorting, setSorting] = useQueryString("ord", null);

    // TODO: These needs to be set somewhere
    const numberOfBedroomsFacilityId = 27;
    const numberOfBathroomsFacilityId = 35;
    const extraFacilities = [numberOfBedroomsFacilityId, numberOfBathroomsFacilityId];

    const [searchContext] = useGlobalSearchContext({ defaultValue, lodgingListSetting });
    const query = useLodgingListQuery({
        variables: {
            query: searchContext.toQueryString(),
            onlyMasterFacilities: [],
            onlyFacilities: extraFacilities,
            displayDate: formatISO(new Date(), { representation: "date" }),
            sorting: sorting as any,
            year,
        },
    });

    const [numberOfSkeletons, setNumberOfSeletons] = useState(6);

    useEffect(() => {
        if (query.data != null) {
            setNumberOfSeletons(query.data.lodgingList.results.length);
        }
    }, [query.data]);

    const queryString = useMemo(() => {
        return searchContext.toQueryString([
            "context:lodging-location",
            "context:lodging-address1",
        ]);
    }, [searchContext]);

    if (query.error) {
        return <div>{query.error}</div>;
    }

    if (query.loading) {
        return <SearchResultListSkeleton numberOfSkeletons={numberOfSkeletons} height={391} />;
    }

    return (
        <>
            <LodgingList
                lodgingCardTranslations={translations}
                {...query.data}
                lodgingUrlBuilder={(l) =>
                    `${lodgingUrl(lodgingUrlPattern, l.lodging)}?${queryString}`
                }
                extraFacilities={extraFacilities}
                onlyShowFavorites={onlyShowFavorites}
                emptyResultHtmlContent={emptyResultHtmlContent}
                searchResultListTranslations={searchResultListTranslations}
                headerElement={headerElement}
                markerIconUrl={markerIconUrl}
                mapHeight={mapHeight}
                maxMapZoomLevel={maxMapZoomLevel}
                googleMapsApiKey={googleMapsApiKey}
                sorting={sorting}
                setSorting={setSorting}
            />
        </>
    );
}
